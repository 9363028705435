<template>
    <div class="content">
      <div class="row mt-5">
        <div class="col-md-12">
          <h3>MES COMMANDES</h3>
          <div class="btn-group btn-group-toggle float-right mb-3" data-toggle="buttons">
            <router-link
              :to="{ name: 'cree Commandes Leadmarket Annonceur'}"
              class="btn btn-round btn-just-icon btn-market"
              :disabled="!userCanCmdWeedoMarket"
            >CRÉER UNE COMMANDE</router-link>
          </div>
          <div class="card">
            <div class="card-body">
              <div>
                <div class="row">
                  <div class="col-xl-1 col-sm-6">
                    <base-input>
                      <el-select
                        class="select-primary pagination-select"
                        v-model="pagination.perPage"
                        placeholder="Per page"
                      >
                        <el-option
                          class="select-primary"
                          v-for="item in pagination.perPageOptions"
                          :key="item"
                          :label="item"
                          :value="item"
                        >
                        </el-option>
                      </el-select>
                    </base-input>
                  </div>
                  <div class="col-xl-8 col-sm-6">
                    <label style="margin-top: 10px; font-size: 0.75rem">{{ $t("labels.Datatable.NbrLinePerPage").toUpperCase() }}</label>
                  </div>
                  <base-input class="col-xl-3 col-sm-6">
                    <el-input
                      type="search"
                      class="mb-3 search-input"
                      clearable
                      prefix-icon="el-icon-search"
                      placeholder="Rechercher"
                      aria-controls="datatables"
                      v-model="searchQuery"
                    >
                    </el-input>
                  </base-input>
                </div>
                <!-- hide/show colonne -->
                <base-dropdown menu-classes="dropdown-black">
                  <template slot="title-container">
                    <button class="btn btn-icon btn-round btn-market">
                      <i class="tim-icons el-icon-view"></i>
                    </button>
                  </template>
                    <template v-for="row in rows">
                      <div class="dropdown-header">
                        <base-checkbox v-model="row.show">
                          {{ row.label }}
                        </base-checkbox>
                      </div>
                    </template>
                </base-dropdown>
                <!-- hide/show colonne -->
                <el-table :data="filterlistCommande" v-loading="$apollo.queries.MyCommandes.loading" class="customtable_cmd">
                  <el-table-column
                    prop='date_debut_livraison'
                    label='Date'
                    minWidth='120'
                    show-overflow-tooltip
                    sortable
                    v-if="rows[0].show"
                  >
                  </el-table-column>
                  <el-table-column
                    prop='pk_commande'
                    label='N° commande'
                    minWidth='150'
                    show-overflow-tooltip
                    sortable
                    v-if="rows[1].show"
                  >
                  </el-table-column>
                  <el-table-column
                    prop='nom'
                    label='Nom commande'
                    minWidth='165'
                    show-overflow-tooltip
                    sortable
                    v-if="rows[2].show"
                  >
                    <template scope='scope'>
                      {{ (scope.row.nom) ? scope.row.nom.replace(/Ã©/g,'é').toUpperCase() : '' }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop='montant'
                    label='Montant commande'
                    minWidth='200'
                    show-overflow-tooltip
                    sortable
                    v-if="rows[3].show"
                  >
                    <template scope="scope">
                      {{ formatThounsends(scope.row.montant,true,2) + ' €'}}
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop='livred'
                    label='Leads livrés'
                    minWidth='140'
                    show-overflow-tooltip
                    sortable
                    v-if="rows[4].show"
                  >
                  </el-table-column>
                  <el-table-column
                    prop='reste'
                    label='Restant à livrer'
                    minWidth='145'
                    show-overflow-tooltip
                    v-if="rows[5].show"
                  >
                    <template scope="scope">
                      <template v-if="scope.row.reste >= 0">
                        {{ scope.row.reste }}
                      </template>
                      <template v-else>
                        0
                      </template>
                    </template>
                  </el-table-column>
                  <!-- <el-table-column
                    prop='mode_livraison'
                    label='Mode livraison'
                    minWidth='152'
                    show-overflow-tooltip
                    sortable
                    v-if="rows[6].show"
                  >
                    <template scope="scope">
                      {{ scope.row.mode_livraison.toUpperCase() }}
                    </template>
                  </el-table-column> -->
                  <el-table-column
                    prop='delai_livraison'
                    label='Délai livraison'
                    minWidth='160'
                    show-overflow-tooltip
                    sortable
                    v-if="rows[6].show"
                  >
                    <template scope="scope">
                      <template v-if="scope.row.delai_livraison === 'etale'">
                        ÉTALÉ
                      </template>
                      <template v-else-if="scope.row.delai_livraison === 'immediat'">
                        IMMÉDIAT
                      </template>
                      <template v-else>
                        {{ scope.row.delai_livraison.toUpperCase() }}
                      </template>
                    </template>
                  </el-table-column>
                  <!-- <el-table-column
                    prop='jours'
                    label='Jours livraison'
                    minWidth='157'
                    show-overflow-tooltip
                    sortable
                    v-if="rows[8].show"
                  >
                  </el-table-column> -->
                  <el-table-column
                    label='ÉTAT LIVRAISON'
                    minWidth='135'
                    show-overflow-tooltip       
                    v-if="rows[7].show"            
                  >
                    <template scope="scope">
                      <template v-if="scope.row.date_fin_commande">
                        <span style="color:black"><i class="fa fa-stop"></i></span> &nbsp;&nbsp; TERMINÉ
                      </template>
                      <template v-else-if="scope.row.statut == 'pause'">
                        <template v-if="scope.row.retrait_pause == 1">
                          <span style="color:green;cursor: pointer;" @click="ChangerStatutCommande('relance', scope.row)"><i class="fa fa-play"></i></span>
                        </template>
                        &nbsp;&nbsp;EN PAUSE
                      </template>
                      <template v-else>
                        <template v-if="scope.row.retrait_pause == 1">
                          <span style="color:rgb(204, 11, 11);cursor: pointer;" @click="ChangerStatutCommande('pause', scope.row)"><i class="fa fa-pause"></i></span>
                        </template>
                        &nbsp;&nbsp;EN COURS
                      </template>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop='lead_par_jour'
                    label='Capping'
                    minWidth='115'
                    show-overflow-tooltip
                    sortable
                    v-if="rows[8].show"
                  >
                    <template scope="scope">
                      <template v-if="!scope.row.date_fin_commande || scope.row.lead_par_jour">
                        <template v-if="scope.row.date_fin_commande || scope.row.retrait_pause == 0">
                          {{ scope.row.lead_par_jour }}
                        </template>
                        <template v-else>
                          <base-input :value="scope.row.lead_par_jour" type="number" @keyup.enter.native="changeCapping($event, scope.row)"></base-input>
                        </template>
                      </template>

                    </template>
                  </el-table-column>
                  <el-table-column
                    prop=''
                    label='Etat'
                    minWidth='65'
                    show-overflow-tooltip
                    v-if="rows[9].show"
                  >
                    PAYÉ
                  </el-table-column>
                  <el-table-column
                    prop=''
                    label='Leads'
                    minWidth='73'
                    show-overflow-tooltip
                    v-if="rows[10].show"
                  >
                    <template scope="scope">
                      <template v-if="scope.row.livred != 0">
                        <el-tooltip
                          content="Télécharger le fichier csv"
                          effect="light"
                          :open-delay="300"
                          placement="top"
                        >
                          <base-button
                            @click.native="DownloadCSV(scope.row.pk_commande)"
                            class="btn-link"
                            type="success"
                            size="sm"
                            icon
                          >
                            <i class="tim-icons icon-cloud-download-93 icon-large"></i>
                          </base-button>
                        </el-tooltip>
                      </template>
                      <template v-else>
                        LIVRAISON DES LEADS PRÉVUE ULTÉRIEUREMENT
                      </template>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop=''
                    label='Action'
                    minWidth='100'
                    v-if="rows[11].show"
                  >
                    <template scope="scope">
                      <el-tooltip
                        content="Dupliquer"
                        effect="light"
                        :open-delay="300"
                        placement="top"
                      >
                        <base-button
                          @click="Dupliquer(scope.row.pk_commande)"
                          class="btn-link"
                          type="success"
                          size="sm"
                          icon
                          :disabled="!userCanCmdWeedoMarket || scope.row.pk_commande < 8401"
                        >
                          <i class="tim-icons icon-single-copy-04 icon-large"></i>
                        </base-button>
                      </el-tooltip>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <div
                slot="footer"
                class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <div class="">
                  <p class="card-category">
                    {{ $t('labels.Datatable.Showing') }} {{ from + 1 }}  {{ $t('labels.Datatable.to') }} {{ to }} {{ $t('labels.Datatable.of') }} {{ total }} {{ $t('labels.Datatable.entries') }}
                  </p>
                </div>
                <base-pagination
                  type="market"
                  class="pagination-no-border"
                  v-model="pagination.currentPage"
                  :per-page="pagination.perPage"
                  :total="total"
                >
                </base-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import {Select, Option ,DatePicker} from 'element-ui'
  import { Table, TableColumn } from 'element-ui';
  import { BasePagination, BaseDropdown } from 'src/components';
  import gql from 'graphql-tag'
  import axios from 'axios';
  import swal from 'sweetalert2'

  let today = new Date()

  export default {
    apollo: {
      userCanCmdWeedoMarket: {
        client: 'AUTH',
        query: require("src/graphql/leadmarket/Annonceur/userCanCmdWeedoMarket.gql"),
        fetchPolicy: 'network-only',
        update: data => data.userCanCmdWeedoMarket
      },
      MyCommandes: {
        client: 'MARKET',
        query: require("src/graphql/leadmarket/Annonceur/MyCommandes.gql"),
        variables () {
          return {
            id_utilisateur: JSON.parse(localStorage.getItem('utilisateur')).id
          }
        },
        result({ data, loader, networkStatus }) {
        },
        fetchPolicy: 'network-only',
        update: data => data.getCommandes[0].commandes
      }
    },
    components: {
      BasePagination,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Select.name]: Select,
      [Option.name]: Option,
      BaseDropdown
    },
    data() {
      return {
        userCanCmdWeedoMarket: false,
        searchQuery: '',
        total: 0,
        pagination: {
          perPage: 50,
          currentPage: 1,
          perPageOptions: [10, 25, 50, 100],
          total: 0
        },
        tableColumns: [],
        MyCommandes: [],
        rows: [
          { label: 'Date', show: true },
          { label: 'N° commande', show: true },
          { label: 'Nom commande', show: true },
          { label: 'Montant commande', show: true },
          { label: 'Leads livrés', show: true },
          { label: 'Restant à livrer', show: true },
          // { label: 'Mode livraison', show: true },
          { label: 'Délai livraison', show: true },
          // { label: 'Jours livraison', show: true },
          { label: 'ÉTAT LIVRAISON', show: true },
          { label: 'Capping', show: true },
          { label: 'Etat', show: true },
          { label: 'Leads', show: true },
          { label: 'Action', show: true }
        ]
      }
    },
    computed: {
      filterlistCommande() {
        var list = []
        try {
          list = this.MyCommandes.filter((cammande) => {            
            return cammande.date_debut_livraison.match(this.searchQuery) ||
                   (cammande.pk_commande + '').match(this.searchQuery) ||
                   (cammande.nom + '').match(this.searchQuery) ||
                   (cammande.montant + '').match(this.searchQuery) ||
                   (cammande.livred + '').match(this.searchQuery) ||
                   (cammande.reste + '').match(this.searchQuery) ||
                   (cammande.mode_livraison + '').match(this.searchQuery) ||
                   (cammande.delai_livraison + '').match(this.searchQuery) ||
                   (cammande.jours + '').match(this.searchQuery) ||
                   (cammande.pk_commande + '').match(this.searchQuery) 
          })

          this.total = list.length
          return list.slice(this.from, this.to);
        } catch (error) {
          console.log(error)
          return null
        }
      },
      to() {
        let highBound = this.from + this.pagination.perPage;
        if (this.total < highBound) {
          highBound = this.total;
        }
        return highBound;
      },
      from() {
        return this.pagination.perPage * (this.pagination.currentPage - 1);
      }
    },
    methods: {
      ChangerStatutCommande(status, row) {
        swal.fire({
            title: 'Êtes-vous sûr?',
            text: "Vous êtes en train de modifier l'Etat!",
            width: 400,
            showCancelButton: true,
            confirmButtonColor: '#988fc3',
            cancelButtonColor: '#27293D',
            confirmButtonText: 'Modifier',
            cancelButtonText: 'Annuler'
          }).then((result) => {
            if (result.value) {
              this.$apollo.mutate({
                client: 'MARKET',
                mutation: gql`mutation (
                  $id_utilisateur: Int!,
                  $ref: Int!,
                  $statut: String!
                ) {
                  changerStatutCommande(
                    id_utilisateur: $id_utilisateur,
                    ref: $ref,
                    statut: $statut
                  ){
                    state
                  }
                }`,
                variables: {
                  id_utilisateur: JSON.parse(localStorage.getItem('utilisateur')).id,
                  ref: row.pk_commande,
                  statut: status
                },
              }).then(response => {
                if (response.data.changerStatutCommande.state) {
                  this.$notify({type: 'success', message: this.$t("apiresponse.Stateupdated") })
                  if (status === 'relance') {
                    row.statut = null
                  }
                  else if (status === 'pause') {
                    row.statut = 'pause'
                  }
                }
                else {
                  this.$notify({type: 'warning', message: this.$t("apiresponse.errorupdate") })
                }
                             
              }).catch(error => {
                this.$notify({type: 'warning', message: this.$t("apiresponse.errorupdate") })
              })
            }
          })
      },
      changeCapping(e, row){
        let newvalue = e.target.value;
        
        if(newvalue == '') {
          return
        }
        newvalue = parseInt(newvalue)

        if (newvalue < 10) {
          this.$notify({type: 'warning', message: "Capping doit avoir une valeur de 10 ou plus" })
          return
        }

        if (newvalue < row.lead_par_jour) {
          this.$notify({type: 'warning', message: "Vous n'avez pas le droit de baisser le capping" })
          return
        }        
        else {
          swal.fire({
            title: 'Êtes-vous sûr?',
            text: "Vous êtes en train de modifier le Capping!",
            width: 400,
            showCancelButton: true,
            confirmButtonColor: '#988fc3',
            cancelButtonColor: '#27293D',
            confirmButtonText: 'Modifier',
            cancelButtonText: 'Annuler'
          }).then((result) => {

            if (result.value) {
              this.$apollo.mutate({
                client: 'MARKET',
                mutation: gql`mutation (
                  $id_utilisateur: Int!,
                  $ref: Int!,
                  $column: String!,
                  $value: Int!,
                  $ancien_val: Int!
                ) {
                  changerPrioVolumeCommande(
                    id_utilisateur: $id_utilisateur,
                    ref: $ref,
                    column: $column,
                    value: $value,
                    ancien_val: $ancien_val
                  ){
                    state
                  }
                }`,
                variables: {
                  id_utilisateur: JSON.parse(localStorage.getItem('utilisateur')).id,
                  ref: row.pk_commande,
                  column: "capping",
                  value: newvalue,
                  ancien_val: row.lead_par_jour
                },
              }).then(response => {
                if (response.data.changerPrioVolumeCommande.state) {
                  this.$notify({type: 'success', message: "Capping a été bien modifié." }) 
                  row.lead_par_jour = newvalue
                }
                else {
                  this.$notify({type: 'warning', message: this.$t("apiresponse.errorupdate") })
                }
              }).catch(error => {
                this.$notify({type: 'warning', message: this.$t("apiresponse.errorupdate") })
              })
            }
          })
        }
      },
      formatThounsends: function(value, fixe = false, number = 0) {
        if (fixe) {
          value = value.toFixed(number);
        }
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      },
      DownloadCSV(id_cammande) {

        const url_api = "https://apileadmarket.wee-do-it.net/api/export-Leads-csv";
        let accessToken = localStorage.getItem("token_jwt");
        let formData = new FormData()
        formData.append('id_utilisateur', JSON.parse(localStorage.getItem('utilisateur')).id)
        formData.append('commande', id_cammande)
        formData.append('with_tag', false)
        formData.append('with_fields', true)
        formData.append('export_validate', true)
        formData.append('with_date_collecte', false)

        this.axios
          .post(url_api,formData , {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded',authorization: `Bearer ${accessToken}` },
            responseType: "blob"
          }).then(function(response) {
            console.log(response.data)
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'COMMANDE '+id_cammande+'.csv');
            document.body.appendChild(link);
            link.click();
          }.bind(this))
          .catch(function (error) {
            console.log(error);
          })
        
      },
      Dupliquer(id) {
        let routeData = this.$router.resolve({ name:'cree Commandes Leadmarket Annonceur', params: {commande_id: id} });
        window.open(routeData.href, '_blank');
      }
    },
  }
  </script>

  <style>
    .customtable_cmd .cell{
      word-break: break-word;
    }
  </style>