<template>
  <div>
    <modal
      :show.sync="ShowLeadModal"
      footerClasses="justify-content-center"
      class="modal-showInfoLeads"
      body-classes="p-0"
    >
      <card class="border-0 mb-0">
        <template slot="header">

          <el-tooltip
            content="Quitter"
            effect="light"
            :open-delay="300"
            placement="top"
          >
            <base-button
              @click.native="ShowLeadModal = false"
              class="btn-link float-right"
              type="danger"
              size="sm"
              icon
            >
              <i class="tim-icons icon-simple-remove"></i>
            </base-button>
          </el-tooltip>
        </template>

        <div class="row" v-if="showDetailsLead">
          <card class="col-sm-12">
            <template slot="header">
              <h4 class="title d-inline">DÉTAILS DU PROSPECT</h4>
            </template>
            <div class="table-responsive">
              <base-table :data="[{index:1},{index:2}]" thead-classes="text-primary" >
                <template slot-scope="{ row }">
                  <td class="UpperCase">
                    {{ (row.index ===1) ? 'ID Lead' : showDetailsLead.lead.pk_lead }}
                  </td>
                  <td class="UpperCase">	
                    {{ (row.index ===1) ? 'DATE NAISSANCE' : showDetailsLead.lead.date_naissance }}
                  </td>
                  <td class="UpperCase">	
                    {{ (row.index ===1) ? 'CODE POSTAL' : showDetailsLead.lead.cp }}
                  </td>
                  <td class="UpperCase">
                    {{ (row.index ===1) ? 'VILLE' : showDetailsLead.lead.ville }}
                  </td>
                </template>
              </base-table>
            </div>
          </card>
          <card class="col-lg-6 col-sm-12">
            <template slot="header">
              <h4 class="title d-inline">SYNTHÈSE DU DOSSIER</h4>
            </template>
            <div class="table-responsive">
              <base-table :data="showDetailsLead.inputs" thead-classes="text-primary" >
                <template slot-scope="{ row }">
                  <td class="UpperCase" v-if="row.mapping != 'tel'">
                    {{ (row.champs) ? replaceUTF_8(row.champs) : '' }}
                  </td>
                  <td class="UpperCase" v-if="row.mapping != 'tel'">
                    {{ row.value }}
                  </td>
                </template>
              </base-table>
            </div>
          </card>

          <iframe class="col-lg-6 col-sm-12" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" 
            :src="'https://maps.google.it/maps?q='+ showDetailsLead.lead.cp +'&output=embed'"
          ></iframe>

        </div>

      </card>
    </modal>

    <template v-if='Commande'>
      <base-alert type="success">
        <strong>RÉCAPITUALTIF DE VOTRE COMMANDE</strong> <br/>
        Vous trouverez ci-dessous, les disponibilités immédiates de votre commande. Pour confirmer, cliquez sur « Ajouter au panier » après avoir pré-séléctionné votre lead.
        Vous avez commandé : 
        {{ ( Commande.quantite_lead_exclusif) ? Commande.quantite_lead_exclusif + ' leads Exclusif ,' : ''}}
        {{ ( Commande.quantite_lead_duo) ? Commande.quantite_lead_duo + ' leads Duo ,' : ''}}
        {{ ( Commande.quantite_lead_trio) ? Commande.quantite_lead_trio + ' leads Trio' : ''}}
      </base-alert>
      <el-table ref="Lead_Availability" :data="Lead_Availability" v-loading="$apollo.queries.showCommandeOptionsV2.loading">
        <el-table-column
          prop='type'
          label=''
          minWidth='250'
          show-overflow-tooltip
        >
          <template scope="scope">
            <template v-if="scope.row.type == 'EXCLUSIF'">
              <img src="/img/lead_exclusif.png" style="height: 20%; width: 20%;" alt="" /> Disponibilité pack Exclusif :
            </template>
            <template v-else-if="scope.row.type == 'DUO'">
              <img src="/img/lead_duo.png" style="height: 20%; width: 20%;" alt="" /> Disponibilité pack Duo :
            </template>
            <template v-else-if="scope.row.type == 'TRIO'">
              <img src="/img/lead_trio.png" style="height: 20%; width: 20%;" alt="" /> Disponibilité pack Trio :
            </template>
          </template>
        </el-table-column>
        <el-table-column
          prop='Immediate_delivery'
          label='Livraison immédiate'
          minWidth='170'
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop=''
          label=''
        >
          <template scope="scope">
            <el-tooltip
              content="vider"
              effect="light"
              :open-delay="300"
              placement="top"
            >
              <base-button
                @click.native="ClearLeadImmediate(scope.row)"
                class="btn-link"
                type="danger"
                size="sm"
                icon
              >
              <i class="fa fa-trash icon-large"></i>
              </base-button>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          prop='subsequent_delivery'
          label='Livraison ultérieure'
          minWidth='170'
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop=''
          label=''
        >
          <template scope="scope">
            <el-tooltip
              content="vider"
              effect="light"
              :open-delay="300"
              placement="top"
            >
              <base-button
                @click.native="ClearLeadSubsequent(scope.row)"
                class="btn-link"
                type="danger"
                size="sm"
                icon
              >
              <i class="fa fa-trash icon-large"></i>
              </base-button>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          prop=''
          label=''
          minWidth='260'
        >
          <template scope="scope">
            <el-tooltip
              content="ajouter au panier"
              effect="light"
              :open-delay="300"
              placement="top"
            >
              <base-button
                :class="(disable_addcard_Trio) ? '' : 'blink_addtocard'"
                simple
                type="success"
                size="sm"
                v-if="scope.row.type == 'TRIO'"
                :disabled="disable_addcard_Trio"
                @click="addtoCard(scope.row)"
              >
                <i class="fas fa-shopping-cart icon-large"></i>  {{ (disable_addcard_Trio) ? 'ajouté avec succés' : 'ajouter au panier' }}
              </base-button>
              <base-button
                :class="(disable_addcard_Duo) ? '' : 'blink_addtocard'"
                simple
                type="success"
                size="sm"
                v-else-if="scope.row.type == 'DUO'"
                :disabled="disable_addcard_Duo"
                @click="addtoCard(scope.row)"
              >
                <i class="fas fa-shopping-cart icon-large"></i> {{ (disable_addcard_Duo) ? 'ajouté avec succés' : 'ajouter au panier' }}
              </base-button>
              <base-button
                :class="(disable_addcard_Exclusif) ? '' : 'blink_addtocard'"
                simple
                type="success"
                size="sm"
                v-else-if="scope.row.type == 'EXCLUSIF'"
                :disabled="disable_addcard_Exclusif"
                @click="addtoCard(scope.row)"
              >
                <i class="fas fa-shopping-cart icon-large"></i> {{ (disable_addcard_Exclusif) ? 'ajouté avec succés' : 'ajouter au panier' }}
              </base-button>

            </el-tooltip>
          </template>
          
        </el-table-column>
      </el-table>
      <template v-if="!$apollo.queries.showCommandeOptionsV2.loading">
        <!-- table leads exclusif -->
        <template v-if="showCommandeOptionsV2.results_exclusif.length">
          <hr/>
          LEADS PACK EXCLUSIF
          <hr/>
          <div
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="col-xl-2 col-sm-6">
              <base-input>
                <el-select
                  class="select-primary pagination-select"
                  v-model="paginationExclusif.perPage"
                  placeholder="Per page"
                >
                  <el-option
                    class="select-primary"
                    v-for="item in paginationExclusif.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </base-input>
            </div>
            <!-- <div class="col-xl-8 col-sm-6">
              <label style="margin-top: 10px; font-size: 0.75rem">{{ $t("labels.Datatable.NbrLinePerPage").toUpperCase() }}</label>
            </div> -->
            <!-- <base-input class="col-xl-3 col-sm-6">
              <el-input
                type="search"
                class="mb-3 search-input"
                clearable
                prefix-icon="el-icon-search"
                placeholder="Rechercher"
                aria-controls="datatables"
                v-model="searchQuery"
              >
              </el-input>
            </base-input> -->
          </div>
          <el-table ref="results_exclusif" :data="showCommandeOptionsV2.results_exclusif.slice((paginationExclusif.currentPage-1)*paginationExclusif.perPage,paginationExclusif.currentPage*paginationExclusif.perPage)" @selection-change="handleSelectionExclusif" :row-key="getRowKey">
            <el-table-column
              :reserve-selection="true"
              type="selection"
              >
            </el-table-column>
            <el-table-column
              label='Ref'
              minWidth='250'
              show-overflow-tooltip
            >
              <template scope="scope">
                <label style="cursor: pointer; text-decoration: underline;" @click="showLeadInfo(scope.row, 1)">VOIR LE LEAD </label>
              </template>
            </el-table-column>
            <el-table-column
              label='Gamme'
              minWidth='250'
              show-overflow-tooltip
            >
              EXCLUSIF
            </el-table-column>
            <el-table-column
              prop='cp'
              label='Code Postal'
              minWidth='250'
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop='ville'
              label='ville'
              minWidth='250'
              show-overflow-tooltip
            >
            </el-table-column>
          </el-table>

          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                {{ $t('labels.Datatable.Showing') }} {{ from_exclusif + 1 }}  {{ $t('labels.Datatable.to') }} {{ to_exclusif }} {{ $t('labels.Datatable.of') }} {{ total_exclusif }} {{ $t('labels.Datatable.entries') }}
              </p>
            </div>
            <base-pagination
              type="market"
              class="pagination-no-border"
              v-model="paginationExclusif.currentPage"
              :per-page="paginationExclusif.perPage"
              :total="total_exclusif"
            >
            </base-pagination>
          </div>
        </template>
        <!-- table leads Duo -->
        <template v-if="showCommandeOptionsV2.results_duo.length">
          <hr/>
          LEADS PACK DUO
          <hr/>
          <div
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="col-xl-2 col-sm-6">
              <base-input>
                <el-select
                  class="select-primary pagination-select"
                  v-model="paginationDuo.perPage"
                  placeholder="Per page"
                >
                  <el-option
                    class="select-primary"
                    v-for="item in paginationDuo.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </base-input>
            </div>
            <!-- <div class="col-xl-8 col-sm-6">
              <label style="margin-top: 10px; font-size: 0.75rem">{{ $t("labels.Datatable.NbrLinePerPage").toUpperCase() }}</label>
            </div> -->
            <!-- <base-input class="col-xl-3 col-sm-6">
              <el-input
                type="search"
                class="mb-3 search-input"
                clearable
                prefix-icon="el-icon-search"
                placeholder="Rechercher"
                aria-controls="datatables"
                v-model="searchQuery"
              >
              </el-input>
            </base-input> -->
          </div>
          <el-table ref="results_duo" :data="showCommandeOptionsV2.results_duo.slice((paginationDuo.currentPage-1)*paginationDuo.perPage,paginationDuo.currentPage*paginationDuo.perPage)" @selection-change="handleSelectionDuo" :row-key="getRowKey">
            <el-table-column
              :reserve-selection="true"
              type="selection"
              >
            </el-table-column>
            <el-table-column
              label='Ref'
              minWidth='250'
              show-overflow-tooltip
            >
              <template scope="scope">
                <label style="cursor: pointer; text-decoration: underline;" @click="showLeadInfo(scope.row, 2)">VOIR LE LEAD </label>
              </template>            
            </el-table-column>
            <el-table-column
              label='Gamme'
              minWidth='250'
              show-overflow-tooltip
            >
              DUO
            </el-table-column>
            <el-table-column
              prop='cp'
              label='Code Postal'
              minWidth='250'
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop='ville'
              label='ville'
              minWidth='250'
              show-overflow-tooltip
            >
            </el-table-column>
          </el-table>

          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                {{ $t('labels.Datatable.Showing') }} {{ from_duo + 1 }}  {{ $t('labels.Datatable.to') }} {{ to_duo }} {{ $t('labels.Datatable.of') }} {{ total_duo }} {{ $t('labels.Datatable.entries') }}
              </p>
            </div>
            <base-pagination
              type="market"
              class="pagination-no-border"
              v-model="paginationDuo.currentPage"
              :per-page="paginationDuo.perPage"
              :total="total_duo"
            >
            </base-pagination>
          </div>
        </template>
        <!-- table leads Trio -->
        <template v-if="showCommandeOptionsV2.results_trio.length">
          <hr/>
          LEADS PACK TRIO
          <hr/>
          <div
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="col-xl-2 col-sm-6">
              <base-input>
                <el-select
                  class="select-primary pagination-select"
                  v-model="paginationTrio.perPage"
                  placeholder="Per page"
                >
                  <el-option
                    class="select-primary"
                    v-for="item in paginationTrio.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </base-input>
            </div>
            <!-- <div class="col-xl-8 col-sm-6">
              <label style="margin-top: 10px; font-size: 0.75rem">{{ $t("labels.Datatable.NbrLinePerPage").toUpperCase() }}</label>
            </div> -->
            <!-- <base-input class="col-xl-3 col-sm-6">
              <el-input
                type="search"
                class="mb-3 search-input"
                clearable
                prefix-icon="el-icon-search"
                placeholder="Rechercher"
                aria-controls="datatables"
                v-model="searchQuery"
              >
              </el-input>
            </base-input> -->
          </div>
          <el-table ref="results_trio" :data="showCommandeOptionsV2.results_trio.slice((paginationTrio.currentPage-1)*paginationTrio.perPage,paginationTrio.currentPage*paginationTrio.perPage)" @selection-change="handleSelectionTrio" :row-key="getRowKey">
            <el-table-column
              :reserve-selection="true"
              type="selection"
              >
            </el-table-column>
            <el-table-column
              label='Ref'
              minWidth='250'
              show-overflow-tooltip
            >
              <template scope="scope">
                <label style="cursor: pointer; text-decoration: underline;" @click="showLeadInfo(scope.row, 3)">VOIR LE LEAD </label>
              </template>
            </el-table-column>
            <el-table-column
              label='Gamme'
              minWidth='250'
              show-overflow-tooltip
            >
              TRIO
            </el-table-column>
            <el-table-column
              prop='cp'
              label='Code Postal'
              minWidth='250'
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop='ville'
              label='ville'
              minWidth='250'
              show-overflow-tooltip
            >
            </el-table-column>
          </el-table>

          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                {{ $t('labels.Datatable.Showing') }} {{ from_trio + 1 }}  {{ $t('labels.Datatable.to') }} {{ to_trio }} {{ $t('labels.Datatable.of') }} {{ total_trio }} {{ $t('labels.Datatable.entries') }}
              </p>
            </div>
            <base-pagination
              type="market"
              class="pagination-no-border"
              v-model="paginationTrio.currentPage"
              :per-page="paginationTrio.perPage"
              :total="total_trio"
            >
            </base-pagination>
          </div>
        </template>
      </template>
    </template>
  </div>
</template>

<script>
  import {Select, Option } from 'element-ui'
  import {Table, TableColumn} from 'element-ui'
  import {BaseAlert} from 'src/components'
  import gql from 'graphql-tag'
  import { BasePagination, Modal, BaseTable } from 'src/components';

  export default {
    apollo: {
      showCommandeOptionsV2: {
        client: 'MARKET',
        query: gql` query (
                            $id_utilisateur: ID, $categorie_id: Int, $filtre_id: Int, $quantite_exclusif: Int, $quantite_duo: Int, 
                            $quantite_trio: Int, $options_sante: String, $exclure: String, $departements: String
                          ) {
          showCommandeOptionsV2(
            id_utilisateur: $id_utilisateur,
            categorie_id: $categorie_id, 
            filtre_id: $filtre_id, 
            quantite_exclusif: $quantite_exclusif, 
            quantite_duo: $quantite_duo, 
            quantite_trio: $quantite_trio, 
            options_sante: $options_sante,
            exclure: $exclure,
            departements: $departements
          )
          {
            results_exclusif{
              pk_lead
              ville
              cp
            }
            results_duo{
              pk_lead
              ville
              cp
            }
            results_trio{
              pk_lead
              ville
              cp
            }
            produit_designation
            produit
            quantite_exclusif
            quantite_duo
            quantite_trio
            fk_annonceur
          }
        }`,
        variables() {
          return {
            id_utilisateur: null,
            categorie_id: null,
            filtre_id: null, 
            quantite_exclusif: null, 
            quantite_duo: null, 
            quantite_trio: null,
            options_sante: null,
            exclure: null,
            departements: null
          }
        },
        skip() {
          return !this.Commande
        },
        fetchPolicy: 'network-only',
        update: data => data.showCommandeOptionsV2,
        async result({ data, loader, networkStatus }) {
          
          // Temporary
          if (this.Commande.Product === 12) {
            this.showCommandeOptionsV2.results_trio = []
          }
          // Temporary 
          
          let Lead_Availability = []
          let currentday = new Date().getFullYear() + "-"+ (new Date().getUTCMonth() + 1) + "-" + new Date().getUTCDate()
          // Calculate Lead Exclusif Availability
          if (this.Commande.quantite_lead_exclusif) {
            if (this.Commande.delai_livraison === "immediat") {
              if (this.showCommandeOptionsV2.results_exclusif.length == 0)
                Lead_Availability.push({ type: 'EXCLUSIF' ,Immediate_delivery: 0, subsequent_delivery: this.Commande.quantite_lead_exclusif })
              else if (this.showCommandeOptionsV2.results_exclusif.length >= this.Commande.quantite_lead_exclusif) {
                await this.sleep(1000);
                for (let index = 0; index < this.Commande.quantite_lead_exclusif; index++) {
                  this.toggleSelectionExclusif([this.showCommandeOptionsV2.results_exclusif[index]])
                }
                Lead_Availability.push({ type: 'EXCLUSIF' ,Immediate_delivery: this.Commande.quantite_lead_exclusif, subsequent_delivery: 0 })
              }
              else if (this.showCommandeOptionsV2.results_exclusif.length < this.Commande.quantite_lead_exclusif) {
                Lead_Availability.push({ type: 'EXCLUSIF' ,Immediate_delivery: this.showCommandeOptionsV2.results_exclusif.length, subsequent_delivery: parseInt(this.Commande.quantite_lead_exclusif) - this.showCommandeOptionsV2.results_exclusif.length })
                await this.sleep(1000);
                for (let index = 0; index < this.showCommandeOptionsV2.results_exclusif.length; index++) {
                  this.toggleSelectionExclusif([this.showCommandeOptionsV2.results_exclusif[index]])
                }
              }
            }
            else if (this.Commande.delai_livraison === "etale") {
              if (this.Commande.date_debut_livraison_client === currentday && this.showCommandeOptionsV2.results_exclusif.length != 0) {
                if (this.showCommandeOptionsV2.results_exclusif.length >= this.Commande.lead_par_jour) {
                  await this.sleep(1000);
                  for (let index = 0; index < this.Commande.lead_par_jour; index++) {
                    this.toggleSelectionExclusif([this.showCommandeOptionsV2.results_exclusif[index]])
                  }
                  Lead_Availability.push({ type: 'EXCLUSIF' ,Immediate_delivery: parseInt(this.Commande.lead_par_jour), subsequent_delivery: parseInt(this.Commande.quantite_lead_exclusif) - parseInt(this.Commande.lead_par_jour) })
                }
                else {
                  await this.sleep(1000);
                  for (let index = 0; index < this.showCommandeOptionsV2.results_exclusif.length; index++) {
                    this.toggleSelectionExclusif([this.showCommandeOptionsV2.results_exclusif[index]])
                  }
                  Lead_Availability.push({ type: 'EXCLUSIF' ,Immediate_delivery: this.showCommandeOptionsV2.results_exclusif.length, subsequent_delivery: parseInt(this.Commande.quantite_lead_exclusif) - this.showCommandeOptionsV2.results_exclusif.length })
                }
              }
              else {
                Lead_Availability.push({ type: 'EXCLUSIF' ,Immediate_delivery: 0, subsequent_delivery: this.Commande.quantite_lead_exclusif })
              }
            }
          }

          // Calculate Lead Duo Availability
          if (this.Commande.quantite_lead_duo) {
            if (this.Commande.delai_livraison === "immediat") {
              if (this.showCommandeOptionsV2.results_duo.length == 0)
                Lead_Availability.push({ type: 'DUO' ,Immediate_delivery: 0, subsequent_delivery: this.Commande.quantite_lead_duo })
              else if (this.showCommandeOptionsV2.results_duo.length >= this.Commande.quantite_lead_duo) {
                await this.sleep(1000);
                for (let index = 0; index < this.Commande.quantite_lead_duo; index++) {
                  this.toggleSelectionDuo([this.showCommandeOptionsV2.results_duo[index]])
                }
                Lead_Availability.push({ type: 'DUO' ,Immediate_delivery: this.Commande.quantite_lead_duo, subsequent_delivery: 0 })
              }
              else if (this.showCommandeOptionsV2.results_duo.length < this.Commande.quantite_lead_duo) {
                await this.sleep(1000);
                for (let index = 0; index < this.showCommandeOptionsV2.results_duo.length; index++) {
                  this.toggleSelectionDuo([this.showCommandeOptionsV2.results_duo[index]])
                }
                Lead_Availability.push({ type: 'DUO' ,Immediate_delivery: this.showCommandeOptionsV2.results_duo.length, subsequent_delivery: parseInt(this.Commande.quantite_lead_duo) - this.showCommandeOptionsV2.results_duo.length })
              }
            }
            else if (this.Commande.delai_livraison === "etale") {
              if (this.Commande.date_debut_livraison_client === currentday && this.showCommandeOptionsV2.results_duo.length != 0) {
                if (this.showCommandeOptionsV2.results_duo.length >= this.Commande.lead_par_jour) {
                  await this.sleep(1000);
                  for (let index = 0; index < this.Commande.lead_par_jour; index++) {
                    this.toggleSelectionDuo([this.showCommandeOptionsV2.results_duo[index]])
                  }
                  Lead_Availability.push({ type: 'DUO' ,Immediate_delivery: parseInt(this.Commande.lead_par_jour), subsequent_delivery: parseInt(this.Commande.quantite_lead_duo) - parseInt(this.Commande.lead_par_jour) })
                }
                else {
                  await this.sleep(1000);
                  for (let index = 0; index < this.showCommandeOptionsV2.results_duo.length; index++) {
                    this.toggleSelectionDuo([this.showCommandeOptionsV2.results_duo[index]])
                  }
                  Lead_Availability.push({ type: 'DUO' ,Immediate_delivery: this.showCommandeOptionsV2.results_duo.length, subsequent_delivery: parseInt(this.Commande.quantite_lead_duo) - this.showCommandeOptionsV2.results_duo.length })
                }
              }
              else {
                Lead_Availability.push({ type: 'DUO' ,Immediate_delivery: 0, subsequent_delivery: this.Commande.quantite_lead_duo })
              }
            }
          }

          // Calculate Lead Trio Availability
          if (this.Commande.quantite_lead_trio) {
            if (this.Commande.delai_livraison === "immediat") {
              if (this.showCommandeOptionsV2.results_trio.length == 0)
                Lead_Availability.push({ type: 'TRIO' ,Immediate_delivery: 0, subsequent_delivery: this.Commande.quantite_lead_trio })
              else if (this.showCommandeOptionsV2.results_trio.length >= this.Commande.quantite_lead_trio) {
                await this.sleep(1000);
                for (let index = 0; index < this.Commande.quantite_lead_trio; index++) {
                  this.toggleSelectionTrio([this.showCommandeOptionsV2.results_trio[index]])
                }
                Lead_Availability.push({ type: 'TRIO' ,Immediate_delivery: this.Commande.quantite_lead_trio, subsequent_delivery: 0 })
              }
              else if (this.showCommandeOptionsV2.results_trio.length < this.Commande.quantite_lead_trio) {
                await this.sleep(1000);
                for (let index = 0; index < this.showCommandeOptionsV2.results_trio.length; index++) {
                  this.toggleSelectionTrio([this.showCommandeOptionsV2.results_trio[index]])
                }
                Lead_Availability.push({ type: 'TRIO' ,Immediate_delivery: this.showCommandeOptionsV2.results_trio.length, subsequent_delivery: parseInt(this.Commande.quantite_lead_trio) - this.showCommandeOptionsV2.results_trio.length })
              }
            }
            else if (this.Commande.delai_livraison === "etale") {
              if (this.Commande.date_debut_livraison_client === currentday && this.showCommandeOptionsV2.results_trio.length != 0) {
                if (this.showCommandeOptionsV2.results_trio.length >= this.Commande.lead_par_jour) {
                  await this.sleep(1000);
                  for (let index = 0; index < this.Commande.lead_par_jour; index++) {
                    this.toggleSelectionTrio([this.showCommandeOptionsV2.results_trio[index]])
                  }
                  Lead_Availability.push({ type: 'TRIO' ,Immediate_delivery: parseInt(this.Commande.lead_par_jour), subsequent_delivery: parseInt(this.Commande.quantite_lead_trio) - parseInt(this.Commande.lead_par_jour) })
                }
                else {
                  await this.sleep(1000);
                  for (let index = 0; index < this.showCommandeOptionsV2.results_trio.length; index++) {
                    this.toggleSelectionTrio([this.showCommandeOptionsV2.results_trio[index]])
                  }
                  Lead_Availability.push({ type: 'TRIO' ,Immediate_delivery: this.showCommandeOptionsV2.results_trio.length, subsequent_delivery: parseInt(this.Commande.quantite_lead_trio) - this.showCommandeOptionsV2.results_trio.length })
                }
              }
              else {
                Lead_Availability.push({ type: 'TRIO' ,Immediate_delivery: 0, subsequent_delivery: this.Commande.quantite_lead_trio })
              }
            }
          }

          this.Lead_Availability = Lead_Availability
        },
      },
    },
    components: {
      BasePagination,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      BaseAlert,
      [Select.name]: Select,
      [Option.name]: Option,
      Modal,
      BaseTable
    },
    computed: {
      total_trio() {
        if (this.showCommandeOptionsV2) 
          return this.showCommandeOptionsV2.results_trio.length
        else
          return 0
      },
      to_trio() {
        let highBound = this.from_trio + this.paginationTrio.perPage;
        if (this.total_trio < highBound) {
          highBound = this.total_trio;
        }
        return highBound;
      },
      from_trio() {
        return this.paginationTrio.perPage * (this.paginationTrio.currentPage - 1);
      },
      total_exclusif() {
        if (this.showCommandeOptionsV2) 
          return this.showCommandeOptionsV2.results_exclusif.length
        else
          return 0
      },
      to_exclusif() {
        let highBound = this.from_exclusif + this.paginationExclusif.perPage;
        if (this.total_exclusif < highBound) {
          highBound = this.total_exclusif;
        }
        return highBound;
      },
      from_exclusif() {
        return this.paginationExclusif.perPage * (this.paginationExclusif.currentPage - 1);
      },
      total_duo() {
        if (this.showCommandeOptionsV2) 
          return this.showCommandeOptionsV2.results_duo.length
        else
          return 0
      },
      to_duo() {
        let highBound = this.from_duo + this.paginationDuo.perPage;
        if (this.total_duo < highBound) {
          highBound = this.total_duo;
        }
        return highBound;
      },
      from_duo() {
        return this.paginationDuo.perPage * (this.paginationDuo.currentPage - 1);
      }
    },
    data() {
      return {
        LeadsExclusif_Selected: [],
        LeadsDuo_Selected: [],
        LeadsTrio_Selected: [],
        Card_LeadsExclusif_Selected: [],
        Card_LeadsDuo_Selected: [],
        Card_LeadsTrio_Selected: [],
        disable_addcard_Exclusif: false,
        disable_addcard_Duo: false,
        disable_addcard_Trio: false,
        ShowLeadModal: false,
        showDetailsLead: null,
        paginationTrio: {
          perPage: 50,
          currentPage: 1,
          perPageOptions: [50, 100, 250, 500],
          total: 0
        },
        paginationExclusif: {
          perPage: 50,
          currentPage: 1,
          perPageOptions: [50, 100, 250, 500],
          total: 0
        },
        paginationDuo: {
          perPage: 50,
          currentPage: 1,
          perPageOptions: [50, 100, 250, 500],
          total: 0
        },
        Commande: null,
        Lead_Availability: [],
      }
    },
    mounted() {
      this.disable_addcard_Exclusif = false
      this.disable_addcard_Duo = false
      this.disable_addcard_Trio = false

      this.$root.$on('Commande_Object', (Commande_Object) => {
        this.LeadsExclusif_Selected = []
        this.LeadsDuo_Selected = []
        this.LeadsTrio_Selected = []
        this.Card_LeadsExclusif_Selected = []
        this.Card_LeadsDuo_Selected = []
        this.Card_LeadsTrio_Selected = []
        this.Commande =  Commande_Object
        this.$apollo.queries.showCommandeOptionsV2.refetch({
          id_utilisateur: JSON.parse(localStorage.getItem('utilisateur')).id,
          categorie_id: this.Commande.Product,
          filtre_id: this.Commande.Filtre, 
          quantite_exclusif: this.Commande.quantite_lead_exclusif, 
          quantite_duo: this.Commande.quantite_lead_duo, 
          quantite_trio: this.Commande.quantite_lead_trio,
          options_sante: this.Commande.options_sante,
          exclure: this.Commande.exclure,
          departements: this.Commande.Departement_parse
        })
      })
    },

    methods: {
      async showLeadInfo(row, pack_id){
        await this.$apollo.query({
          client: 'MARKET',
          query: require("src/graphql/leadmarket/Annonceur/showDetailsLead.gql"),
          variables: {
            id_utilisateur: JSON.parse(localStorage.getItem('utilisateur')).id,
            lead_id: row.pk_lead,
            pack_id: pack_id
          },
          fetchPolicy: 'network-only'
        }).then(data => {
          this.showDetailsLead = data.data.showDetailsLead
        }).catch(error => {
          console.log(error)
        })
        this.ShowLeadModal = true
      },
      async validate() {
        
        if (this.$apollo.queries.showCommandeOptionsV2.loading) {
          return false
        }

        if (!this.disable_addcard_Exclusif && !this.disable_addcard_Duo && !this.disable_addcard_Trio) {
          this.$notify({type: 'warning', message: "votre pannier est vide !" })
          return false
        }

        /// ceck solde         ///
        let exclusif
        let duo
        let trio
        let exclusif_now = 0
        let exclusif_later = 0
        if (this.disable_addcard_Exclusif) {
          exclusif_now = (exclusif = this.Lead_Availability.find(x => x.type === 'EXCLUSIF')) ? exclusif.Immediate_delivery : 0
          exclusif_later = (exclusif =this.Lead_Availability.find(x => x.type === 'EXCLUSIF')) ? exclusif.subsequent_delivery : 0
        }
        let exclusif_total = parseInt(exclusif_now) + parseInt(exclusif_later)
        let duo_now = 0
        let duo_later = 0
        if (this.disable_addcard_Duo) {
          duo_now = (duo =this.Lead_Availability.find(x => x.type === 'DUO')) ? duo.Immediate_delivery : 0
          duo_later = (duo =this.Lead_Availability.find(x => x.type === 'DUO')) ? duo.subsequent_delivery : 0
        }
        let duo_total = parseInt(duo_now) + parseInt(duo_later)
        let trio_now = 0
        let trio_later = 0
        if (this.disable_addcard_Trio) {
          trio_now = (trio =this.Lead_Availability.find(x => x.type === 'TRIO')) ? trio.Immediate_delivery : 0
          trio_later = (trio =this.Lead_Availability.find(x => x.type === 'TRIO')) ? trio.subsequent_delivery : 0
        }
        let trio_total = parseInt(trio_now) + parseInt(trio_later)
        // Emprunteur check 
        if (this.Commande.Product == 12) {
          if ((exclusif_total + duo_total + trio_total) < 5) {
            this.$notify({type: 'warning', message: "Votre commande doit contenir au minimum 5 leads." })
            return false
          }
        }
        // Emprunteur check 
        else {
          if ((exclusif_total + duo_total + trio_total) < 10) {
            this.$notify({type: 'warning', message: "Votre commande doit contenir au minimum 10 leads." })
            return false
          }
        }
        let total_ht = (exclusif_total * this.Commande.prix_unitaire_lead_exclusif) +
                      (duo_total * this.Commande.prix_unitaire_lead_duo) +
                      (trio_total * this.Commande.prix_unitaire_lead_trio)
        let check_solde = false
        await this.$apollo.query({
          client: 'MARKET',
          query: gql` query ($id_utilisateur: ID, $montant: Float) {
            soldeSuffisant(id_utilisateur: $id_utilisateur, montant: $montant) {
                state
              }
            }`,
            variables: {
              id_utilisateur: JSON.parse(localStorage.getItem('utilisateur')).id,
              montant: total_ht
            },
            fetchPolicy: 'network-only'
          }).then(data => {
            check_solde = data.data.soldeSuffisant.state
          }).catch((error) => {
            check_solde = false
          })
          if (!check_solde) {
            this.$notify({type: 'warning', message: "Vous n'avez pas de solde !" })
            return false
          }
          this.Commande.montant = total_ht
        /// ceck solde         ///
        let id_exclusif_selected = ""
        let id_duo_selected = ""
        let id_trio_selected = ""
        // Exclusif
        if(this.Card_LeadsExclusif_Selected.length && this.disable_addcard_Exclusif) {
          this.Card_LeadsExclusif_Selected.forEach(element => {
            id_exclusif_selected += element.pk_lead + ","
          });
          id_exclusif_selected = id_exclusif_selected.slice(0, -1)
        }
        // Duo
        if(this.Card_LeadsDuo_Selected.length && this.disable_addcard_Duo) {
          this.Card_LeadsDuo_Selected.forEach(element => {
            id_duo_selected += element.pk_lead + ","
          });
          id_duo_selected = id_duo_selected.slice(0, -1)
        }
        // Trio
        if(this.Card_LeadsTrio_Selected.length && this.disable_addcard_Trio) {
          this.Card_LeadsTrio_Selected.forEach(element => {
            id_trio_selected += element.pk_lead + ","
          });
          id_trio_selected = id_trio_selected.slice(0, -1)
        }
        // send add to card params
        this.$root.$emit('disable_addcard_Exclusif', this.disable_addcard_Exclusif)
        this.$root.$emit('disable_addcard_Duo', this.disable_addcard_Duo)
        this.$root.$emit('disable_addcard_Trio', this.disable_addcard_Trio)
        // send add to card params
        this.$root.$emit('Commande_Object2', this.Commande)
        this.$root.$emit('Lead_Availability', this.Lead_Availability)
        this.$root.$emit('id_exclusif_selected', id_exclusif_selected)
        this.$root.$emit('id_duo_selected', id_duo_selected)
        this.$root.$emit('id_trio_selected', id_trio_selected)
        let elmnt = document.getElementById('TOPPAGE');
        elmnt.scrollIntoView(false);

        return true
      },
      addtoCard(row) {
        if (row.type == "TRIO") {
          this.Card_LeadsTrio_Selected = this.LeadsTrio_Selected
          this.disable_addcard_Trio = true

          let trio
          let trio_now = (trio =this.Lead_Availability.find(x => x.type === 'TRIO')) ? trio.Immediate_delivery : 0
          let trio_later = (trio =this.Lead_Availability.find(x => x.type === 'TRIO')) ? trio.subsequent_delivery : 0
          
          this.$store.commit("setLeadsPanier_trio", parseInt(trio_now) + parseInt(trio_later));
        }
        else if (row.type == "DUO") {
          this.Card_LeadsDuo_Selected = this.LeadsDuo_Selected
          this.disable_addcard_Duo = true

          let duo
          let duo_now = (duo =this.Lead_Availability.find(x => x.type === 'DUO')) ? duo.Immediate_delivery : 0
          let duo_later = (duo =this.Lead_Availability.find(x => x.type === 'DUO')) ? duo.subsequent_delivery : 0

          this.$store.commit("setLeadsPanier_duo", parseInt(duo_now) + parseInt(duo_later));
        }
        else if (row.type == "EXCLUSIF") {
          this.Card_LeadsExclusif_Selected = this.LeadsExclusif_Selected
          this.disable_addcard_Exclusif = true

          let exclusif
          let exclusif_now = (exclusif = this.Lead_Availability.find(x => x.type === 'EXCLUSIF')) ? exclusif.Immediate_delivery : 0
          let exclusif_later = (exclusif =this.Lead_Availability.find(x => x.type === 'EXCLUSIF')) ? exclusif.subsequent_delivery : 0

          this.$store.commit("setLeadsPanier_exclusif", parseInt(exclusif_now) + parseInt(exclusif_later));
        }
      },
      getRowKey(row) {
        return row.pk_lead
      },
      handleSelectionExclusif(rows) {
        this.Lead_Availability.forEach(row => {
          if (row.type == 'EXCLUSIF') {
            row.Immediate_delivery = rows.length
          }
        });
        this.LeadsExclusif_Selected = rows
        this.disable_addcard_Exclusif = false
        this.disable_addcard_Duo = false
        this.disable_addcard_Trio = false
        this.$store.commit("setLeadsPanier_exclusif", 0);
        this.$store.commit("setLeadsPanier_trio", 0);
        this.$store.commit("setLeadsPanier_duo", 0);
      },
      handleSelectionTrio(rows) {
        this.Lead_Availability.forEach(row => {
          if (row.type == 'TRIO') {
            row.Immediate_delivery = rows.length
          }
        });
        this.LeadsTrio_Selected = rows
        this.disable_addcard_Exclusif = false
        this.disable_addcard_Duo = false
        this.disable_addcard_Trio = false
        this.$store.commit("setLeadsPanier_exclusif", 0);
        this.$store.commit("setLeadsPanier_trio", 0);
        this.$store.commit("setLeadsPanier_duo", 0);
      },
      handleSelectionDuo(rows) {
        this.Lead_Availability.forEach(row => {
          if (row.type == 'DUO') {
            row.Immediate_delivery = rows.length
          }
        });
        this.LeadsDuo_Selected = rows
        this.disable_addcard_Exclusif = false
        this.disable_addcard_Duo = false
        this.disable_addcard_Trio = false
        this.$store.commit("setLeadsPanier_exclusif", 0);
        this.$store.commit("setLeadsPanier_trio", 0);
        this.$store.commit("setLeadsPanier_duo", 0);
      },
      toggleSelectionExclusif(rows) {
        if (rows) {
          rows.forEach(row => {
            this.$refs.results_exclusif.toggleRowSelection(row);
          });
        } else {
          this.$refs.results_exclusif.clearSelection();
        }
      },
      toggleSelectionDuo(rows) {
        if (rows) {
          rows.forEach(row => {
            this.$refs.results_duo.toggleRowSelection(row);
          });
        } else {
          this.$refs.results_duo.clearSelection();
        }
      },
      toggleSelectionTrio(rows) {
        if (rows) {
          rows.forEach(row => {
            this.$refs.results_trio.toggleRowSelection(row);
          });
        } else {
          this.$refs.results_trio.clearSelection();
        }
      },
      sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      },
      ClearLeadImmediate(row) {
        if (row.type == 'EXCLUSIF') {
          this.toggleSelectionExclusif()
        }
        else if (row.type == 'DUO') {
          this.toggleSelectionDuo()
        }
        else if (row.type == 'TRIO') {
          this.toggleSelectionTrio()
        }
        row.Immediate_delivery = 0
        this.disable_addcard_Exclusif = false
        this.disable_addcard_Duo = false
        this.disable_addcard_Trio = false
      },
      ClearLeadSubsequent(row) {
        if(row.subsequent_delivery) {
          row.subsequent_delivery = 0
          this.disable_addcard_Exclusif = false
          this.disable_addcard_Duo = false
          this.disable_addcard_Trio = false
        }
      },
      replaceUTF_8(string) {
        if(!string) 
          return ''

        for (const [key, value] of Object.entries(this.$store.state.UT8_array)) {
          string = string.replaceAll(value, key)
        }
        
        return string
      }
    },
  }
</script>

<style>

  .blink_addtocard {
    animation: blinker 2s linear infinite;
  }
  
  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
  </style>