<template>
  <div class="content">
    <div class="row mt-5">
      <div class="col-md-12">
        <h3>PARAMÈTRES FILTRE</h3>
      </div>
      <div class="col-md-12">
        <div class="btn-group btn-group-toggle float-right mb-3" data-toggle="buttons">
          <base-button
            @click="addFilters()"
            class="btn btn-round btn-just-icon btn-market"
          ><i class="far fa-save"></i> SAUVEGARDER</base-button>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <base-alert type="warning" dismissible>
            <center>
              La personnalisation des filtres vous permet d’augmenter votre ciblage et donc votre taux de transformation. 
              La multiplication des filtres réduit le volume de leads possibles et augmente le coût du contact.
            </center>
          </base-alert>
          <form>
            <div class="row justify-content-center">
              <base-input label="NOM DU FILTRE"  placeholder="NOM DU FILTRE"
                v-model="Filter.cname"
                class="col-sm-5"
                v-validate="'required'"
                name="nom du filtre"
                :error="getError('nom du filtre')"
                type="text">
              </base-input>
              <base-input label="Produit" :error="getError('Produit')" class="col-sm-5">
                <el-select class="select-primary"
                  filterable
                  placeholder="PRODUIT"
                  name="Produit"
                  v-validate="'required'"
                  v-model="Filter.Product">
                  <el-option 
                    v-for="option in ListProduct"
                    class="select-primary"
                    :value="option.pk_categorie"
                    :label="option.designation.replace(/Ã©/g,'é').toUpperCase()"
                    :key="option.id">
                  </el-option>
                </el-select>
              </base-input>
            </div>
          </form>
        </div>
      </div>
      <div class="card">
        <span slot="header" class="badge-pill badge-market tab-title" style="font-weight: bold;">
          CONDITIONS
        </span>
        <div class="card-body">
          <div class="row">
            <label class="col-sm-2 mt-2">CHAMPS DU PROSPECT</label>
            <base-input class="col-sm-5" v-if="Filter.Product === 1">
              <el-select class="select-primary" v-model="Conditions.prospect">
                <el-option value="age_prospect" label="AGE DE PROSPECT"></el-option>
                <el-option value="regime" label="RÉGIME DE PROSPECT"></el-option>
                <el-option value="profession" label="PROFESSION DE PROSPECT"></el-option>
              </el-select>
            </base-input>
            <base-input class="col-sm-5" v-if="Filter.Product === 2">
              <el-select class="select-primary" v-model="Conditions.prospect">
                <el-option value="tout_auto" label="TOUS LES CHAMPS FILTRANTS"></el-option>
                <el-option value="date_mise_circulation" label="DATE DE MISE EN CIRCULATION"></el-option>
                <el-option value="nombre_an_assure" label="NOMBRE D’ANNÉES D’ASSURANCE"></el-option>
                <el-option value="bonus_malus_auto" label="BONUS MALUS AUTO"></el-option>
                <el-option value="resilie" label="RÉSILIATION D’UN AUTRE ASSUREUR INFÉRIEUR À 2 ANS"></el-option>
                <el-option value="retrait_suspension" label="RETRAIT OU SUSPENSION DE PERMIS SUR LES 2 DERNIÈRES ANNÉES"></el-option>
                <el-option value="sinistre" label="NOMBRE DE SINISTRES RESPONSABLES DEPUIS 3 ANS"></el-option>
                <el-option value="date_obtention_permis" label="DATE D’OBTENTION DU PERMIS"></el-option>
                <el-option value="marque" label="MARQUE DU VÉHICULE À ASSURER"></el-option>
                <el-option value="modele" label="MODÈLE DU VÉHICULE À ASSURER"></el-option>
              </el-select>
            </base-input>
            <base-input class="col-sm-5" v-if="Filter.Product === 3">
              <el-select class="select-primary" v-model="Conditions.prospect">
                <el-option value="tout_mrh" label="TOUS LES CHAMPS FILTRANTS"></el-option>
                <el-option value="type_logement" label="TYPE DE LOGEMENT"></el-option>
                <el-option value="occupant" label="TYPE DE SOUSCRIPTEUR"></el-option>
                <el-option value="nbre_pieces_principales" label="NOMBRE DE PIÈCES INFÉRIEUR À 30M²"></el-option>
                <el-option value="plus_30" label="NOMBRE DE PIÈCES SUPÉRIEURES À 30M²"></el-option>
                <el-option value="cp" label="CODE POSTAL"></el-option>
              </el-select>
            </base-input>
            <base-input class="col-sm-5" v-if="Filter.Product === 4">
              <el-select class="select-primary" v-model="Conditions.prospect">
                <el-option value="tout_animaux" label="TOUS LES CHAMPS FILTRANTS"></el-option>
                <el-option value="type_animal" label="ANIMAL À ASSURER"></el-option>
                <el-option value="date_naissance_animal" label="DATE DE NAISSANCE DE L’ANIMAL"></el-option>
                <el-option value="race_roise" label="ANIMAL DE RACE CROISÉE"></el-option>
                <el-option value="tatoue" label="ANIMAL TATOUÉ OU PUCÉ"></el-option>
              </el-select>
            </base-input>
            <base-input class="col-sm-5" v-if="Filter.Product === 8">
              <el-select class="select-primary" v-model="Conditions.prospect">
                <el-option value="age_prospect" label="AGE DE PROSPECT"></el-option>
                <el-option value="profession" label="PROFESSION DE PROSPECT"></el-option>
              </el-select>
            </base-input>
          </div>
          
          <div class="row">
            <label class="col-3"><b>CHAMPS :</b></label>
            <label class="col-9"><b>VALEUR :</b></label>
          </div>
          <!-- start Assurance Sante individuelle -->
          <div class="row" v-if="show.age_prospect">
            <label class="col-3">AGE DE PROSPECT :</label>
            <base-input type="number" placeholder="ENTRE" class="col-4" v-model="Conditions.age_prospect_from"></base-input>
            <base-input type="number" placeholder="ET" class="col-4" v-model="Conditions.age_prospect_to"></base-input>
            <base-button
              @click.native="show.age_prospect = false"
              class="col edit btn-link"
              type="danger"
              size="sm"
              icon
            >
              <i class="tim-icons icon-simple-remove"></i>
            </base-button>
          </div>
          <div class="row" v-if="show.regime_prospect">
            <label class="col-3">RÉGIME DE PROSPECT :</label>
            <base-input class="col-2">
              <el-select class="select-primary" v-model="Conditions.condition_regime">
                <el-option value="egale" label="EGALE À"></el-option>
                <el-option value="not_egale" label="EXCLURE"></el-option>
              </el-select>
            </base-input>
            <base-input class="col-6">
              <el-select class="select-primary" clearable multiple v-model="Conditions.regime">
                <el-option value="Salarié" label="SALARIÉ"></el-option>
                <el-option value="Retraité" label="RETRAITÉ"></el-option>
                <el-option value="Alsace Moselle salarié" label="ALSACE MOSELLE SALARIÉ"></el-option>
                <el-option value="Salarié agricole" label="SALARIÉ AGRICOLE"></el-option>
                <el-option value="TNS Indépendant" label="TNS INDÉPENDANT"></el-option>
              </el-select>
            </base-input>
            <base-button
              @click.native="show.regime_prospect = false"
              class="col edit btn-link"
              type="danger"
              size="sm"
              icon
            >
              <i class="tim-icons icon-simple-remove"></i>
            </base-button>
          </div>
          <div class="row" v-if="show.profession_prospect">
            <label class="col-3">PROFESSION DE PROSPECT :</label>
            <base-input class="col-2">
              <el-select class="select-primary" v-model="Conditions.condition_profession">
                <el-option value="egale" label="EGALE À"></el-option>
                <el-option value="not_egale" label="EXCLURE"></el-option>
              </el-select>
            </base-input>
            <base-input class="col-6">
              <el-select class="select-primary" clearable multiple v-model="Conditions.profession">
                <el-option value="Commerçant" label="COMMERÇANT"></el-option>
                <el-option value="Artisan" label="ARTISAN"></el-option>
                <el-option value="Chef d entreprise" label="CHEF D'ENTREPRISE"></el-option>
                <el-option value="Profession libérale sédentaire" label="PROFESSION LIBÉRALE SÉDENTAIRE"></el-option>
                <el-option value="Profession libérale non sédentaire" label="PROFESSION LIBÉRALE NON SÉDENTAIRE"></el-option>
              </el-select>
            </base-input>
            <base-button
              @click.native="show.profession_prospect = false"
              class="col edit btn-link"
              type="danger"
              size="sm"
              icon
            >
              <i class="tim-icons icon-simple-remove"></i>
            </base-button>
          </div>
          <!-- end Assurance Sante individuelle -->

          <!-- start Assurance Auto -->
          <div class="row" v-if="show.date_mise_circulation">
            <label class="col-3">DATE DE MISE EN CIRCULATION :</label>
            <base-input class="col-2">
              <el-select class="select-primary" v-model="Conditions.condition_date_mise_circulation">
                <el-option value="<" label="INFÉRIEUR À"></el-option>
                <el-option value=">" label="SUPÉRIEUR À"></el-option>
              </el-select>
            </base-input>
            <base-input class="col-sm-5">
              <el-date-picker 
                v-model="Conditions.date_mise_circulation" 
                value-format="dd-MM-YYY" 
                type="date" 
                placeholder="Date">
              </el-date-picker>
            </base-input>
            <base-button
              @click.native="show.date_mise_circulation = false"
              class="col edit btn-link"
              type="danger"
              size="sm"
              icon
            >
              <i class="tim-icons icon-simple-remove"></i>
            </base-button>
          </div>
          <div class="row" v-if="show.nombre_an_assure">
            <label class="col-3">NOMBRE D’ANNÉES D’ASSURANCE :</label>
            <base-input class="col-2">
              <el-select class="select-primary" v-model="Conditions.condition_nombre_an_assure">
                <el-option value="<" label="INFÉRIEUR À"></el-option>
                <el-option value=">" label="SUPÉRIEUR À"></el-option>
              </el-select>
            </base-input>
            <base-input class="col-5">
              <el-select class="select-primary" v-model="Conditions.nombre_an_assure">
                <el-option value="1" label="1 AN"></el-option>
                <el-option value="2" label="2 ANS"></el-option>
              </el-select>
            </base-input>
            <base-button
              @click.native="show.nombre_an_assure = false"
              class="col edit btn-link"
              type="danger"
              size="sm"
              icon
            >
              <i class="tim-icons icon-simple-remove"></i>
            </base-button>
          </div>

          <div class="row" v-if="show.bonus_malus_auto">
            <label class="col-3">BONUS MALUS AUTO :</label>
            <base-input class="col-2">
              <el-select class="select-primary" v-model="Conditions.condition_bonus_malus_auto">
                <el-option value="entre" label="ENTRE"></el-option>
              </el-select>
            </base-input>
            <base-input class="col-5">
              <el-select class="select-primary" multiple v-model="Conditions.bonus_malus_auto">
                <el-option value="0.50-0.90" label="0,50 (SOIT 50% DE BONUS) ET 0,90 SOIT (SOIT 10% DE BONUS)"></el-option>
                <el-option value="0.91-1.00" label="0,91 (SOIT 9% DE BONUS) ET 1  SOIT (SOIT NI BONUS NI MALUS)"></el-option>
                <el-option value="1.01-1.50" label="1,01  (SOIT 1% DE MALUS) ET 1,5  SOIT (SOIT 50% DE MALUS)"></el-option>
                <el-option value="1.51-3.50" label="1,51 (SOIT 51% DE MALUS) ET 3,5 SOIT (SOIT 250% DE MALUS)"></el-option>
              </el-select>
            </base-input>
            <base-button
              @click.native="show.bonus_malus_auto = false"
              class="col edit btn-link"
              type="danger"
              size="sm"
              icon
            >
              <i class="tim-icons icon-simple-remove"></i>
            </base-button>
          </div>

          <div class="row" v-if="show.resilie">
            <label class="col-3">RÉSILIATION D’UN AUTRE ASSUREUR INFÉRIEUR À 2 ANS :</label>
            <base-input class="col-2">
              <el-select class="select-primary" v-model="Conditions.condition_resilie">
                <el-option value="=" label="EGALE À"></el-option>
              </el-select>
            </base-input>
            <base-input class="col-5">
              <el-select class="select-primary" v-model="Conditions.resilie">
                <el-option value="OUI" label="OUI"></el-option>
                <el-option value="NON" label="NON"></el-option>
              </el-select>
            </base-input>
            <base-button
              @click.native="show.resilie = false"
              class="col edit btn-link"
              type="danger"
              size="sm"
              icon
            >
              <i class="tim-icons icon-simple-remove"></i>
            </base-button>
          </div>

          <div class="row" v-if="show.retrait_suspension">
            <label class="col-3">RETRAIT OU SUSPENSION DE PERMIS SUR LES 2 DERNIÈRES ANNÉES :</label>
            <base-input class="col-2">
              <el-select class="select-primary" v-model="Conditions.condition_retrait_suspension">
                <el-option value="=" label="EGALE À"></el-option>
              </el-select>
            </base-input>
            <base-input class="col-5">
              <el-select class="select-primary" v-model="Conditions.retrait_suspension">
                <el-option value="OUI" label="OUI"></el-option>
                <el-option value="NON" label="NON"></el-option>
              </el-select>
            </base-input>
            <base-button
              @click.native="show.retrait_suspension = false"
              class="col edit btn-link"
              type="danger"
              size="sm"
              icon
            >
              <i class="tim-icons icon-simple-remove"></i>
            </base-button>
          </div>

          <div class="row" v-if="show.sinistre">
            <label class="col-3">NOMBRE DE SINISTRES RESPONSABLES DEPUIS 3 ANS :</label>
            <base-input class="col-2">
              <el-select class="select-primary" v-model="Conditions.condition_sinistre">
                <el-option value="<=" label="JUSQU'À"></el-option>
                <el-option value=">" label="PLUS DE"></el-option>
              </el-select>
            </base-input>
            <base-input class="col-5">
              <el-select class="select-primary" v-model="Conditions.sinistre">
                <el-option value="0" label="0"></el-option>
                <el-option value="1" label="1"></el-option>
                <el-option value="2" label="2"></el-option>
                <el-option value="3" label="3"></el-option>
                <el-option value="4" label="4"></el-option>
                <el-option value="plus" label="PLUS DE 4"></el-option>
              </el-select>
            </base-input>
            <base-button
              @click.native="show.sinistre = false"
              class="col edit btn-link"
              type="danger"
              size="sm"
              icon
            >
              <i class="tim-icons icon-simple-remove"></i>
            </base-button>
          </div>

          <div class="row" v-if="show.date_obtention_permis">
            <label class="col-3">DATE D’OBTENTION DU PERMIS :</label>
            <base-input class="col-2">
              <el-select class="select-primary" v-model="Conditions.condition_date_obtention_permis">
                <el-option value="<" label="INFÉRIEUR À"></el-option>
                <el-option value=">" label="SUPÉRIEUR À"></el-option>
              </el-select>
            </base-input>
            <base-input class="col-5">
              <el-select class="select-primary" v-model="Conditions.date_obtention_permis">
                <el-option value="2" label="2 ANS"></el-option>
              </el-select>
            </base-input>
            <base-button
              @click.native="show.date_obtention_permis = false"
              class="col edit btn-link"
              type="danger"
              size="sm"
              icon
            >
              <i class="tim-icons icon-simple-remove"></i>
            </base-button>
          </div>

          <div class="row" v-if="show.marque">
            <label class="col-3">MARQUE DU VÉHICULE À ASSURER :</label>
            <base-input class="col-2">
              <el-select class="select-primary" v-model="Conditions.condition_marque">
                <el-option value="egale" label="EGALE À"></el-option>
                <el-option value="not_egale" label="EXCLURE"></el-option>
              </el-select>
            </base-input>
            <base-input class="col-5">
              <el-select class="select-primary" collapse-tags filterable multiple v-model="Conditions.marque">
                <el-option 
                  v-for="option in ListMarque"
                  :value="option.libelle" 
                  :label="option.libelle"
                  :key="option.id"
                ></el-option>
              </el-select>
            </base-input>
            <base-button
              @click.native="show.marque = false"
              class="col edit btn-link"
              type="danger"
              size="sm"
              icon
            >
              <i class="tim-icons icon-simple-remove"></i>
            </base-button>
          </div>

          <div class="row" v-if="show.modele">
            <label class="col-3">MODÈLE DU VÉHICULE À ASSURER :</label>
            <base-input class="col-2">
              <el-select class="select-primary" v-model="Conditions.condition_modele">
                <el-option value="egale" label="EGALE À"></el-option>
                <el-option value="not_egale" label="EXCLURE"></el-option>
              </el-select>
            </base-input>
            <base-input class="col-5">
              <el-select class="select-primary" collapse-tags filterable multiple v-model="Conditions.modele">
                <el-option 
                  v-for="option in ListModele"
                  :value="option.libelle" 
                  :label="option.libelle"
                  :key="option.id"
                ></el-option>
              </el-select>
            </base-input>
            <base-button
              @click.native="show.modele = false"
              class="col edit btn-link"
              type="danger"
              size="sm"
              icon
            >
              <i class="tim-icons icon-simple-remove"></i>
            </base-button>
          </div>
          <!-- end Assurance Auto -->

          <!-- start Assurance Habitation -->

          <div class="row" v-if="show.type_logement">
            <label class="col-3">TYPE DE LOGEMENT :</label>
            <base-input class="col-2">
              <el-select class="select-primary" v-model="Conditions.condition_type_logement">
                <el-option value="egale" label="EGALE À"></el-option>
              </el-select>
            </base-input>
            <base-input class="col-4">
              <el-select class="select-primary" v-model="Conditions.type_logement">
                <el-option value="MAISON" label="MAISON"></el-option>
                <el-option value="APPARTEMENT" label="APPARTEMENT"></el-option>
              </el-select>
            </base-input>
            <base-button
              @click.native="show.type_logement = false"
              class="col edit btn-link"
              type="danger"
              size="sm"
              icon
            >
              <i class="tim-icons icon-simple-remove"></i>
            </base-button>
          </div>

          <div class="row" v-if="show.occupant">
            <label class="col-3">TYPE DE SOUSCRIPTEUR :</label>
            <base-input class="col-2">
              <el-select class="select-primary" v-model="Conditions.condition_occupant">
                <el-option value="egale" label="EGALE À"></el-option>
              </el-select>
            </base-input>
            <base-input class="col-4">
              <el-select class="select-primary" v-model="Conditions.occupant">
                <el-option value="LOCATAIRE_OCCUPANT" label="LOCATAIRE OCUPANT"></el-option>
                <el-option value="PROPRIETAIRE_OCCUPANT" label="PROPRIÉTAIRE"></el-option>
                <el-option value="COPROPRIETAIRE_OCCUPANT" label="COPROPRIÉTAIRE"></el-option>
              </el-select>
            </base-input>
            <base-button
              @click.native="show.occupant = false"
              class="col edit btn-link"
              type="danger"
              size="sm"
              icon
            >
              <i class="tim-icons icon-simple-remove"></i>
            </base-button>
          </div>

          <div class="row" v-if="show.nbre_pieces_principales">
            <label class="col-3">NOMBRE DE PIÈCES INFÉRIEUR À 30M² :</label>
            <base-input class="col-2">
              <el-select class="select-primary" v-model="Conditions.condition_nbre_pieces_principales">
                <el-option value="egale" label="EGALE À"></el-option>
              </el-select>
            </base-input>
            <base-input class="col-4">
              <el-select class="select-primary" v-model="Conditions.nbre_pieces_principales">
                <el-option value="1" label="1"></el-option>
                <el-option value="2" label="2"></el-option>
                <el-option value="3" label="3"></el-option>
                <el-option value="4" label="4"></el-option>
                <el-option value="5" label="5"></el-option>
                <el-option value="6" label="6"></el-option>
                <el-option value="7" label="7"></el-option>
                <el-option value="8" label="8"></el-option>
              </el-select>
            </base-input>
            <base-button
              @click.native="show.nbre_pieces_principales = false"
              class="col edit btn-link"
              type="danger"
              size="sm"
              icon
            >
              <i class="tim-icons icon-simple-remove"></i>
            </base-button>
          </div>

          <div class="row" v-if="show.plus_30">
            <label class="col-3">NOMBRE DE PIÈCES SUPÉRIEURES À 30M² :</label>
            <base-input class="col-2">
              <el-select class="select-primary" v-model="Conditions.condition_plus_30">
                <el-option value="egale" label="EGALE À"></el-option>
              </el-select>
            </base-input>
            <base-input class="col-4">
              <el-select class="select-primary" v-model="Conditions.plus_30">
                <el-option value="0" label="0"></el-option>
                <el-option value="1" label="1"></el-option>
                <el-option value="2" label="2"></el-option>
                <el-option value="3" label="3"></el-option>
                <el-option value="4" label="4"></el-option>
                <el-option value="5" label="5"></el-option>
              </el-select>
            </base-input>
            <base-button
              @click.native="show.plus_30 = false"
              class="col edit btn-link"
              type="danger"
              size="sm"
              icon
            >
              <i class="tim-icons icon-simple-remove"></i>
            </base-button>
          </div>

          <div class="row" v-if="show.cp">
            <label class="col-3">CODE POSTAL :</label>
            <base-input class="col-2">
              <el-select class="select-primary" v-model="Conditions.condition_cp">
                <el-option value="egale" label="EGALE À"></el-option>
              </el-select>
            </base-input>
            <base-input class="col-4">
              <el-select class="select-primary" collapse-tags filterable multiple v-model="Conditions.cp">
                <el-option 
                  v-for="option in ListZones.slice(1, 1000)"
                  :value="option.code_postal" 
                  :label="option.code_postal"
                  :key="option.id">
                </el-option>
              </el-select>
            </base-input>
            <base-button
              @click.native="show.cp = false"
              class="col edit btn-link"
              type="danger"
              size="sm"
              icon
            >
              <i class="tim-icons icon-simple-remove"></i>
            </base-button>
          </div>
          <!-- end Assurance Habitation -->


          <!-- start Assurance Animaux -->
          <div class="row" v-if="show.type_animal">
            <label class="col-3">ANIMAL À ASSURER :</label>
            <base-input class="col-2">
              <el-select class="select-primary" v-model="Conditions.condition_type_animal">
                <el-option value="egale" label="EGALE À"></el-option>
              </el-select>
            </base-input>
            <base-input class="col-6">
              <el-select class="select-primary" collapse-tags filterable multiple v-model="Conditions.type_animal">
                <el-option value="CHIEN" label="CHIEN"></el-option>
                <el-option value="CHAT" label="CHAT"></el-option>
                <el-option value="Nouveaux animaux de compagnie" label="NOUVEAUX ANIMAUX DE COMPAGNIE"></el-option>
              </el-select>
            </base-input>
            <base-button
              @click.native="show.type_animal = false"
              class="col edit btn-link"
              type="danger"
              size="sm"
              icon
            >
              <i class="tim-icons icon-simple-remove"></i>
            </base-button>
          </div>

          <div class="row" v-if="show.date_naissance_animal">
            <label class="col-3">AGE DE L'ANIMAL :</label>
            <base-input class="col-2" v-model="Conditions.date_naissance_animal_from" placeholder="ENTRE" type="number"></base-input>
            <base-input class="col-2">
              <el-select class="select-primary" v-model="Conditions.date_naissance_animal_from_opt_from">
                <el-option value="mois" label="MOIS"></el-option>
                <el-option value="ans" label="ANS"></el-option>
              </el-select>
            </base-input>
            <base-input class="col-2" v-model="Conditions.date_naissance_animal_to" placeholder="ET" type="number"></base-input>
            <base-input class="col-2">
              <el-select class="select-primary" v-model="Conditions.date_naissance_animal_from_opt_to">
                <el-option value="mois" label="MOIS"></el-option>
                <el-option value="ans" label="ANS"></el-option>
              </el-select>
            </base-input>
            <base-button
              @click.native="show.date_naissance_animal = false"
              class="col edit btn-link"
              type="danger"
              size="sm"
              icon
            >
              <i class="tim-icons icon-simple-remove"></i>
            </base-button>
          </div>

          <div class="row" v-if="show.race">
            <label class="col-3">RACE DE CHIEN :</label>
            <base-input class="col-2">
              <el-select class="select-primary" v-model="Conditions.condition_race">
                <el-option value="egale" label="EGALE À"></el-option>
              </el-select>
            </base-input>
            <base-input class="col-6">
              <el-select class="select-primary" collapse-tags filterable v-model="Conditions.race">
                <el-option 
                  v-for="option in ListRaces"
                  :value="option.nom" 
                  :label="option.nom"
                  :key="option.id">
                </el-option>
              </el-select>
            </base-input>
            <base-button
              @click.native="show.race = false"
              class="col edit btn-link"
              type="danger"
              size="sm"
              icon
            >
              <i class="tim-icons icon-simple-remove"></i>
            </base-button>
          </div>

          <div class="row" v-if="show.race_roise">
            <label class="col-3">ANIMAL DE RACE CROISÉE :</label>
            <base-input class="col-2">
              <el-select class="select-primary" v-model="Conditions.condition_race_roise">
                <el-option value="egale" label="EGALE À"></el-option>
              </el-select>
            </base-input>
            <base-input class="col-6">
              <el-select class="select-primary" v-model="Conditions.race_roise">
                <el-option value="oui" label="OUI"></el-option>
                <el-option value="non" label="NON"></el-option>
              </el-select>
            </base-input>
            <base-button
              @click.native="show.race_roise = false"
              class="col edit btn-link"
              type="danger"
              size="sm"
              icon
            >
              <i class="tim-icons icon-simple-remove"></i>
            </base-button>
          </div>

          <div class="row" v-if="show.race2">
            <label class="col-3">DEUXIÈME RACE CHIEN :</label>
            <base-input class="col-2">
              <el-select class="select-primary" v-model="Conditions.condition_race2">
                <el-option value="egale" label="EGALE À"></el-option>
              </el-select>
            </base-input>
            <base-input class="col-6">
              <el-select class="select-primary" collapse-tags filterable v-model="Conditions.race2">
                <el-option 
                  v-for="option in ListRaces"
                  :value="option.nom" 
                  :label="option.nom"
                  :key="option.id">
                </el-option>
              </el-select>
            </base-input>
            <base-button
              @click.native="show.race2 = false"
              class="col edit btn-link"
              type="danger"
              size="sm"
              icon
            >
              <i class="tim-icons icon-simple-remove"></i>
            </base-button>
          </div>

          <div class="row" v-if="show.tatoue">
            <label class="col-3">ANIMAL TATOUÉ OU PUCÉ :</label>
            <base-input class="col-2">
              <el-select class="select-primary" v-model="Conditions.condition_tatoue">
                <el-option value="egale" label="EGALE À"></el-option>
              </el-select>
            </base-input>
            <base-input class="col-6">
              <el-select class="select-primary" v-model="Conditions.tatoue">
                <el-option value="oui" label="OUI"></el-option>
                <el-option value="non" label="NON"></el-option>
              </el-select>
            </base-input>
            <base-button
              @click.native="show.tatoue = false"
              class="col edit btn-link"
              type="danger"
              size="sm"
              icon
            >
              <i class="tim-icons icon-simple-remove"></i>
            </base-button>
          </div>
          <!-- end Assurance Animaux -->
          
        </div>
      </div>
      <div class="card" v-for="(Conditions, index) in ConditionsOr" v-if="Rerender">
        <span slot="header" class="badge-pill badge-market tab-title" style="font-weight: bold;">
          OU
          <base-button size="sm" type="danger" style="float: right;" @click="removeORCondition(index)">SUPPRIMER</base-button>
        </span>
        <div class="card-body">
          <div class="row">
            <label class="col-sm-2 mt-2">CHAMPS DU PROSPECT</label>
            <base-input class="col-sm-5" v-if="Filter.Product === 1">
              <el-select class="select-primary" v-model="Conditions.prospect">
                <el-option value="age_prospect" label="AGE DE PROSPECT"></el-option>
                <el-option value="regime" label="RÉGIME DE PROSPECT"></el-option>
                <el-option value="profession" label="PROFESSION DE PROSPECT"></el-option>
              </el-select>
            </base-input>
            <base-input class="col-sm-5" v-if="Filter.Product === 2">
              <el-select class="select-primary" v-model="Conditions.prospect">
                <el-option value="tout_auto" label="TOUS LES CHAMPS FILTRANTS"></el-option>
                <el-option value="date_mise_circulation" label="DATE DE MISE EN CIRCULATION"></el-option>
                <el-option value="nombre_an_assure" label="NOMBRE D’ANNÉES D’ASSURANCE"></el-option>
                <el-option value="bonus_malus_auto" label="BONUS MALUS AUTO"></el-option>
                <el-option value="resilie" label="RÉSILIATION D’UN AUTRE ASSUREUR INFÉRIEUR À 2 ANS"></el-option>
                <el-option value="retrait_suspension" label="RETRAIT OU SUSPENSION DE PERMIS SUR LES 2 DERNIÈRES ANNÉES"></el-option>
                <el-option value="sinistre" label="NOMBRE DE SINISTRES RESPONSABLES DEPUIS 3 ANS"></el-option>
                <el-option value="date_obtention_permis" label="DATE D’OBTENTION DU PERMIS"></el-option>
                <el-option value="marque" label="MARQUE DU VÉHICULE À ASSURER"></el-option>
                <el-option value="modele" label="MODÈLE DU VÉHICULE À ASSURER"></el-option>
              </el-select>
            </base-input>
            <base-input class="col-sm-5" v-if="Filter.Product === 3">
              <el-select class="select-primary" v-model="Conditions.prospect">
                <el-option value="tout_mrh" label="TOUS LES CHAMPS FILTRANTS"></el-option>
                <el-option value="type_logement" label="TYPE DE LOGEMENT"></el-option>
                <el-option value="occupant" label="TYPE DE SOUSCRIPTEUR"></el-option>
                <el-option value="nbre_pieces_principales" label="NOMBRE DE PIÈCES INFÉRIEUR À 30M²"></el-option>
                <el-option value="plus_30" label="NOMBRE DE PIÈCES SUPÉRIEURES À 30M²"></el-option>
                <el-option value="cp" label="CODE POSTAL"></el-option>
              </el-select>
            </base-input>
            <base-input class="col-sm-5" v-if="Filter.Product === 4">
              <el-select class="select-primary" v-model="Conditions.prospect">
                <el-option value="tout_animaux" label="TOUS LES CHAMPS FILTRANTS"></el-option>
                <el-option value="type_animal" label="ANIMAL À ASSURER"></el-option>
                <el-option value="date_naissance_animal" label="DATE DE NAISSANCE DE L’ANIMAL"></el-option>
                <el-option value="race_roise" label="ANIMAL DE RACE CROISÉE"></el-option>
                <el-option value="tatoue" label="ANIMAL TATOUÉ OU PUCÉ"></el-option>
              </el-select>
            </base-input>
            <base-input class="col-sm-5" v-if="Filter.Product === 8">
              <el-select class="select-primary" v-model="Conditions.prospect">
                <el-option value="age_prospect" label="AGE DE PROSPECT"></el-option>
                <el-option value="profession" label="PROFESSION DE PROSPECT"></el-option>
              </el-select>
            </base-input>
          </div>

          <div class="row">
            <label class="col-3"><b>CHAMPS :</b></label>
            <label class="col-9"><b>VALEUR :</b></label>
          </div>

          <!-- start Assurance Sante individuelle -->
          <div class="row" v-if="showOr[index].age_prospect">
            <label class="col-3">AGE DE PROSPECT :</label>
            <base-input type="number" placeholder="ENTRE" class="col-4" v-model="Conditions.age_prospect_from"></base-input>
            <base-input type="number" placeholder="ET" class="col-4" v-model="Conditions.age_prospect_to"></base-input>
            <base-button
              @click.native="showOr[index].age_prospect = false;Rerender = false;Rerender = true;"
              class="col edit btn-link"
              type="danger"
              size="sm"
              icon
            >
              <i class="tim-icons icon-simple-remove"></i>
            </base-button>
          </div>
          <div class="row" v-if="showOr[index].regime_prospect">
            <label class="col-3">RÉGIME DE PROSPECT :</label>
            <base-input class="col-2">
              <el-select class="select-primary" v-model="Conditions.condition_regime">
                <el-option value="egale" label="EGALE À"></el-option>
                <el-option value="not_egale" label="EXCLURE"></el-option>
              </el-select>
            </base-input>
            <base-input class="col-6">
              <el-select class="select-primary" clearable multiple v-model="Conditions.regime">
                <el-option value="Salarié" label="SALARIÉ"></el-option>
                <el-option value="Retraité" label="RETRAITÉ"></el-option>
                <el-option value="Alsace Moselle salarié" label="ALSACE MOSELLE SALARIÉ"></el-option>
                <el-option value="Salarié agricole" label="SALARIÉ AGRICOLE"></el-option>
                <el-option value="TNS Indépendant" label="TNS INDÉPENDANT"></el-option>
              </el-select>
            </base-input>
            <base-button
              @click.native="showOr[index].regime_prospect = false;Rerender = false;Rerender = true;"
              class="col edit btn-link"
              type="danger"
              size="sm"
              icon
            >
              <i class="tim-icons icon-simple-remove"></i>
            </base-button>
          </div>
          <div class="row" v-if="showOr[index].profession_prospect">
            <label class="col-3">PROFESSION DE PROSPECT :</label>
            <base-input class="col-2">
              <el-select class="select-primary" v-model="Conditions.condition_profession">
                <el-option value="egale" label="EGALE À"></el-option>
                <el-option value="not_egale" label="EXCLURE"></el-option>
              </el-select>
            </base-input>
            <base-input class="col-6">
              <el-select class="select-primary" clearable multiple v-model="Conditions.profession">
                <el-option value="Commerçant" label="COMMERÇANT"></el-option>
                <el-option value="Artisan" label="ARTISAN"></el-option>
                <el-option value="Chef d entreprise" label="CHEF D'ENTREPRISE"></el-option>
                <el-option value="Profession libérale sédentaire" label="PROFESSION LIBÉRALE SÉDENTAIRE"></el-option>
                <el-option value="Profession libérale non sédentaire" label="PROFESSION LIBÉRALE NON SÉDENTAIRE"></el-option>
              </el-select>
            </base-input>
            <base-button
              @click.native="showOr[index].profession_prospect = false;Rerender = false;Rerender = true;"
              class="col edit btn-link"
              type="danger"
              size="sm"
              icon
            >
              <i class="tim-icons icon-simple-remove"></i>
            </base-button>
          </div>
          <!-- end Assurance Sante individuelle -->

          <!-- start Assurance Auto -->
          <div class="row" v-if="showOr[index].date_mise_circulation">
            <label class="col-3">DATE DE MISE EN CIRCULATION :</label>
            <base-input class="col-2">
              <el-select class="select-primary" v-model="Conditions.condition_date_mise_circulation">
                <el-option value="<" label="INFÉRIEUR À"></el-option>
                <el-option value=">" label="SUPÉRIEUR À"></el-option>
              </el-select>
            </base-input>
            <base-input class="col-sm-5">
              <el-date-picker 
                v-model="Conditions.date_mise_circulation" 
                value-format="dd-MM-YYY" 
                type="date" 
                placeholder="Date">
              </el-date-picker>
            </base-input>
            <base-button
              @click.native="showOr[index].date_mise_circulation = false;Rerender = false;Rerender = true;"
              class="col edit btn-link"
              type="danger"
              size="sm"
              icon
            >
              <i class="tim-icons icon-simple-remove"></i>
            </base-button>
          </div>
          <div class="row" v-if="showOr[index].nombre_an_assure">
            <label class="col-3">NOMBRE D’ANNÉES D’ASSURANCE :</label>
            <base-input class="col-2">
              <el-select class="select-primary" v-model="Conditions.condition_nombre_an_assure">
                <el-option value="<" label="INFÉRIEUR À"></el-option>
                <el-option value=">" label="SUPÉRIEUR À"></el-option>
              </el-select>
            </base-input>
            <base-input class="col-5">
              <el-select class="select-primary" v-model="Conditions.nombre_an_assure">
                <el-option value="1" label="1 AN"></el-option>
                <el-option value="2" label="2 ANS"></el-option>
              </el-select>
            </base-input>
            <base-button
              @click.native="showOr[index].nombre_an_assure = false;Rerender = false;Rerender = true;"
              class="col edit btn-link"
              type="danger"
              size="sm"
              icon
            >
              <i class="tim-icons icon-simple-remove"></i>
            </base-button>
          </div>

          <div class="row" v-if="showOr[index].bonus_malus_auto">
            <label class="col-3">BONUS MALUS AUTO :</label>
            <base-input class="col-2">
              <el-select class="select-primary" v-model="Conditions.condition_bonus_malus_auto">
                <el-option value="entre" label="ENTRE"></el-option>
              </el-select>
            </base-input>
            <base-input class="col-5">
              <el-select class="select-primary" multiple v-model="Conditions.bonus_malus_auto">
                <el-option value="0.50-0.90" label="0,50 (SOIT 50% DE BONUS) ET 0,90 SOIT (SOIT 10% DE BONUS)"></el-option>
                <el-option value="0.91-1.00" label="0,91 (SOIT 9% DE BONUS) ET 1  SOIT (SOIT NI BONUS NI MALUS)"></el-option>
                <el-option value="1.01-1.50" label="1,01  (SOIT 1% DE MALUS) ET 1,5  SOIT (SOIT 50% DE MALUS)"></el-option>
                <el-option value="1.51-3.50" label="1,51 (SOIT 51% DE MALUS) ET 3,5 SOIT (SOIT 250% DE MALUS)"></el-option>
              </el-select>
            </base-input>
            <base-button
              @click.native="showOr[index].bonus_malus_auto = false;Rerender = false;Rerender = true;"
              class="col edit btn-link"
              type="danger"
              size="sm"
              icon
            >
              <i class="tim-icons icon-simple-remove"></i>
            </base-button>
          </div>

          <div class="row" v-if="showOr[index].resilie">
            <label class="col-3">RÉSILIATION D’UN AUTRE ASSUREUR INFÉRIEUR À 2 ANS :</label>
            <base-input class="col-2">
              <el-select class="select-primary" v-model="Conditions.condition_resilie">
                <el-option value="=" label="EGALE À"></el-option>
              </el-select>
            </base-input>
            <base-input class="col-5">
              <el-select class="select-primary" v-model="Conditions.resilie">
                <el-option value="OUI" label="OUI"></el-option>
                <el-option value="NON" label="NON"></el-option>
              </el-select>
            </base-input>
            <base-button
              @click.native="showOr[index].resilie = false;Rerender = false;Rerender = true;"
              class="col edit btn-link"
              type="danger"
              size="sm"
              icon
            >
              <i class="tim-icons icon-simple-remove"></i>
            </base-button>
          </div>

          <div class="row" v-if="showOr[index].retrait_suspension">
            <label class="col-3">RETRAIT OU SUSPENSION DE PERMIS SUR LES 2 DERNIÈRES ANNÉES :</label>
            <base-input class="col-2">
              <el-select class="select-primary" v-model="Conditions.condition_retrait_suspension">
                <el-option value="=" label="EGALE À"></el-option>
              </el-select>
            </base-input>
            <base-input class="col-5">
              <el-select class="select-primary" v-model="Conditions.retrait_suspension">
                <el-option value="OUI" label="OUI"></el-option>
                <el-option value="NON" label="NON"></el-option>
              </el-select>
            </base-input>
            <base-button
              @click.native="showOr[index].retrait_suspension = false;Rerender = false;Rerender = true;"
              class="col edit btn-link"
              type="danger"
              size="sm"
              icon
            >
              <i class="tim-icons icon-simple-remove"></i>
            </base-button>
          </div>

          <div class="row" v-if="showOr[index].sinistre">
            <label class="col-3">NOMBRE DE SINISTRES RESPONSABLES DEPUIS 3 ANS :</label>
            <base-input class="col-2">
              <el-select class="select-primary" v-model="Conditions.condition_sinistre">
                <el-option value="<=" label="JUSQU'À"></el-option>
                <el-option value=">" label="PLUS DE"></el-option>
              </el-select>
            </base-input>
            <base-input class="col-5">
              <el-select class="select-primary" v-model="Conditions.sinistre">
                <el-option value="0" label="0"></el-option>
                <el-option value="1" label="1"></el-option>
                <el-option value="2" label="2"></el-option>
                <el-option value="3" label="3"></el-option>
                <el-option value="4" label="4"></el-option>
                <el-option value="plus" label="PLUS DE 4"></el-option>
              </el-select>
            </base-input>
            <base-button
              @click.native="showOr[index].sinistre = false;Rerender = false;Rerender = true;"
              class="col edit btn-link"
              type="danger"
              size="sm"
              icon
            >
              <i class="tim-icons icon-simple-remove"></i>
            </base-button>
          </div>

          <div class="row" v-if="showOr[index].date_obtention_permis">
            <label class="col-3">DATE D’OBTENTION DU PERMIS :</label>
            <base-input class="col-2">
              <el-select class="select-primary" v-model="Conditions.condition_date_obtention_permis">
                <el-option value="<" label="INFÉRIEUR À"></el-option>
                <el-option value=">" label="SUPÉRIEUR À"></el-option>
              </el-select>
            </base-input>
            <base-input class="col-5">
              <el-select class="select-primary" v-model="Conditions.date_obtention_permis">
                <el-option value="2" label="2 ANS"></el-option>
              </el-select>
            </base-input>
            <base-button
              @click.native="showOr[index].date_obtention_permis = false;Rerender = false;Rerender = true;"
              class="col edit btn-link"
              type="danger"
              size="sm"
              icon
            >
              <i class="tim-icons icon-simple-remove"></i>
            </base-button>
          </div>

          <div class="row" v-if="showOr[index].marque">
            <label class="col-3">MARQUE DU VÉHICULE À ASSURER :</label>
            <base-input class="col-2">
              <el-select class="select-primary" v-model="Conditions.condition_marque">
                <el-option value="egale" label="EGALE À"></el-option>
                <el-option value="not_egale" label="EXCLURE"></el-option>
              </el-select>
            </base-input>
            <base-input class="col-5">
              <el-select class="select-primary" collapse-tags filterable multiple v-model="Conditions.marque">
                <el-option 
                  v-for="option in ListMarque"
                  :value="option.libelle" 
                  :label="option.libelle"
                  :key="option.id"
                ></el-option>
              </el-select>
            </base-input>
            <base-button
              @click.native="showOr[index].marque = false;Rerender = false;Rerender = true;"
              class="col edit btn-link"
              type="danger"
              size="sm"
              icon
            >
              <i class="tim-icons icon-simple-remove"></i>
            </base-button>
          </div>

          <div class="row" v-if="showOr[index].modele">
            <label class="col-3">MODÈLE DU VÉHICULE À ASSURER :</label>
            <base-input class="col-2">
              <el-select class="select-primary" v-model="Conditions.condition_modele">
                <el-option value="egale" label="EGALE À"></el-option>
                <el-option value="not_egale" label="EXCLURE"></el-option>
              </el-select>
            </base-input>
            <base-input class="col-5">
              <el-select class="select-primary" collapse-tags filterable multiple v-model="Conditions.modele">
                <el-option 
                  v-for="option in ListModele"
                  :value="option.libelle" 
                  :label="option.libelle"
                  :key="option.id"
                ></el-option>
              </el-select>
            </base-input>
            <base-button
              @click.native="showOr[index].modele = false;Rerender = false;Rerender = true;"
              class="col edit btn-link"
              type="danger"
              size="sm"
              icon
            >
              <i class="tim-icons icon-simple-remove"></i>
            </base-button>
          </div>
          <!-- end Assurance Auto -->

          <!-- start Assurance Habitation -->
          <div class="row" v-if="showOr[index].type_logement">
            <label class="col-3">TYPE DE LOGEMENT :</label>
            <base-input class="col-2">
              <el-select class="select-primary" v-model="Conditions.condition_type_logement">
                <el-option value="egale" label="EGALE À"></el-option>
              </el-select>
            </base-input>
            <base-input class="col-4">
              <el-select class="select-primary" v-model="Conditions.type_logement">
                <el-option value="MAISON" label="MAISON"></el-option>
                <el-option value="APPARTEMENT" label="APPARTEMENT"></el-option>
              </el-select>
            </base-input>
            <base-button
              @click.native="showOr[index].type_logement = false;Rerender = false;Rerender = true;"
              class="col edit btn-link"
              type="danger"
              size="sm"
              icon
            >
              <i class="tim-icons icon-simple-remove"></i>
            </base-button>
          </div>

          <div class="row" v-if="showOr[index].occupant">
            <label class="col-3">TYPE DE SOUSCRIPTEUR :</label>
            <base-input class="col-2">
              <el-select class="select-primary" v-model="Conditions.condition_occupant">
                <el-option value="egale" label="EGALE À"></el-option>
              </el-select>
            </base-input>
            <base-input class="col-4">
              <el-select class="select-primary" v-model="Conditions.occupant">
                <el-option value="LOCATAIRE_OCCUPANT" label="LOCATAIRE OCUPANT"></el-option>
                <el-option value="PROPRIETAIRE_OCCUPANT" label="PROPRIÉTAIRE"></el-option>
                <el-option value="COPROPRIETAIRE_OCCUPANT" label="COPROPRIÉTAIRE"></el-option>
              </el-select>
            </base-input>
            <base-button
              @click.native="showOr[index].occupant = false;Rerender = false;Rerender = true;"
              class="col edit btn-link"
              type="danger"
              size="sm"
              icon
            >
              <i class="tim-icons icon-simple-remove"></i>
            </base-button>
          </div>

          <div class="row" v-if="showOr[index].nbre_pieces_principales">
            <label class="col-3">NOMBRE DE PIÈCES INFÉRIEUR À 30M² :</label>
            <base-input class="col-2">
              <el-select class="select-primary" v-model="Conditions.condition_nbre_pieces_principales">
                <el-option value="egale" label="EGALE À"></el-option>
              </el-select>
            </base-input>
            <base-input class="col-4">
              <el-select class="select-primary" v-model="Conditions.nbre_pieces_principales">
                <el-option value="1" label="1"></el-option>
                <el-option value="2" label="2"></el-option>
                <el-option value="3" label="3"></el-option>
                <el-option value="4" label="4"></el-option>
                <el-option value="5" label="5"></el-option>
                <el-option value="6" label="6"></el-option>
                <el-option value="7" label="7"></el-option>
                <el-option value="8" label="8"></el-option>
              </el-select>
            </base-input>
            <base-button
              @click.native="showOr[index].nbre_pieces_principales = false;Rerender = false;Rerender = true;"
              class="col edit btn-link"
              type="danger"
              size="sm"
              icon
            >
              <i class="tim-icons icon-simple-remove"></i>
            </base-button>
          </div>

          <div class="row" v-if="showOr[index].plus_30">
            <label class="col-3">NOMBRE DE PIÈCES SUPÉRIEURES À 30M² :</label>
            <base-input class="col-2">
              <el-select class="select-primary" v-model="Conditions.condition_plus_30">
                <el-option value="egale" label="EGALE À"></el-option>
              </el-select>
            </base-input>
            <base-input class="col-4">
              <el-select class="select-primary" v-model="Conditions.plus_30">
                <el-option value="0" label="0"></el-option>
                <el-option value="1" label="1"></el-option>
                <el-option value="2" label="2"></el-option>
                <el-option value="3" label="3"></el-option>
                <el-option value="4" label="4"></el-option>
                <el-option value="5" label="5"></el-option>
              </el-select>
            </base-input>
            <base-button
              @click.native="showOr[index].plus_30 = false;Rerender = false;Rerender = true;"
              class="col edit btn-link"
              type="danger"
              size="sm"
              icon
            >
              <i class="tim-icons icon-simple-remove"></i>
            </base-button>
          </div>

          <div class="row" v-if="showOr[index].cp">
            <label class="col-3">CODE POSTAL :</label>
            <base-input class="col-2">
              <el-select class="select-primary" v-model="Conditions.condition_cp">
                <el-option value="egale" label="EGALE À"></el-option>
              </el-select>
            </base-input>
            <base-input class="col-4">
              <el-select class="select-primary" collapse-tags filterable multiple v-model="Conditions.cp">
                <el-option 
                  v-for="option in ListZones.slice(1, 1000)"
                  :value="option.code_postal" 
                  :label="option.code_postal"
                  :key="option.id">
                </el-option>
              </el-select>
            </base-input>
            <base-button
              @click.native="showOr[index].cp = false;Rerender = false;Rerender = true;"
              class="col edit btn-link"
              type="danger"
              size="sm"
              icon
            >
              <i class="tim-icons icon-simple-remove"></i>
            </base-button>
          </div>
          <!-- end Assurance Habitation -->

          <!-- start Assurance Animaux -->
          <div class="row" v-if="showOr[index].type_animal">
            <label class="col-3">ANIMAL À ASSURER :</label>
            <base-input class="col-2">
              <el-select class="select-primary" v-model="Conditions.condition_type_animal">
                <el-option value="egale" label="EGALE À"></el-option>
              </el-select>
            </base-input>
            <base-input class="col-6">
              <el-select class="select-primary" collapse-tags filterable multiple v-model="Conditions.type_animal">
                <el-option value="CHIEN" label="CHIEN"></el-option>
                <el-option value="CHAT" label="CHAT"></el-option>
                <el-option value="Nouveaux animaux de compagnie" label="NOUVEAUX ANIMAUX DE COMPAGNIE"></el-option>
              </el-select>
            </base-input>
            <base-button
              @click.native="showOr[index].type_animal = false;Rerender = false;Rerender = true;"
              class="col edit btn-link"
              type="danger"
              size="sm"
              icon
            >
              <i class="tim-icons icon-simple-remove"></i>
            </base-button>
          </div>

          <div class="row" v-if="showOr[index].date_naissance_animal">
            <label class="col-3">AGE DE L'ANIMAL :</label>
            <base-input class="col-2" v-model="Conditions.date_naissance_animal_from" placeholder="ENTRE" type="number"></base-input>
            <base-input class="col-2">
              <el-select class="select-primary" v-model="Conditions.date_naissance_animal_from_opt_from">
                <el-option value="mois" label="MOIS"></el-option>
                <el-option value="ans" label="ANS"></el-option>
              </el-select>
            </base-input>
            <base-input class="col-2" v-model="Conditions.date_naissance_animal_to" placeholder="ET" type="number"></base-input>
            <base-input class="col-2">
              <el-select class="select-primary" v-model="Conditions.date_naissance_animal_from_opt_to">
                <el-option value="mois" label="MOIS"></el-option>
                <el-option value="ans" label="ANS"></el-option>
              </el-select>
            </base-input>
            <base-button
              @click.native="showOr[index].date_naissance_animal = false;Rerender = false;Rerender = true;"
              class="col edit btn-link"
              type="danger"
              size="sm"
              icon
            >
              <i class="tim-icons icon-simple-remove"></i>
            </base-button>
          </div>

          <div class="row" v-if="showOr[index].race">
            <label class="col-3">RACE DE CHIEN :</label>
            <base-input class="col-2">
              <el-select class="select-primary" v-model="Conditions.condition_race">
                <el-option value="egale" label="EGALE À"></el-option>
              </el-select>
            </base-input>
            <base-input class="col-6">
              <el-select class="select-primary" collapse-tags filterable v-model="Conditions.race">
                <el-option 
                  v-for="option in ListRaces"
                  :value="option.nom" 
                  :label="option.nom"
                  :key="option.id">
                </el-option>
              </el-select>
            </base-input>
            <base-button
              @click.native="showOr[index].race = false;Rerender = false;Rerender = true;"
              class="col edit btn-link"
              type="danger"
              size="sm"
              icon
            >
              <i class="tim-icons icon-simple-remove"></i>
            </base-button>
          </div>

          <div class="row" v-if="showOr[index].race_roise">
            <label class="col-3">ANIMAL DE RACE CROISÉE :</label>
            <base-input class="col-2">
              <el-select class="select-primary" v-model="Conditions.condition_race_roise">
                <el-option value="egale" label="EGALE À"></el-option>
              </el-select>
            </base-input>
            <base-input class="col-6">
              <el-select class="select-primary" v-model="Conditions.race_roise">
                <el-option value="oui" label="OUI"></el-option>
                <el-option value="non" label="NON"></el-option>
              </el-select>
            </base-input>
            <base-button
              @click.native="showOr[index].race_roise = false;Rerender = false;Rerender = true;"
              class="col edit btn-link"
              type="danger"
              size="sm"
              icon
            >
              <i class="tim-icons icon-simple-remove"></i>
            </base-button>
          </div>

          <div class="row" v-if="showOr[index].race2">
            <label class="col-3">DEUXIÈME RACE CHIEN :</label>
            <base-input class="col-2">
              <el-select class="select-primary" v-model="Conditions.condition_race2">
                <el-option value="egale" label="EGALE À"></el-option>
              </el-select>
            </base-input>
            <base-input class="col-6">
              <el-select class="select-primary" collapse-tags filterable v-model="Conditions.race2">
                <el-option 
                  v-for="option in ListRaces"
                  :value="option.nom" 
                  :label="option.nom"
                  :key="option.id">
                </el-option>
              </el-select>
            </base-input>
            <base-button
              @click.native="showOr[index].race2 = false;Rerender = false;Rerender = true;"
              class="col edit btn-link"
              type="danger"
              size="sm"
              icon
            >
              <i class="tim-icons icon-simple-remove"></i>
            </base-button>
          </div>

          <div class="row" v-if="showOr[index].tatoue">
            <label class="col-3">ANIMAL TATOUÉ OU PUCÉ :</label>
            <base-input class="col-2">
              <el-select class="select-primary" v-model="Conditions.condition_tatoue">
                <el-option value="egale" label="EGALE À"></el-option>
              </el-select>
            </base-input>
            <base-input class="col-6">
              <el-select class="select-primary" v-model="Conditions.tatoue">
                <el-option value="oui" label="OUI"></el-option>
                <el-option value="non" label="NON"></el-option>
              </el-select>
            </base-input>
            <base-button
              @click.native="showOr[index].tatoue = false;Rerender = false;Rerender = true;"
              class="col edit btn-link"
              type="danger"
              size="sm"
              icon
            >
              <i class="tim-icons icon-simple-remove"></i>
            </base-button>
          </div>
          <!-- end Assurance Animaux -->
        </div>
      </div>
      <base-button
        @click="addORCondition()"
        class="btn btn-round btn-just-icon btn-market"
      ><i class="fas fa-plus"></i> AJOUTER CONDITION OU</base-button>
      <div class="col-md-12">
        <div class="btn-group btn-group-toggle float-right mb-3" data-toggle="buttons">
          <base-button
            @click="addFilters()"
            class="btn btn-round btn-just-icon btn-market"
          ><i class="far fa-save"></i> SAUVEGARDER</base-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { BaseAlert } from 'src/components'
  import { Select, Option, DatePicker } from 'element-ui'

  export default {
    watch: {
      'Filter.Product' (value) {
        this.Conditions.prospect = ''
        Object.keys(this.show).forEach( key => { 
          this.show[key] = false 
        });
        this.Conditions.type_animal = ''
        this.Conditions.race_roise = ''
        this.ConditionsOr = []
        this.showOr = []
      },
      'Conditions.prospect' (value) {
        if (value === 'age_prospect') {
          this.show.age_prospect = true
        }
        else if (value === 'regime') {
          this.show.regime_prospect = true
        }
        else if (value === 'profession') {
          this.show.profession_prospect = true
        }
        else if (value === 'date_mise_circulation') {
          this.show.date_mise_circulation = true
        }
        else if (value === 'nombre_an_assure') {
          this.show.nombre_an_assure = true
        }
        else if (value === 'bonus_malus_auto') {
          this.show.bonus_malus_auto = true
        }
        else if (value === 'resilie') {
          this.show.resilie = true
        }
        else if (value === 'retrait_suspension') {
          this.show.retrait_suspension = true
        }
        else if (value === 'sinistre') {
          this.show.sinistre = true
        }
        else if (value === 'date_obtention_permis') {
          this.show.date_obtention_permis = true
        }
        else if (value === 'marque') {
          this.show.marque = true
        }
        else if (value === 'modele') {
          this.show.modele = true
        }
        else if (value === 'tout_auto') {
          this.show.date_mise_circulation = true
          this.show.nombre_an_assure = true
          this.show.bonus_malus_auto = true
          this.show.resilie = true
          this.show.retrait_suspension = true
          this.show.sinistre = true
          this.show.date_obtention_permis = true
          this.show.marque = true
          this.show.modele = true
        }
        else if (value === 'type_logement') {
          this.show.type_logement = true
        }
        else if (value === 'occupant') {
          this.show.occupant = true
        }
        else if (value === 'nbre_pieces_principales') {
          this.show.nbre_pieces_principales = true
        }
        else if (value === 'plus_30') {
          this.show.plus_30 = true
        }
        else if (value === 'cp') {
          this.show.cp = true
        }
        else if (value === 'tout_mrh') {
          this.show.type_logement = true
          this.show.occupant = true
          this.show.nbre_pieces_principales = true
          this.show.plus_30 = true
          this.show.cp = true
        }
        else if (value === 'type_animal') {
          this.show.type_animal = true
        }
        else if (value === 'date_naissance_animal') {
          this.show.date_naissance_animal = true
        }
        else if (value === 'race_roise') {
          this.show.race_roise = true
        }
        else if (value === 'tatoue') {
          this.show.tatoue = true
        }
        else if (value === 'tout_animaux') {
          this.show.type_animal = true
          this.show.date_naissance_animal = true
          this.show.race_roise = true
          this.show.tatoue = true
        }
      },
      "Conditions.type_animal"(value) {
        if(value.includes('CHIEN')) {
          this.show.race = true
        }
        else {
          this.show.race = false
        }
      },
      "Conditions.race_roise"(value) {
        if(value == 'oui') {
          this.show.race2 = true
        }
        else {
          this.show.race2 = false
        }
      },
      ConditionsOr: {
        deep: true,
        handler(array) {
          array.forEach((element, index) => {
            let value = element.prospect
            if (value === 'age_prospect') {
              this.showOr[index].age_prospect = true
            }
            else if (value === 'regime') {
              this.showOr[index].regime_prospect = true
            }
            else if (value === 'profession') {
              this.showOr[index].profession_prospect = true
            }
            else if (value === 'date_mise_circulation') {
              this.showOr[index].date_mise_circulation = true
            }
            else if (value === 'nombre_an_assure') {
              this.showOr[index].nombre_an_assure = true
            }
            else if (value === 'bonus_malus_auto') {
              this.showOr[index].bonus_malus_auto = true
            }
            else if (value === 'resilie') {
              this.showOr[index].resilie = true
            }
            else if (value === 'retrait_suspension') {
              this.showOr[index].retrait_suspension = true
            }
            else if (value === 'sinistre') {
              this.showOr[index].sinistre = true
            }
            else if (value === 'date_obtention_permis') {
              this.showOr[index].date_obtention_permis = true
            }
            else if (value === 'marque') {
              this.showOr[index].marque = true
            }
            else if (value === 'modele') {
              this.showOr[index].modele = true
            }
            else if (value === 'tout_auto') {
              this.showOr[index].date_mise_circulation = true
              this.showOr[index].nombre_an_assure = true
              this.showOr[index].bonus_malus_auto = true
              this.showOr[index].resilie = true
              this.showOr[index].retrait_suspension = true
              this.showOr[index].sinistre = true
              this.showOr[index].date_obtention_permis = true
              this.showOr[index].marque = true
              this.showOr[index].modele = true
            }
            else if (value === 'type_logement') {
              this.showOr[index].type_logement = true
            }
            else if (value === 'occupant') {
              this.showOr[index].occupant = true
            }
            else if (value === 'nbre_pieces_principales') {
              this.showOr[index].nbre_pieces_principales = true
            }
            else if (value === 'plus_30') {
              this.showOr[index].plus_30 = true
            }
            else if (value === 'cp') {
              this.showOr[index].cp = true
            }
            else if (value === 'tout_mrh') {
              this.showOr[index].type_logement = true
              this.showOr[index].occupant = true
              this.showOr[index].nbre_pieces_principales = true
              this.showOr[index].plus_30 = true
              this.showOr[index].cp = true
            }
            else if (value === 'type_animal') {
              this.showOr[index].type_animal = true
              if(element.type_animal && element.type_animal.includes('CHIEN')) {
                this.showOr[index].race = true
              }
              else {
                this.showOr[index].race = false
              }
            }
            else if (value === 'date_naissance_animal') {
              this.showOr[index].date_naissance_animal = true
            }
            else if (value === 'race_roise') {
              this.showOr[index].race_roise = true
              if(element.race_roise == 'oui') {
                this.showOr[index].race2 = true
              }
              else {
                this.showOr[index].race2 = false
              }
            }
            else if (value === 'tatoue') {
              this.showOr[index].tatoue = true
            }
            else if (value === 'tout_animaux') {
              this.showOr[index].type_animal = true
              this.showOr[index].date_naissance_animal = true
              this.showOr[index].race_roise = true
              this.showOr[index].tatoue = true
            }
          });
        }
      }
    },
    apollo: {
      PrepareAddFilters: {
        client: 'MARKET',
        query: require("src/graphql/leadmarket/Annonceur/PrepareAddFilters.gql"),
        variables () {
          return {
            id_utilisateur: JSON.parse(localStorage.getItem('utilisateur')).id
          }
        },
        result({ data, loader, networkStatus }) {
          this.ListProduct = data.addFilter.categories
          this.ListMarque = data.addFilter.marques
          this.ListModele = data.addFilter.modeles
          this.ListZones = data.addFilter.zones
          this.ListRaces = data.addFilter.races
        },
        fetchPolicy: 'network-only',
        update: data => data.addFilter
      }
    },
    components: {
      BaseAlert,
      [Select.name]: Select,
      [Option.name]: Option,
      [DatePicker.name]: DatePicker,
    },
    data() {
      return {
        ListProduct: [],
        ListMarque: [],
        ListModele: [],
        ListZones: [],
        ListRaces: [],
        Filter: {
          cname: '',
          Product: null
        },
        Conditions: {
          prospect: '',
          age_prospect_from: null,
          age_prospect_to: null,
          condition_regime: '',
          regime: '',
          condition_profession: '',
          profession: '',
          condition_date_mise_circulation: '<',
          date_mise_circulation: '',
          condition_nombre_an_assure: '<',
          nombre_an_assure: '',
          condition_bonus_malus_auto: 'entre',
          bonus_malus_auto: '',
          condition_resilie: '=',
          resilie: '',
          condition_retrait_suspension: '=',
          retrait_suspension: '',
          condition_sinistre: '<=',
          sinistre: '',
          condition_date_obtention_permis: '<',
          date_obtention_permis: '',
          condition_marque: 'egale',
          marque: '',
          condition_modele: 'egale',
          modele: '',
          condition_type_logement: 'egale',
          type_logement: '',
          condition_occupant : 'egale',
          occupant: '',
          condition_nbre_pieces_principales: 'egale',
          nbre_pieces_principales: '',
          condition_plus_30: 'egale',
          plus_30: '',
          condition_cp: 'egale',
          cp: '',
          condition_type_animal: 'egale',
          type_animal: '',
          date_naissance_animal_from_opt_from: '',
          date_naissance_animal_from: '',
          date_naissance_animal_from_opt_to: '',
          date_naissance_animal_to: '',
          condition_race: 'egale',
          race: '',
          condition_race_roise: 'egale',
          race_roise: '',
          condition_race2: 'egale',
          race2: '',
          condition_tatoue: 'egale',
          tatoue: ''
        },
        show: {
          age_prospect: false,
          regime_prospect: false,
          profession_prospect: false,
          date_mise_circulation: false,
          nombre_an_assure: false,
          bonus_malus_auto: false,
          resilie: false,
          retrait_suspension: false,
          sinistre: false,
          date_obtention_permis: false,
          marque: false,
          modele: false,
          type_logement: false,
          occupant: false,
          nbre_pieces_principales: false,
          plus_30: false,
          cp: false,
          type_animal: false,
          date_naissance_animal : false,
          race_roise: false,
          tatoue: false,
          race: false,
          race2: false
        },
        ConditionsOr: [],
        showOr: [],
        Rerender: true
      }
    },
    methods: {
      addORCondition() {
        this.ConditionsOr.push(
          {
            Conditions: {
              prospect: '',
              age_prospect_from: null,
              age_prospect_to: null,
              condition_regime: '',
              regime: '',
              condition_profession: '',
              profession: '',
              condition_date_mise_circulation: '<',
              date_mise_circulation: '',
              condition_nombre_an_assure: '<',
              nombre_an_assure: '',
              condition_bonus_malus_auto: 'entre',
              bonus_malus_auto: '',
              condition_resilie: '=',
              resilie: '',
              condition_retrait_suspension: '=',
              retrait_suspension: '',
              condition_sinistre: '<=',
              sinistre: '',
              condition_date_obtention_permis: '<',
              date_obtention_permis: '',
              condition_marque: 'egale',
              marque: '',
              condition_modele: 'egale',
              modele: '',
              condition_type_logement: 'egale',
              type_logement: '',
              condition_occupant : 'egale',
              occupant: '',
              condition_nbre_pieces_principales: 'egale',
              nbre_pieces_principales: '',
              condition_plus_30: 'egale',
              plus_30: '',
              condition_cp: 'egale',
              cp: '',
              condition_type_animal: 'egale',
              type_animal: '',
              date_naissance_animal_from_opt_from: '',
              date_naissance_animal_from: '',
              date_naissance_animal_from_opt_to: '',
              date_naissance_animal_to: '',
              condition_race: 'egale',
              race: '',
              condition_race_roise: 'egale',
              race_roise: '',
              condition_race2: 'egale',
              race2: '',
              condition_tatoue: 'egale',
              tatoue: ''
            },
          }
        )
        this.showOr.push(
          {
            show: {
              age_prospect: false,
              regime_prospect: false,
              profession_prospect: false,
              date_mise_circulation: false,
              nombre_an_assure: false,
              bonus_malus_auto: false,
              resilie: false,
              retrait_suspension: false,
              sinistre: false,
              date_obtention_permis: false,
              marque: false,
              modele: false,
              type_logement: false,
              occupant: false,
              nbre_pieces_principales: false,
              plus_30: false,
              cp: false,
              type_animal: false,
              date_naissance_animal : false,
              race_roise: false,
              tatoue: false,
              race: false,
              race2: false
            }
          }
        )
      },
      getError(fieldName) {
        return this.errors.first(fieldName)
      },
      async addFilters() {
        let validate
        await this.$validator.validateAll().then(isValid => {
          validate = isValid
        })
        if (!validate)
          return false
        let options = ''
        if (this.Filter.Product === 9 || this.Filter.Product === 10)
          options = ''
        
        // start Assurance Sante individuelle
        if (this.Filter.Product === 1) {
          // AGE DE PROSPECT 
          if (this.Conditions.age_prospect_from && this.Conditions.age_prospect_to && this.show.age_prospect) {
            options += "age_prospect|entre|" + this.Conditions.age_prospect_from +'-'+ this.Conditions.age_prospect_to +';'
          }
          // RÉGIME DE PROSPECT
          if (this.Conditions.condition_regime && this.Conditions.regime.length && this.show.regime_prospect) {
            options += "regime|" + this.Conditions.condition_regime + '|' + this.Conditions.regime.join(',') +';'
          }
          // PROFESSION DE PROSPECT
          if (this.Conditions.condition_profession && this.Conditions.profession.length && this.show.profession_prospect) {
            options += "profession|" + this.Conditions.condition_profession + '|' + this.Conditions.profession.join(',') +';'
          }

          // OR
          this.ConditionsOr.forEach((Conditions, index) => {
            options += "OR;"
            // AGE DE PROSPECT 
            if (Conditions.age_prospect_from && Conditions.age_prospect_to && this.showOr[index].age_prospect) {
              options += "age_prospect|entre|" + Conditions.age_prospect_from +'-'+ Conditions.age_prospect_to +';'
            }
            // RÉGIME DE PROSPECT
            if (Conditions.condition_regime && Conditions.regime.length && this.showOr[index].regime_prospect) {
              options += "regime|" + Conditions.condition_regime + '|' + Conditions.regime.join(',') +';'
            }
            // PROFESSION DE PROSPECT
            if (Conditions.condition_profession && Conditions.profession.length && this.showOr[index].profession_prospect) {
              options += "profession|" + Conditions.condition_profession + '|' + Conditions.profession.join(',') +';'
            }
          });
        }

        // start Assurance Auto
        if (this.Filter.Product === 2) {
          // DATE DE MISE EN CIRCULATION
          if (this.Conditions.condition_date_mise_circulation && this.Conditions.date_mise_circulation && this.show.date_mise_circulation) {
            options += "date_mise_circulation|" + this.Conditions.condition_date_mise_circulation + '|' + this.Conditions.date_mise_circulation +';'
          }
          // NOMBRE D’ANNÉES D’ASSURANCE
          if (this.Conditions.condition_nombre_an_assure && this.Conditions.nombre_an_assure && this.show.nombre_an_assure) {
            options += "nombre_an_assure|" + this.Conditions.condition_nombre_an_assure + '|' + this.Conditions.nombre_an_assure +';'
          }
          // BONUS MALUS AUTO
          if (this.Conditions.condition_bonus_malus_auto && this.Conditions.bonus_malus_auto.length && this.show.bonus_malus_auto) {
            options += "bonus_malus_auto|" + this.Conditions.condition_bonus_malus_auto + '|' + this.Conditions.bonus_malus_auto.join(',') +';'
          }
          // RÉSILIATION D’UN AUTRE ASSUREUR INFÉRIEUR À 2 ANS
          if (this.Conditions.condition_resilie && this.Conditions.resilie && this.show.resilie) {
            options += "resilie|" + this.Conditions.condition_resilie + '|' + this.Conditions.resilie +';'
          }
          // RETRAIT OU SUSPENSION DE PERMIS SUR LES 2 DERNIÈRES ANNÉES
          if (this.Conditions.condition_retrait_suspension && this.Conditions.retrait_suspension && this.show.retrait_suspension) {
            options += "retrait_suspension|" + this.Conditions.condition_retrait_suspension + '|' + this.Conditions.retrait_suspension +';'
          }
          // NOMBRE DE SINISTRES RESPONSABLES DEPUIS 3 ANS
          if (this.Conditions.condition_sinistre && this.Conditions.sinistre && this.show.sinistre) {
            options += "sinistre|" + this.Conditions.condition_sinistre + '|' + this.Conditions.sinistre +';'
          }
          // DATE D’OBTENTION DU PERMIS
          if (this.Conditions.condition_date_obtention_permis && this.Conditions.date_obtention_permis && this.show.date_obtention_permis) {
            options += "date_obtention_permis|" + this.Conditions.condition_date_obtention_permis + '|' + this.Conditions.date_obtention_permis +';'
          }
          // MARQUE DU VÉHICULE À ASSURER
          if (this.Conditions.condition_marque && this.Conditions.marque.length && this.show.marque) {
            options += "marque|" + this.Conditions.condition_marque + '|' + this.Conditions.marque.join(',') +';'
          }
          // MODÈLE DU VÉHICULE À ASSURER
          if (this.Conditions.condition_modele && this.Conditions.modele.length && this.show.modele) {
            options += "modele|" + this.Conditions.condition_modele + '|' +this.Conditions.modele.join(',') +';'
          }

          // OR 
          this.ConditionsOr.forEach((Conditions, index) => {
            options += "OR;"
            // DATE DE MISE EN CIRCULATION
            if (Conditions.condition_date_mise_circulation && Conditions.date_mise_circulation && this.showOr[index].date_mise_circulation) {
              options += "date_mise_circulation|" + Conditions.condition_date_mise_circulation + '|' + Conditions.date_mise_circulation +';'
            }
            // NOMBRE D’ANNÉES D’ASSURANCE
            if (Conditions.condition_nombre_an_assure && Conditions.nombre_an_assure && this.showOr[index].nombre_an_assure) {
              options += "nombre_an_assure|" + Conditions.condition_nombre_an_assure + '|' + Conditions.nombre_an_assure +';'
            }
            // BONUS MALUS AUTO
            if (Conditions.condition_bonus_malus_auto && Conditions.bonus_malus_auto.length && this.showOr[index].bonus_malus_auto) {
              options += "bonus_malus_auto|" + Conditions.condition_bonus_malus_auto + '|' + Conditions.bonus_malus_auto.join(',') +';'
            }
            // RÉSILIATION D’UN AUTRE ASSUREUR INFÉRIEUR À 2 ANS
            if (Conditions.condition_resilie && Conditions.resilie && this.showOr[index].resilie) {
              options += "resilie|" + Conditions.condition_resilie + '|' + Conditions.resilie +';'
            }
            // RETRAIT OU SUSPENSION DE PERMIS SUR LES 2 DERNIÈRES ANNÉES
            if (Conditions.condition_retrait_suspension && Conditions.retrait_suspension && this.showOr[index].retrait_suspension) {
              options += "retrait_suspension|" + Conditions.condition_retrait_suspension + '|' + Conditions.retrait_suspension +';'
            }
            // NOMBRE DE SINISTRES RESPONSABLES DEPUIS 3 ANS
            if (Conditions.condition_sinistre && Conditions.sinistre && this.showOr[index].sinistre) {
              options += "sinistre|" + Conditions.condition_sinistre + '|' + Conditions.sinistre +';'
            }
            // DATE D’OBTENTION DU PERMIS
            if (Conditions.condition_date_obtention_permis && Conditions.date_obtention_permis && this.showOr[index].date_obtention_permis) {
              options += "date_obtention_permis|" + Conditions.condition_date_obtention_permis + '|' + Conditions.date_obtention_permis +';'
            }
            // MARQUE DU VÉHICULE À ASSURER
            if (Conditions.condition_marque && Conditions.marque.length && this.showOr[index].marque) {
              options += "marque|" + Conditions.condition_marque + '|' + Conditions.marque.join(',') +';'
            }
            // MODÈLE DU VÉHICULE À ASSURER
            if (Conditions.condition_modele && Conditions.modele.length && this.showOr[index].modele) {
              options += "modele|" + Conditions.condition_modele + '|' +Conditions.modele.join(',') +';'
            }
          });
        }

        // start Assurance Habitation
        if (this.Filter.Product === 3) {
          // TYPE DE LOGEMENT
          if (this.Conditions.condition_type_logement && this.Conditions.type_logement && this.show.type_logement) {
            options += "type_logement|" + this.Conditions.condition_type_logement + '|' + this.Conditions.type_logement +';'
          }
          // TYPE DE SOUSCRIPTEUR
          if (this.Conditions.condition_occupant && this.Conditions.occupant && this.show.occupant) {
            options += "occupant|" + this.Conditions.condition_occupant + '|' + this.Conditions.occupant +';'
          }
          // NOMBRE DE PIÈCES INFÉRIEUR À 30M²
          if (this.Conditions.condition_nbre_pieces_principales && this.Conditions.nbre_pieces_principales && this.show.nbre_pieces_principales) {
            options += "nbre_pieces_principales|" + this.Conditions.condition_nbre_pieces_principales + '|' + this.Conditions.nbre_pieces_principales +';'
          }
          // NOMBRE DE PIÈCES SUPÉRIEURES À 30M²
          if (this.Conditions.condition_plus_30 && this.Conditions.plus_30 && this.show.plus_30) {
            options += "plus_30|" + this.Conditions.condition_plus_30 + '|' + this.Conditions.plus_30 +';'
          }
          // CODE POSTAL
          if (this.Conditions.condition_cp && this.Conditions.cp.length && this.show.cp) {
            options += "cp|" + this.Conditions.condition_cp + '|' + this.Conditions.cp.join(',') +';'
          }
          // OR
          this.ConditionsOr.forEach((Conditions, index) => {
            options += "OR;"
            // TYPE DE LOGEMENT
            if (Conditions.condition_type_logement && Conditions.type_logement && this.showOr[index].type_logement) {
              options += "type_logement|" + Conditions.condition_type_logement + '|' + Conditions.type_logement +';'
            }
            // TYPE DE SOUSCRIPTEUR
            if (Conditions.condition_occupant && Conditions.occupant && this.showOr[index].occupant) {
              options += "occupant|" + Conditions.condition_occupant + '|' + Conditions.occupant +';'
            }
            // NOMBRE DE PIÈCES INFÉRIEUR À 30M²
            if (Conditions.condition_nbre_pieces_principales && Conditions.nbre_pieces_principales && this.showOr[index].nbre_pieces_principales) {
              options += "nbre_pieces_principales|" + Conditions.condition_nbre_pieces_principales + '|' + Conditions.nbre_pieces_principales +';'
            }
            // NOMBRE DE PIÈCES SUPÉRIEURES À 30M²
            if (Conditions.condition_plus_30 && Conditions.plus_30 && this.showOr[index].plus_30) {
              options += "plus_30|" + Conditions.condition_plus_30 + '|' + Conditions.plus_30 +';'
            }
            // CODE POSTAL
            if (Conditions.condition_cp && Conditions.cp.length && this.showOr[index].cp) {
              options += "cp|" + Conditions.condition_cp + '|' + Conditions.cp.join(',') +';'
            }
          });
        }

        // start Assurance Animaux
        if (this.Filter.Product === 4) {
          // ANIMAL À ASSURER
          if (this.Conditions.condition_type_animal && this.Conditions.type_animal.length && this.show.type_animal) {
            options += "type_animal|" + this.Conditions.condition_type_animal + '|' + this.Conditions.type_animal.join(',') +';'
            // RACE DE CHIEN
            if (this.show.race) {
              if (this.Conditions.condition_race && this.Conditions.race) {
                options += "race|" + this.Conditions.condition_race + '|' + this.Conditions.race +';'
              }
            }
          }
          // DATE DE NAISSANCE DE L’ANIMAL
          if (this.Conditions.date_naissance_animal_from && this.Conditions.date_naissance_animal_from_opt_from && this.Conditions.date_naissance_animal_to && this.Conditions.date_naissance_animal_from_opt_to && this.show.date_naissance_animal) {
            options += "date_naissance_animal|entre|" + this.Conditions.date_naissance_animal_from + '-' + this.Conditions.date_naissance_animal_from_opt_from + '-' + this.Conditions.date_naissance_animal_to + '-' + this.Conditions.date_naissance_animal_from_opt_to +';'
          }
          // ANIMAL DE RACE CROISÉE
          if (this.Conditions.condition_race_roise && this.Conditions.race_roise && this.show.race_roise) {
            options += "race_roise|" + this.Conditions.condition_race_roise + '|' + this.Conditions.race_roise +';'
            // DEUXIÈME RACE CHIEN
            if (this.show.race2) {
              options += "race2|" + this.Conditions.condition_race2 + '|' + this.Conditions.race2 +';'
            }
          }
          // ANIMAL TATOUÉ OU PUCÉ
          if (this.Conditions.condition_tatoue && this.Conditions.tatoue && this.show.tatoue) {
            options += "tatoue|" + this.Conditions.condition_tatoue + '|' + this.Conditions.tatoue +';'
          }

          // OR
          this.ConditionsOr.forEach((Conditions, index) => {
            options += "OR;"
            // ANIMAL À ASSURER
            if (Conditions.condition_type_animal && Conditions.type_animal.length && this.showOr[index].type_animal) {
              options += "type_animal|" + Conditions.condition_type_animal + '|' + Conditions.type_animal.join(',') +';'
              // RACE DE CHIEN
              if (this.showOr[index].race) {
                if (Conditions.condition_race && Conditions.race) {
                  options += "race|" + Conditions.condition_race + '|' + Conditions.race +';'
                }
              }
            }
            // DATE DE NAISSANCE DE L’ANIMAL
            if (Conditions.date_naissance_animal_from && Conditions.date_naissance_animal_from_opt_from && Conditions.date_naissance_animal_to && Conditions.date_naissance_animal_from_opt_to && this.showOr[index].date_naissance_animal) {
              options += "date_naissance_animal|entre|" + Conditions.date_naissance_animal_from + '-' + Conditions.date_naissance_animal_from_opt_from + '-' + Conditions.date_naissance_animal_to + '-' + Conditions.date_naissance_animal_from_opt_to +';'
            }
            // ANIMAL DE RACE CROISÉE
            if (Conditions.condition_race_roise && Conditions.race_roise && this.showOr[index].race_roise) {
              options += "race_roise|" + Conditions.condition_race_roise + '|' + Conditions.race_roise +';'
              // DEUXIÈME RACE CHIEN
              if (this.showOr[index].race2) {
                options += "race2|" + Conditions.condition_race2 + '|' + Conditions.race2 +';'
              }
            }
            // ANIMAL TATOUÉ OU PUCÉ
            if (Conditions.condition_tatoue && Conditions.tatoue && this.showOr[index].tatoue) {
              options += "tatoue|" + Conditions.condition_tatoue + '|' + Conditions.tatoue +';'
            }
          });
        }

        // start TNS santé
        if (this.Filter.Product === 8) {
          // AGE DE PROSPECT 
          if (this.Conditions.age_prospect_from && this.Conditions.age_prospect_to && this.show.age_prospect) {
            options += "age_prospect|entre|" + this.Conditions.age_prospect_from +'-'+ this.Conditions.age_prospect_to +';'
          }
          // PROFESSION DE PROSPECT
          if (this.Conditions.condition_profession && this.Conditions.profession.length && this.show.profession_prospect) {
            options += "profession|" + this.Conditions.condition_profession + '|' + this.Conditions.profession.join(',') +';'
          }
          // OR
          this.ConditionsOr.forEach((Conditions, index) => {
            options += "OR;"
            // AGE DE PROSPECT 
            if (Conditions.age_prospect_from && Conditions.age_prospect_to && this.showOr[index].age_prospect) {
              options += "age_prospect|entre|" + Conditions.age_prospect_from +'-'+ Conditions.age_prospect_to +';'
            }
            // PROFESSION DE PROSPECT
            if (Conditions.condition_profession && Conditions.profession.length && this.showOr[index].profession_prospect) {
              options += "profession|" + Conditions.condition_profession + '|' + Conditions.profession.join(',') +';'
            }
          });
        }

        await this.$apollo.mutate({
          client: 'MARKET',
          mutation: require("src/graphql/leadmarket/Annonceur/mutation/addFilter.gql"),
          variables: {
            id_utilisateur: JSON.parse(localStorage.getItem('utilisateur')).id,
            categorie_id: this.Filter.Product,
            nom: this.Filter.cname,
            options: options.slice(0, -1)
          },
        }).then(response => {
          this.$notify({type: 'success', message: "filtre créé avec succès" })
          this.$router.push({ name: 'mes filtres Leadmarket Annonceur' })
          
        }).catch(error => {
          this.$notify({type: 'warning', message: this.$t("apiresponse.errorcreation") })
        })
      },
      removeORCondition(index) {
        this.ConditionsOr.splice(index, 1); 
        this.showOr.splice(index, 1); 
      }
    },
  }
</script>