<template>
  <div>
    <div class="row justify-content-center">
      <base-input label="Nom de la commande" placeholder="NOM DE LA COMMANDE"
        v-model="Commande.name_cmd"
        class="col-sm-5"
        type="text">
      </base-input>
      <base-input label="Produit" :error="getError('Produit')" class="col-sm-5">
        <el-select class="select-primary"
          filterable
          clearable
          placeholder="PRODUIT"
          name="Produit"
          v-validate="'required'"
          v-model="Commande.Product">
          <el-option 
            v-for="option in ProductSelects.ListProduct"
            class="select-primary"
            :value="option.pk_categorie"
            :label="option.designation.replace(/Ã©/g,'é').toUpperCase()"
            :key="option.id">
          </el-option>
        </el-select>
      </base-input>
      <base-input label="Lead" class="col-sm-10" v-if="Commande.Product == 1 && !Commande.Filtre">
        <base-radio inline name="salaries" class="mb-3" v-model="Commande.options_sante">
          SALARIÉS
        </base-radio>
    
        <base-radio inline name="seniors" class="mb-3" v-model="Commande.options_sante">
          SÉNIORS
        </base-radio>
      </base-input>
      <base-input label="Département" class="col-sm-5">
        <el-select class="select-primary"
          filterable
          multiple
          collapse-tags
          placeholder="DÉPARTEMENT"
          @change="changeDepartSelect"
          v-model="Commande.Departement">
          <el-option 
            class="select-primary"
            value="ALL_SELECT"
            label="TOUT SÉLECTIONNER"
          >
          </el-option>
          <hr/>
          <el-option 
            v-for="option in DepartementSelects.ListDepartement"
            class="select-primary"
            :value="option.nom"
            :label="option.nom.toUpperCase()"
            :key="option.pk_departement">
          </el-option>
        </el-select>
      </base-input>
      <base-input label="" class="col-sm-5">
        <base-input class="mt-lg-4">
          <base-radio inline name="inclure"  v-model="Commande.exclure">
            INCLURE
          </base-radio>
      
          <base-radio inline name="exclure" v-model="Commande.exclure">
            EXCLURE
          </base-radio>
        </base-input>
      </base-input>

      <base-input label="Filtre" class="col-sm-5">
        <el-select class="select-primary"
          filterable
          clearable
          placeholder="FILTRE"
          v-model="Commande.Filtre">
          <el-option 
            v-for="option in FiltreSelects.ListFiltre"
            class="select-primary"
            :value="option.id"
            :label="option.nom.replace(/Ã©/g,'é')"
            :key="option.id">
          </el-option>
        </el-select>
      </base-input>
      <div class="col-sm-5">
        
      </div>
      <base-input label="Volume de leads TRIO" placeholder="VOLUME DE LEADS TRIO"
        v-model="Commande.quantite_lead_trio"
        class="col-sm-3"
        type="number">
      </base-input>

      <base-input label="Volume de leads Duo" placeholder="VOLUME DE LEADS DUO"
        v-model="Commande.quantite_lead_duo"
        class="col-sm-3"
        type="number">
      </base-input>

      <base-input label="Volume de leads Exclusif" placeholder="VOLUME DE LEADS EXCLUSIF"
        v-model="Commande.quantite_lead_exclusif"
        class="col-sm-4"
        type="number">
      </base-input>

      <!-- <div class="col-sm-3"></div> -->


      <base-input label="Mode de livraison" class="col-sm-5">
        <base-input class="col-sm-12">
          <base-radio inline name="export" class="mb-3" v-model="Commande.mode_livraison">
            EXPORT FICHIER CSV
          </base-radio>
      
          <base-radio inline name="ws" class="mb-3" v-model="Commande.mode_livraison">
            WEBSERVICE
          </base-radio>
        </base-input>
      </base-input>
      <div class="col-sm-5">
        <!-- <base-input label="VOTRE DOCUMENTATION WEB SERVICE" v-if="Commande.mode_livraison == 'ws'">
          <image-upload
            @change="onFileChange"
            src="/s"
            select-text="SÉLECTIONNER UN FICHIER">
          </image-upload>
        </base-input> -->
      </div>

      <base-input label="Délai de livraison des leads" class="col-sm-5">
        <base-input class="col-sm-12">
          <base-radio inline name="immediat" class="mb-3" v-model="Commande.delai_livraison">
            IMMÉDIAT
          </base-radio>
      
          <base-radio inline name="etale" class="mb-3" v-model="Commande.delai_livraison">
            ETALÉ SUR PLUSIEURS JOURS
          </base-radio>
        </base-input>
      </base-input>
      <div class="col-sm-5">
      </div>

      <template v-if="Commande.delai_livraison == 'etale'">
        <div class="col-sm-5">
          <base-input label="Nombre de leads par jour et par gamme" placeholder="NOMBRE DE LEADS"
            v-model="Commande.lead_par_jour"
            :error="getError('Nombre de leads')"
            name="Nombre de leads"
            v-validate="(Commande.Product == 12) ? check_Emp : check"
            type="number">
          </base-input>
        </div>

        <base-input class="col-sm-5" label="Date début livraison">
          <el-date-picker 
            v-model="Commande.date_debut_livraison_client" 
            value-format="yyyy-MM-dd" 
            type="date" 
            placeholder="YYYY-MM-DD">
          </el-date-picker>
        </base-input>

        <base-input label="Jours de livraison :" class="col-sm-10">
          <base-checkbox inline class="mb-3" v-model="Commande.jours_livraison.lundi">
            LUNDI
          </base-checkbox>
          <base-checkbox inline class="mb-3" v-model="Commande.jours_livraison.mardi">
            MARDI
          </base-checkbox>
          <base-checkbox inline class="mb-3" v-model="Commande.jours_livraison.mercredi">
            MERCREDI
          </base-checkbox>
          <base-checkbox inline class="mb-3" v-model="Commande.jours_livraison.jeudi">
            JEUDI
          </base-checkbox>
          <base-checkbox inline class="mb-3" v-model="Commande.jours_livraison.vendredi">
            VENDREDI
          </base-checkbox>
          <base-checkbox inline class="mb-3" v-model="Commande.jours_livraison.samedi">
            SAMEDI
          </base-checkbox>
          <base-checkbox inline class="mb-3" v-model="Commande.jours_livraison.dimanche">
            DIMANCHE
          </base-checkbox>
        </base-input>

        <label class="col-sm-10">HORAIRE DE LIVRAISON : </label>
        <base-input :error="getError('Heure début')" class="col-sm-5">
          <el-time-select
            :disabled="userTempsReelCmdWeedoMarket"
            name="Heure début"
            v-validate="'required'"
            v-model="Commande.heure_debut_livraison"
            :picker-options="{
              start: '00:00',
              step: '00:01',
              end: '23:59'
            }"
            placeholder="HEURE DÉBUT">
          </el-time-select>
        </base-input>

        <base-input class="col-sm-5">
          <el-time-select
            :disabled="userTempsReelCmdWeedoMarket"
            name="Heure fin"
            v-model="Commande.heure_fin_livraison"
            :picker-options="{
              start: '00:00',
              step: '00:01',
              end: '23:59'
            }"
            placeholder="HEURE FIN">
          </el-time-select>
        </base-input>
        
      </template>

      <base-input label="Notification par E-mail :" class="col-sm-10">
        <base-checkbox inline class="mb-3" v-model="Commande.email_notification">
          ÊTRE NOTIFIÉ POUR CHAQUE LEAD REÇU
        </base-checkbox>
      </base-input>
      <template v-if="law === 'ad'">
        <base-input 
          :disabled="law != 'ad'"
          label="Prix unitaire du lead Trio" 
          placeholder="PRIX UNITAIRE DU LEAD TRIO"
          v-model="Commande.prix_unitaire_lead_trio"
          class="col-sm-3"
          addonRightIcon="fas fa-euro-sign"
          textIconRight="HT"
          type="number">
        </base-input>
        

        <base-input 
          :disabled="law != 'ad'"
          label="Prix unitaire du lead Duo" 
          placeholder="PRIX UNITAIRE DU LEAD DUO"
          v-model="Commande.prix_unitaire_lead_duo"
          class="col-sm-3"
          addonRightIcon="fas fa-euro-sign"
          textIconRight="HT"
          type="number">
        </base-input>

        <base-input 
          :disabled="law != 'ad'"
          label="Prix unitaire du lead Exclusif" 
          placeholder="PRIX UNITAIRE DU LEAD EXCLUSIF"
          v-model="Commande.prix_unitaire_lead_exclusif"
          class="col-sm-4"
          addonRightIcon="fas fa-euro-sign"
          textIconRight="HT"
          type="number">
        </base-input>

        <!-- <div class="col-sm-3"></div> -->

        <base-input label="Montant HT" placeholder=""
          disabled
          v-model="SUMtotal"
          class="col-sm-5"
          addonRightIcon="fas fa-euro-sign"
          type="number">
        </base-input>
      </template>
      <template v-else>
        <base-input 
          disabled
          label="Prix unitaire du lead Trio" 
          placeholder="PRIX UNITAIRE DU LEAD TRIO"
          v-bind:value="Commande.prix_unitaire_lead_trio"
          class="col-sm-3"
          addonRightIcon="fas fa-euro-sign"
          textIconRight="HT"
          type="number">
        </base-input>

        <base-input 
          disabled
          label="Prix unitaire du lead Duo" 
          placeholder="PRIX UNITAIRE DU LEAD DUO"
          v-bind:value="Commande.prix_unitaire_lead_duo"
          class="col-sm-3"
          addonRightIcon="fas fa-euro-sign"
          textIconRight="HT"
          type="number">
        </base-input>

        <base-input 
          disabled
          label="Prix unitaire du lead Exclusif" 
          placeholder="PRIX UNITAIRE DU LEAD EXCLUSIF"
          v-bind:value="Commande.prix_unitaire_lead_exclusif"
          class="col-sm-4"
          addonRightIcon="fas fa-euro-sign"
          textIconRight="HT"
          type="number">
        </base-input>

        <base-input label="Montant HT" placeholder=""
          disabled
          v-bind:value="SUMtotal"
          class="col-sm-5"
          addonRightIcon="fas fa-euro-sign"
          type="number">
        </base-input>
      </template>
      <div class="col-sm-5"></div>

    </div>
    
  </div>
</template>
  
<script>
  import {Select, Option, DatePicker, TimeSelect} from 'element-ui'
  import {Table, TableColumn} from 'element-ui'
  import { BaseRadio } from 'src/components/index'
  import gql from 'graphql-tag'
  import {ImageUpload} from 'src/components'

  export default {
    computed: {
      SUMtotal() {
        let total = 0
        total = this.montant_lead_trio + this.montant_lead_duo + this.montant_lead_exclusif
        total = total.toFixed(2)
        this.Commande.montant = total
        return total
      }
    },
    watch: {
      'Commande.Departement' (val) {
        if (val) {
          if (val.length == 106) {
            this.Commande.Departement_parse = 'tout'
          }
          else {
            this.Commande.Departement_parse = ''
            val.forEach(element => {
              this.Commande.Departement_parse += element + ','
            });
          }
        }
        else {
          this.Commande.Departement_parse = 'tout'
        }
      },
      'Commande.Product'(val) {
        if(this.skipProductWatcher) {
          this.Commande.Filtre = null
        }
        if (val) {
          let product = this.produitPackTarifAnnonceur.find(x => x.pk_categorie == val)
          if (product) {
            this.Commande.prix_unitaire_lead_trio = product.trio
            this.Commande.prix_unitaire_lead_duo = product.duo
            this.Commande.prix_unitaire_lead_exclusif = product.exclusif
          } else {
            this.Commande.prix_unitaire_lead_trio = 0
            this.Commande.prix_unitaire_lead_duo = 0
            this.Commande.prix_unitaire_lead_exclusif = 0
          }
        } else {
          this.Commande.prix_unitaire_lead_trio = 0
          this.Commande.prix_unitaire_lead_duo = 0
          this.Commande.prix_unitaire_lead_exclusif = 0
        }
        if (this.Commande.Product === 1) {
          this.$emit('collected_fileds', ["Garantie Hospitalisation","Garantie Dentaire","Frais médicaux","Optique","Date de naissance du prospect","Régime","Nombre d'enfants","E-mail","Date de naissance du conjoint","Régime du conjoint","Civilité","Nom","Prénom","Adresse postale","Code postal","Ville","Téléphone Mobile ou Fixe","Date d'effet"])
        }
        if (this.Commande.Product === 2) {
          this.$emit('collected_fileds', ["Date d'obtention du permis","Coefficient Bonus/Malus","Marque du véhicule à assurer","Modèle du véhicule","Type d´énergie du véhicule","Chevaux Fiscaux","Carosserie","Version véhicule","Civilité","Nom","Prénom","Date naissance","E-mail","Code postal","Ville","Téléphone Mobile ou Fixe","Annulation ou suspension permis","Actuelle assuré ?","Nombre d'années d'assurance","Résilié au cours des 2 dernières années ?","Sinistres responsable au cours des 3 dernières années ?"])
        }
        if (this.Commande.Product === 4) {
          this.$emit('collected_fileds', ["Type d'animal","Race","Sexe","Tatoué ou pucé ?","Nom de l'animal","Niveau de garantie souhaité","Date de naissance de l'animal","Civilité","Nom","Prénom","Date de naissance","Adresse postale","Code postal","Ville","Téléphone Mobile ou Fixe","E-mail","Date d'effet"])
        }
      },
      'Commande.prix_unitaire_lead_trio'(val) {
        this.montant_lead_trio = this.Commande.prix_unitaire_lead_trio * this.Commande.quantite_lead_trio
      },
      'Commande.prix_unitaire_lead_duo'(val) {
        this.montant_lead_duo = this.Commande.prix_unitaire_lead_duo * this.Commande.quantite_lead_duo
      },
      'Commande.prix_unitaire_lead_exclusif'(val) {
        this.montant_lead_exclusif = this.Commande.prix_unitaire_lead_exclusif * this.Commande.quantite_lead_exclusif
      },
      'Commande.quantite_lead_trio'(val) {
        this.montant_lead_trio = this.Commande.prix_unitaire_lead_trio * this.Commande.quantite_lead_trio
      },
      'Commande.quantite_lead_duo'(val) {
        this.montant_lead_duo = this.Commande.prix_unitaire_lead_duo * this.Commande.quantite_lead_duo
      },
      'Commande.quantite_lead_exclusif'(val) {
        this.montant_lead_exclusif = this.Commande.prix_unitaire_lead_exclusif * this.Commande.quantite_lead_exclusif
      }
    },
    apollo: {
      userTempsReelCmdWeedoMarket: {
        client: 'AUTH',
        query: gql` query ($id_utilisateur:  ID) {
          userTempsReelCmdWeedoMarket(id_utilisateur: $id_utilisateur)
        }`,
        variables() {
          return {
            id_utilisateur: JSON.parse(localStorage.getItem('utilisateur')).id
          }
        },
        result({ data, loader, networkStatus }) {
          if (data.userTempsReelCmdWeedoMarket == true) {
            this.Commande.heure_debut_livraison = "09:00"
            this.Commande.heure_fin_livraison = "08:59"
          }
        },
        fetchPolicy: 'network-only'
      },
      law : {
        query: gql` query {
          me  {
            law
          }
        }`,
        fetchPolicy: 'network-only',
        update: data =>  data.me.law,
      },
      wsExiste: {
        client: 'MARKET',
        query: gql` query ($id_utilisateur: ID, $categorie_id: Int) {
          wsExiste( id_utilisateur: $id_utilisateur , categorie_id: $categorie_id ) {
            state
          }
        }`,
        variables() {
          return {
            id_utilisateur: JSON.parse(localStorage.getItem('utilisateur')).id,
            categorie_id: this.Commande.Product
          }
        },
        fetchPolicy: 'network-only',
        update: data =>  data.wsExiste,
        result({ data, loader, networkStatus }) {
          if(data.wsExiste.state)
            this.Commande.mode_livraison = "ws"
          else
          this.Commande.mode_livraison = "export"
        },
      },
      champsProduit: {
        client: 'MARKET',
        query: gql` query ($id_utilisateur: ID, $categorie_id: Int) {
          champsProduit( id_utilisateur: $id_utilisateur , categorie_id: $categorie_id ) {
            resultat
          }
        }`,
        variables() {
          return {
            id_utilisateur: JSON.parse(localStorage.getItem('utilisateur')).id,
            categorie_id: this.Commande.Product
          }
        },
        skip() {
          return !this.Commande.Product || this.Commande.Product === 1 || this.Commande.Product === 2 || this.Commande.Product === 4
        },
        fetchPolicy: 'network-only',
        update: data =>  decodeURIComponent(escape(data.champsProduit.resultat)).split("|"),
        result({ data, loader, networkStatus }) {
          this.$emit('collected_fileds', this.champsProduit)
        },
      },
      soldeSuffisant: {
        client: 'MARKET',
        query: gql` query ($id_utilisateur: ID, $montant: Float) {
          soldeSuffisant(id_utilisateur: $id_utilisateur, montant: $montant)
          {
            state
          }
        }`,
        variables() {
          return {
            id_utilisateur: JSON.parse(localStorage.getItem('utilisateur')).id,
            montant: 0
          }
        },
        fetchPolicy: 'network-only',
        update: data => data.soldeSuffisant.state
      },
      Filters: {
        client: 'MARKET',
        query: gql` query ($id_utilisateur: ID, $categorie_id: Int) {
          listFiltres(
            id_utilisateur: $id_utilisateur
            categorie_id: $categorie_id
          )
          {
            id
            nom
          }
        }`,
        skip() {
          return !this.Commande.Product
        },
        variables() {
          return {
            id_utilisateur: JSON.parse(localStorage.getItem('utilisateur')).id,
            categorie_id: this.Commande.Product
          }
        },
        fetchPolicy: 'network-only',
        result({ data, loader, networkStatus }) {
          this.FiltreSelects.ListFiltre = data.listFiltres
        },
        update: data => data.listFiltres
      },
      produits : {
        client: 'MARKET',
        query: require("src/graphql/leadmarket/Annonceur/PrepareAddCommand.gql"),
        variables() {
          return {
            id_utilisateur: JSON.parse(localStorage.getItem('utilisateur')).id,
            duplique_id: null
          }
        },
        result({ data, loader, networkStatus }) {
          this.DepartementSelects.ListDepartement = data.createCommande[0].departements
          this.ProductSelects.ListProduct = data.createCommande[0].categories
          this.Commande.tva = data.createCommande[0].tva
          if (this.$route.params.commande_id) {
            this.$apollo.query({
              client: 'MARKET',
              query: require("src/graphql/leadmarket/Annonceur/PrepareAddCommand.gql"),
              variables: {
                id_utilisateur: JSON.parse(localStorage.getItem('utilisateur')).id,
                duplique_id: this.$route.params.commande_id
              },
              fetchPolicy: 'network-only'
            }).then(data => {
              if (data.data.createCommande[0].commande_duplique != null)
                this.presetField(data.data.createCommande[0].commande_duplique, data.data.createCommande[0].ligne_commande)
            }).catch((error) => {
              console.error(error)
            })
          }
        },
        fetchPolicy: 'network-only',
        update: data => data.createCommande[0]
      },
      produitPackTarifAnnonceur: {
        client: 'MARKET',
        query: require("src/graphql/leadmarket/Annonceur/produitPackTarifAnnonceur.gql"),
        result({ data, loader, networkStatus }) {
        },
        update: data => data.produitPackTarifAnnonceurV2[0].tarifs,
        fetchPolicy: 'network-only',
      }
    },
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [DatePicker.name]: DatePicker,
      ImageUpload,
      BaseRadio,
      [TimeSelect.name]: TimeSelect
    },
    data() {
      return {
        skipProductWatcher: false,
        departallselect: false,
        userTempsReelCmdWeedoMarket: false,
        Commande: {
          tva: null,
          name_cmd: '',
          Product: '',
          options_sante: '',
          Departement: '',
          Departement_parse: 'tout',
          exclure: 'inclure',
          Filtre: null,
          quantite_lead_trio: null,
          quantite_lead_duo: null,
          quantite_lead_exclusif: null,
          mode_livraison: 'export',
          WS_File: null,
          delai_livraison: 'immediat',
          date_debut_livraison_client: null,
          lead_par_jour: null,
          jours_livraison: {
            lundi: true,
            mardi: false,
            mercredi: false,
            jeudi: false,
            vendredi: false,
            samedi: false,
            dimanche: false
          },
          heure_debut_livraison: '',
          heure_fin_livraison: '',
          email_notification: false,
          prix_unitaire_lead_trio: null,
          prix_unitaire_lead_duo: null,
          prix_unitaire_lead_exclusif: null,
          montant: null
        },
        ProductSelects: {
          ListProduct: []
        },
        DepartementSelects: {
          ListDepartement: []
        },
        FiltreSelects: {
          ListFiltre: []
        },
        montant_lead_trio: 0,
        montant_lead_duo: 0,
        montant_lead_exclusif: 0,
        check: {
          required: true,
          min_value: 10
        },
        check_Emp: {
          required: true,
          min_value: 5
        }
      }
    },
    methods: {
      async GotoOptions() {
        let validate
        await this.$validator.validateAll().then(isValid => {
          validate = isValid
        })
        if (!validate)
          return false

        // Merci de choisir au minimum 4 départements
        if (this.Commande.Departement.length) {
          if (this.Commande.Departement.length < 1 && this.Commande.exclure === "inclure") {
            this.$notify({type: 'warning', message: "Merci de choisir au minimum 1 départements" })
            return false
          }
        }

        if (!this.Commande.quantite_lead_trio && !this.Commande.quantite_lead_duo && !this.Commande.quantite_lead_exclusif) {
          this.$notify({type: 'warning', message: "Vous n'avez pas renseigné le volume de votre commande !" })
          return false
        }

        let lead_exclusif = this.Commande.quantite_lead_exclusif ? parseInt(this.Commande.quantite_lead_exclusif) : 0
        let lead_duo = this.Commande.quantite_lead_duo ? parseInt(this.Commande.quantite_lead_duo) : 0
        let lead_trio = this.Commande.quantite_lead_trio ? parseInt(this.Commande.quantite_lead_trio) : 0
        // Emprunteur check 
        if (this.Commande.Product == 12) {
          if ((lead_exclusif + lead_duo + lead_trio) < 5) {
            this.$notify({type: 'warning', message: "Votre commande doit contenir au minimum 5 leads." })
            return false
          }
        }
        // Emprunteur check 
        else {
          if ((lead_exclusif + lead_duo + lead_trio) < 10) {
            this.$notify({type: 'warning', message: "Votre commande doit contenir au minimum 10 leads." })
            return false
          }
        }

        if (this.Commande.delai_livraison == 'etale') {
          if (!this.Commande.jours_livraison.lundi && !this.Commande.jours_livraison.mardi && !this.Commande.jours_livraison.mercredi && !this.Commande.jours_livraison.jeudi && !this.Commande.jours_livraison.vendredi && !this.Commande.jours_livraison.samedi && !this.Commande.jours_livraison.dimanche) {
            this.$notify({type: 'warning', message: "Vous n'avez pas renseigné un jour de livraison" })
            return false
          }
          if ((lead_exclusif + lead_duo + lead_trio) < this.Commande.lead_par_jour) {
            this.$notify({type: 'warning', message: "Le volume à livrer est en conflit avec le volume commandé. Veuillez corriger." })
            return false
          }
        }

        if (this.Commande.Product === 1 && !this.Commande.Filtre) {
          if (!this.Commande.options_sante) {
            this.$notify({type: 'warning', message: "Vous n'avez pas renseigné le type de lead (SALARIÉS / SÉNIORS)" })
            return false
          }
        }

        await this.$apollo.queries.soldeSuffisant.refetch({
          id_utilisateur: JSON.parse(localStorage.getItem('utilisateur')).id,
          montant: this.Commande.montant
        })
        
        if (!this.soldeSuffisant) {
          this.$notify({type: 'warning', message: "Vous n'avez pas de solde !" })
          return false
        }
        
        if(this.Commande.Filtre) {
          this.Commande.options_sante = ''
        }

        if (this.Commande.Product != 1) {
          this.Commande.options_sante = ''
        }

        if (this.Commande.delai_livraison === "etale") {
          if (!this.Commande.date_debut_livraison_client) {
            this.Commande.date_debut_livraison_client = new Date().getFullYear() + "-"+ (new Date().getUTCMonth() + 1) + "-" + new Date().getUTCDate()
          }
        }
        
        this.$root.$emit('Commande_Object', this.Commande)
        let elmnt = document.getElementById('TOPPAGE');
        elmnt.scrollIntoView(false);
        return true
      },
      async validate() {
        this.$store.commit('setcheckuserExist', true)
        let emailexist = await this.GotoOptions()
        this.$store.commit('setcheckuserExist', false)
        if(!emailexist) {
          this.$emit('Check_Cmd', "error")
          return false
        }
        else
          return true
      },
      getError(fieldName) {
        return this.errors.first(fieldName)
      },
      onFileChange(file) {
        this.Commande.WS_File = file;
      },
      changeDepartSelect(value) {
        const allValues = []
        for (const item of this.DepartementSelects.ListDepartement) {
          allValues.push(item.nom)
        }

        if (value.includes('ALL_SELECT')) {
          if (this.departallselect = !this.departallselect)
            this.Commande.Departement = allValues;
          else
            this.Commande.Departement = []
        }
      },
      presetField(commande_duplique, ligne_duplique) {

        this.Commande.name_cmd = this.replaceUTF_8(commande_duplique.nom)        
        this.Commande.Product = parseInt(commande_duplique.fk_categorie)
        this.$nextTick(() => {
          this.skipProductWatcher = true
        })
        this.Commande.options_sante = commande_duplique.options
        if (commande_duplique.geolocalisation) {
          this.Commande.Departement = this.replaceUTF_8(commande_duplique.geolocalisation).split(",")
        }
        if (parseInt(commande_duplique.fk_filtre)){ 
          this.Commande.Filtre = parseInt(commande_duplique.fk_filtre)
        }
        this.Commande.exclure = (commande_duplique.exclure) ? 'exclure' : 'inclure'

        if(ligne_duplique) {
          ligne_duplique.forEach(element => {
            if (element.fk_pack == "1") {
              this.Commande.quantite_lead_exclusif = element.quantite
            }
            else if (element.fk_pack == "4") {
              this.Commande.quantite_lead_duo = element.quantite
            }
            else if (element.fk_pack == "2") {
              this.Commande.quantite_lead_trio = element.quantite
            }
          });
        }

        this.Commande.mode_livraison = commande_duplique.mode_livraison
        this.Commande.delai_livraison = commande_duplique.delai_livraison
        if(this.Commande.delai_livraison === 'etale') {
          this.Commande.lead_par_jour = commande_duplique.lead_par_jour
          this.Commande.date_debut_livraison_client = new Date().getFullYear() + "-"+ (new Date().getUTCMonth() + 1) + "-" + new Date().getUTCDate()
          if (commande_duplique.jours) {
            this.Commande.jours_livraison.lundi = false
            commande_duplique.jours.split(',').forEach(j => {
              if (j.toLowerCase() == 'lundi') {
                this.Commande.jours_livraison.lundi = true
              }
              else if (j.toLowerCase() == 'mardi') {
                this.Commande.jours_livraison.mardi = true
              }
              else if (j.toLowerCase() == 'mercredi') {
                this.Commande.jours_livraison.mercredi = true
              }
              else if (j.toLowerCase() == 'jeudi') {
                this.Commande.jours_livraison.jeudi = true
              }
              else if (j.toLowerCase() == 'vendredi') {
                this.Commande.jours_livraison.vendredi = true
              }
              else if (j.toLowerCase() == 'samedi') {
                this.Commande.jours_livraison.samedi = true
              }
              else if (j.toLowerCase() == 'dimanche') {
                this.Commande.jours_livraison.dimanche = true
              }
            });
          }
          if (!this.userTempsReelCmdWeedoMarket) {
            this.Commande.heure_debut_livraison = commande_duplique.heure_debut_livraison
            this.Commande.heure_fin_livraison = commande_duplique.heure_fin_livraison
          }
        }

        this.Commande.email_notification = commande_duplique.email_notification
      },
      replaceUTF_8(string) {
        if(!string) 
          return ''

        for (const [key, value] of Object.entries(this.$store.state.UT8_array)) {
          string = string.replaceAll(value, key)
        }
        
        return string
      }
    },
  }
</script>