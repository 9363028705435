<template>
  <div>
    <notifications></notifications>
        <div class="col-md-12">
          <div id="TOPPAGE"></div>
          <simple-wizard datacolor="market" btn_finish="Valider & Payer">
            <template slot="header">
              <h5 class="description"></h5>
            </template>

            <wizard-tab :before-change="() => validateStep('step1')">
              <template slot="label">
                <i class="tim-icons icon-badge"></i>
                <p>COMMANDE</p>
              </template>
              <first-step ref="step1" @on-validated="onStepValidated" @Check_Cmd="StepError" @collected_fileds="selFiledsCollected"></first-step>
            </wizard-tab>

            <wizard-tab :before-change="() => validateStep('step2')">
              <template slot="label">
                <i class="tim-icons icon-bag-16"></i>
                <p>RÉCAPITULATIF</p>
              </template>
              <second-step ref="step2" @on-validated="onStepValidated"></second-step>
            </wizard-tab>
            
            <wizard-tab :before-change="() => validateStep('step3')">
              <template slot="label">
                <i class="tim-icons icon-notes"></i>
                <p>CONFIRMATION</p>
              </template>
              <third-step ref="step3" @on-validated="onStepValidated"></third-step>
            </wizard-tab>
            
          </simple-wizard>

        </div>
    <card>
      <div class="row">
        <div class="col-md-6">
          <div class="whitcontent h4">TYPE DES LEADS :</div><br/><br/>
          <card>
            <div class="whitcontent label">
              <b><img src="/img/lead_trio.png" style="height: 15%; width: 15%;" alt="" />
              &nbsp;LEAD TRIO </b><br/>
              Les Leads de la gamme TRIO ne sont pas exclusifs. Cependant <nobr>WEEDO IT</nobr> ne pourra les commercialiser qu’à 
              trois (3) Clients maximums.
            </div>                            
            <br/>
          </card>
          <card>
            <div class="whitcontent label">
              <b><img src="/img/lead_duo.png" style="height: 15%; width: 15%;" alt="" />
              &nbsp;LEAD DUO </b><br/>
              Les Leads de la gamme DUO ne sont pas exclusifs. Cependant <nobr>WEEDO IT</nobr> ne pourra les commercialiser qu’à deux 
              (2) Clients maximums.
              <br/>
            </div>
          </card>
          <card>
            <div class="whitcontent label">
              <b><img src="/img/lead_exclusif.png" style="height: 15%; width: 15%;" alt="" />
              &nbsp;LEAD EXCLUSIF </b><br/>
              Les Leads EXCLUSIF commercialisés par <nobr>WEEDO IT</nobr> sont exclusifs. Lorsqu’un Client Commande un Lead « Gold »,
              <nobr>WEEDO IT</nobr> lui assure l’exclusivité dudit contact. Ainsi, <nobr>WEEDO IT</nobr> ne commercialisera ce Lead à aucun autre 
              Client.                  
              <br/>
            </div>
          </card>
        </div>
        <div class="col-md-6">
          <div class="whitcontent h4">CHAMPS COLLECTÉS : </div><br/><br/>
          <card>
            <template v-for="champ in champsProduit">
              <div class="whitcontent label"> {{ champ }} </div> <br/>
            </template>
          </card>
        </div>
      </div>
    </card>
  </div>
</template>
<script>
import FirstStep from "./AddCmd/FirstStep.vue";
import SecondStep from "./AddCmd/SecondStep.vue";
import ThirdStep from "./AddCmd/ThirdStep.vue";

import { SimpleWizard, WizardTab, BaseAlert } from "src/components";
import { config } from "src/cognito_conf.js";

export default {
  data() {
    return {
      champsProduit: [],
      wizardModel: {},
      ereurs: []
    };
  },
  components: {
    FirstStep,
    SecondStep,
    ThirdStep,
    SimpleWizard,
    WizardTab,
    BaseAlert
  },
  methods: {
    validateStep(ref) {
      return this.$refs[ref].validate();
    },
    onStepValidated(validated, model) {
      this.wizardModel = { ...this.wizardModel, ...model };
      if(model.step == "final"){
        this.wizardComplete()
      }
    },
    StepError(message){
    },
    wizardComplete() {
      
      return false;
    },
    selFiledsCollected(collected_fileds) {
      this.champsProduit = collected_fileds
    }
  }
};
</script>
