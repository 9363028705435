<template>
  <div class="popup" v-show="$store.state.PopUp_Whatsapp_WM">
    <div class="popup__inner">
      <div class="popupcontent">
        <button type="button" class="closep" aria-label="Close">
          <span aria-hidden="true" @click="closeWahtsappPopup">×</span>
        </button>

        <div class="popup__body">
          <div class="logo__popup">
            <img src="img/logo-popup.png" alt="" />
          </div>
          <div class="content_popup">
            <div class="title_popup">Retrouvez Weedo Market sur WhatsApp !</div>
            <div class="AllBg">
              <div class="bg-num">07 85 47 06 84</div>
            </div>
            <div class="txt_popup">
              Enregistrez le dans vos<br />
              contacts WhatsApp
            </div>
            <div class="scancode">
              <img src="img/scan-code.png" alt="" />
            </div>
          </div>
        </div>
        <!-- /.popup__body -->
      </div>
    </div>
    <!-- /.popup__inner -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters([
      'getendregistration'
    ]),
  },
  methods: {
    closeWahtsappPopup() {
      this.$store.commit("setPopUp_Whatsapp_WM", false);
    }
  }
}
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
}

.popup {
  z-index: 1050;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  opacity: 1;
  background: rgba(21, 22, 30, 0.7);
}

.popup .popup__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  align-items: center;
  width: 95%;
  margin: auto;
  max-width: 600px;
  min-height: calc(100% - (0.5rem * 2));
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.logo__popup {
  float: left;
}

.content_popup {
  padding: 22px 0 11px 0;
  text-align: right;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
}

.content_popup,
.txt_popup,
.bg-num,
.scancode,
button.closep {
  float: right;
}

.title_popup {
  padding: 0 4px 11px 0;
}

.txt_popup {
  padding: 2px 8px 0 0;
  font-size: 16px;
  font-weight: 500;
}

.bg-num {
  width: 250px;
  height: 45px;
  padding: 7px 0 0 16px;
  text-align: center;
  font-size: 23px;
  font-weight: bold;
  color: #41e460;
  background: url("/img/bg-num.png") no-repeat;
}

.scancode {
  width: 100%;
  padding-top: 48px;
}

.popupcontent {
  position: relative;
  width: 100%;
  height: 360px;
  padding: 9px 16px;
  background: #239c56 url("/img/bg-popup.jpg") no-repeat top center;
}

button.closep {
  cursor: pointer;
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 25px;
  font-weight: normal;
  opacity: 1;
  color: #fff !important;
  line-height: 10px;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
}

@media only screen and (max-width: 600px) {
  .popupcontent {
    height: auto;
    background-size: 100%;
  }

  .logo__popup {
    float: right;
  }

  .content_popup,
  .AllBg,
  .scancode {
    width: 100%;
  }

  .scancode,
  .txt_popup {
    text-align: center;
  }

  .AllBg,
  .scancode {
    float: left;
  }
  .txt_popup {
    padding: 2px 0px 0 0;
  }
  .txt_popup br {
    display: none;
  }
  .scancode {
    padding-top: 20px;
  }

  .bg-num {
    float: none;
    margin: auto;
  }
}
</style>