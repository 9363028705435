<template>
  <div class="content weedomarket">
    <div class="container">
      <router-link :to="{ name : 'Partners Leadmarket Annonceur' }">
        <div class="backBtn">
          <a href="#"><i class="fas fa-chevron-left"></i> Retour</a>
        </div>
      </router-link>
      <div class="row">
        <div class="col-lg-5 col-md-6 m-auto">
          <div class="card card-stats">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <div class="numbers text-center">
                    <img src="img/logo-so-soft.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div data="red" class="card card-chart boxDetails">
            <div class="card-header">
              <div class="row">
                <div class="col-sm-12 text-center">
                  <h2 class="card-title">
                    <strong>Market-place ouverte - So Soft</strong>
                  </h2>
                </div>
              </div>
            </div>

            <div class="card-body p-4 contentCard vertC">
              <div class="col-lg-12">
                <p>
                  So Soft est une plateforme SaaS permettant aux professionnels
                  de l’assurance de comparer, tarifer et choisir la meilleure solution disponsible auprès de 22 fournisseurs
                  sur le marché pour leur clients.
                </p>

                <div class="row">
                  <div class="col-lg-12 m-auto list_besoins">
                    <br /><span>Ses points forts :</span>
                    <div class="row m-auto">
                      <div class="col-lg-6 pl-1 pr-1">
                        <ul class="mt-4">
                          <li>Un espace client sécurisé</li>
                          <li>
                            Plus de 3000 contrats référencés et près de 450
                            solutions tarifées
                          </li>
                          <li>Des filtres intelligents</li>
                        </ul>
                      </div>

                      <div class="col-lg-6 pl-1 pr-1">
                        <ul class="mt-4">
                          <li>Tableau de synthèse</li>
                          <li>Devoir de conseil automatisé</li>
                          <li>Accès en multi-utilisateur</li>
                          <li>Une comparaison poste par poste dans un langage unifié</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row m-0 pt-5 text-center box_offrepartenaire">
                <div class="col-lg-7 pt-md-5 mt-md-3 pt-0 mt-0">
                  <h4>OFFRE DE PARTENARIAT*</h4>

                  <span class="pt-md-2 pt-0 pb-5 d-block"
                    >Grâce au partenariat entre<br />Weedo Market et So Soft,
                    vous bénéficiez de :</span
                  >

                  <div class="box__offre m-auto" style="cursor: pointer;" @click="SendMail()">
                    <span class="d-block text-uppercase">10 % offert</span>
                    <h4>SUR VOTRE ABONNEMENT ANNUEL</h4>
                    <h5>À la formule So-soft MAX</h5>
                  </div>
                  <b style="line-height: 43px;">RÉSERVÉ AUX CLIENTS WEEDO-IT</b>

                  <div class="pt-5 pb-4">
                    Une occasion pour vous de tester l'outil<br />et d'optimiser
                    vos performances.
                  </div>

                  <div class="note">
                    * Offre réservée aux nouveaux clients qui souscrivent un
                    abonnement à So Soft
                  </div>
                </div>

                <div class="col-lg-5 pt-md-5 mt-md-5 pt-0 mt-0">
                  <img
                    src="img/img-so-soft.jpg"
                    alt=""
                    class="pt-md-4 mt-md-2 pt-0 mt-0"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    SendMail() {
      var subject= "Offre de partenaire So Soft– Weedo Market";
      const numOrias = JSON.parse(localStorage.getItem('utilisateur')).orias;
      const orias = "Voici mon n° ORIAS : "+ (numOrias=='null' ? '' : numOrias) +"\n\n" 
      var body = "Bonjour,\nJe souhaite bénéficier du code promotionnel relatif à l’offre de partenariat So Soft proposant 10 % offert sur l’abonnement annuel à la formule So-soft MAX.\n\n"+orias+"N.B. : Vous pouvez prendre rendez-vous sur : \nhttps://www.so-soft.fr/contact \n\nCordialement\n\n";
      var uri = "mailto:contact@so-soft.fr;commercial@weedoit.fr?subject=";
      uri += encodeURIComponent(subject);
      uri += "&body=";
      uri += encodeURIComponent(body);
      window.open(uri);
    }
  }
}
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Fira+Sans+Condensed:wght@100;200;300;400;500;600;700;800;900&display=swap");

.weedomarket .container {
  position: relative;
  width: 100%;
  max-width: 100%;
  color: #fff !important;
}

.content.weedomarket .card {
  box-shadow: none;
}

.content.weedomarket .card.card-chart {
  border-radius: 0px !important;
}

.bodyBg {
  background: #27293d;
}

.content.weedomarket .card .card-header {
  padding: 20px 0 11px 0 !important;
}

.content.weedomarket .card.card-stats {
  width: 100%;
  margin: auto auto 30px auto;
  max-width: 437px;
}

.content.weedomarket,
.content.weedomarket p {
  font-family: "Poppins", sans-serif;
  font-size: 22px;
  color: #fff !important;
}

.content.weedomarket p {
  font-size: 20px;
  font-weight: 400 !important;
  line-height: 29px;
}

.content.weedomarket .card-title {
  font-family: "Poppins", sans-serif;
  font-size: 30px;
}

.content.weedomarket li {
  display: inline-flex;
  position: relative;
  width: 100%;
  padding-left: 25px;
  font-weight: 400;
  list-style: none;
}

.content.weedomarket li:before {
  content: "";
  position: absolute;
  top: 13px;
  left: 0;
  width: 13px;
  height: 13px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  background: #aeb618;
  -o-border-radius: 100%;
}

.content.weedomarket .card-body.contentCard .box__offre,
.content.weedomarket .card-body.contentCard.vertP .box__offre {
  padding: 54px 0;
  max-width: 646px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  font-family: "Poppins", sans-serif;
  font-size: 42px;
  font-weight: bold;
  -o-border-radius: 20px;
}

.content.weedomarket .card-body.vertP li::before,
.content.weedomarket .card-body.vertP .box__offre {
  background: #35d6b3;
}

.content.weedomarket .card-body.vert li::before,
.content.weedomarket .card-body.vert .box__offre {
  background: #61ce70;
}

.content.weedomarket .card-body.rouge li::before,
.content.weedomarket .card-body.rouge .box__offre {
  background: #e63c2f;
}

.content.weedomarket .card-body.vertC li::before,
.content.weedomarket .card-body.vertC .box__offre {
  background: #34cfbe;
}

.content.weedomarket .card-body.contentCard .box__offre span {
  font-size: 42px;
}

.content.weedomarket .card-body.contentCard .box__offre {
  padding: 0 0 10px 0;
  font-size: 22px;
  line-height: 43px;
}

.box_offrepartenaire {
  font-family: "Poppins", sans-serif;
}

.box_offrepartenaire h4 {
  font-size: 26px !important;
  font-weight: bold;
  color: #fff !important;
  line-height: 0px;
}

.box_offrepartenaire h5 {
  font-size: 20px !important;
  font-weight: bold;
  color: #fff !important;
  line-height: 20px;
}

.box_offrepartenaire span {
  line-height: 27px;
}

.box__offre {
  width: 100%;
  padding: 38px 0 37px 0;
  max-width: 476px;
  line-height: 32px;
  background: #aeb618;
}

.box__offre span {
  font-size: 36px;
  line-height: 70px;
}

.content.weedomarket .note {
  padding-bottom: 20px;
  font-size: 12px;
}

.listClient .carte {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  height: auto;
  padding-top: 32px;
  padding-bottom: 18px;
  min-height: 420px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  font-family: "Poppins", sans-serif;
  color: #27293d;
  background: #c7c7c7;
  -o-border-radius: 5px;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
}

.listClient .carte .title {
  padding: 8px 0;
  font-size: 20px;
}

.listClient .carte .parg {
  font-size: 12px;
}

.listClient .carte .btn {
  width: 90%;
  padding: 11px 0;
  max-width: 235px;
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  background: #ff004b;
}

.listClient .carte .btn:hover,
.listClient .carte .btn:focus {
  background: #ff004b !important;
  box-shadow: none !important;
}

.listClient .carte .bouton {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex: 1;
  align-items: flex-end;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: flex-end;
  -webkit-align-items: flex-end;
  -ms-flex-align: flex-end;
}

.txtInfo {
  font-size: 14px !important;
  line-height: 22px !important;
}

.backBtn {
  position: absolute;
  top: 0;
  width: 192px;
  padding: 11px 0 11px 75px;
  border: 2px solid #588bbd;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  font-family: "Poppins", sans-serif;
  color: #fff;
  -o-border-radius: 15px;
}

.backBtn .fa-chevron-left {
  position: absolute;
  top: 4px;
  left: 5px;
  width: 49px;
  height: 49px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  text-align: center;
  font-size: 22px;
  font-weight: 900;
  line-height: 49px;
  background: #588abc;
  -o-border-radius: 100%;
}

.backBtn a {
  color: #fff;
}

.weedomarket .btn-group .btn-market {
  padding: 11px 60px;
  font-size: 14px;
}

.weedomarket .btn-group .btn-simple {
  padding: 11px 30px;
}

.content.weedomarket .contentCard,
.content.weedomarket .contentCard p {
  font-family: "Fira Sans Condensed", sans-serif !important;
}

/* Mobile styles responsive */

@media (max-width: 1300px) {
  .listClient .carte {
    min-height: 400px;
  }

  .listClient .carte .btn {
    font-size: 12px;
  }
  
  .listClient .carte .parg {
    font-size: 10px;
  }

  .numbers img {
    width: 150px;
    height: 150px;
  }
}

@media (max-width: 991.98px) {
  .backBtn {
    position: relative;
    width: 50px;
    padding: 0;
    border: 0;
    font-size: 0;
  }

  .backBtn .fa-chevron-left {
    position: relative;
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 40px;
  }

  .listClient .carte {
    min-height: auto;
  }
}

@media (max-width: 767.98px) {
  .content.weedomarket .card-body.contentCard .box__offre {
    padding: 30px 0;
    font-size: 21px;
  }

  .content.weedomarket,
  .content.weedomarket p,
  .listClient .carte .btn {
    font-size: 14px;
  }

  .content.weedomarket p {
    font-size: 16px;
    line-height: 25px;
  }

  .content.weedomarket li::before {
    top: 8px;
    width: 10px;
    height: 10px;
  }

  .numbers img {
    height: auto;
    max-width: 120px;
  }

  .content.weedomarket .card-body.contentCard.vertP .box__offre {
    padding: 25px 0;
    font-size: 30px;
  }
}

@media (max-width: 575.98px) {
  .content.weedomarket .card.card-stats {
    max-width: 100%;
  }

  .card-body {
    padding: 5px !important;
  }

  .content.weedomarket .card-title {
    font-size: 18px;
  }

  .content.weedomarket p,
  .content.weedomarket .card-body.contentCard .box__offre {
    font-size: 14px;
  }

  .content.weedomarket .card-body.contentCard .box__offre,
  .content.weedomarket .card-body.contentCard.vertP .box__offre {
    padding: 15px 0;
  }

  .content.weedomarket .card-body.contentCard .box__offre,
  .content.weedomarket .card-body.contentCard.vertP .box__offre,
  .box_offrepartenaire span {
    line-height: normal;
  }

  .content.weedomarket .card-body.contentCard .box__offre span,
  .content.weedomarket .card-body.contentCard.vertP .box__offre {
    font-size: 21px;
  }

  .weedomarket .btn-group .btn-market,
  .weedomarket .btn-group .btn-simple {
    padding: 10px;
    font-size: 12px;
  }
}

@media only screen and (max-width: 400px) {
  .list_besoins ul {
    padding-left: 10px;
  }
}
</style>