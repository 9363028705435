<template>
  <div class="content">
    <h3>MES TARIFS</h3>
    <card>
      <el-table :data="produitPackTarifAnnonceur" row-class-name="UpperCase" v-loading="$apollo.queries.produitPackTarifAnnonceur.loading">
        <el-table-column
          prop='designation'
          label='Produit / Pack'
          minWidth='300'
          sortable
          show-overflow-tooltip
        >
          <template scope="scope">
            {{ scope.row.designation.replace(/Ã©/g,'é') }}
            <template v-if="scope.row.designation.replace(/Ã©/g,'é') === 'Assurance Emprunteur'">
              <span slot="header" class="badge-pill badge-dangerDark" style="text-transform: uppercase;font-weight: bold;">
                NOUVEAU
              </span>
            </template>
          </template>
        </el-table-column>
        <el-table-column
          prop='exclusif'
          label=''
          minWidth='130'
          label-class-name="gold"
        >
          <template scope="scope">
            {{ scope.row.exclusif + " € HT" }}
          </template>
        </el-table-column>
        <el-table-column
          prop='duo'
          label=''
          minWidth='130'
          label-class-name="silver"
        >
          <template scope="scope">
            {{ scope.row.duo + " € HT" }}
          </template>
        </el-table-column>
        <el-table-column
          prop='trio'
          label=''
          minWidth='130'
          label-class-name="bronze"
        >
          <template scope="scope">
            {{ scope.row.trio + " € HT" }}
          </template>
        </el-table-column>
      </el-table>
    </card>
  </div>
</template>

<script>
import { Table, TableColumn } from 'element-ui';
import gql from "graphql-tag";

export default {
  apollo: {
    produitPackTarifAnnonceur: {
      client: 'MARKET',
      query: require("src/graphql/leadmarket/Annonceur/produitPackTarifAnnonceur.gql"),
      result({ data, loader, networkStatus }) {
        let pos = data.produitPackTarifAnnonceurV2[0].tarifs.findIndex(x => x.designation === "Assurance Emprunteur");
        data.produitPackTarifAnnonceurV2[0].tarifs.splice(0,0,data.produitPackTarifAnnonceurV2[0].tarifs.splice(pos,1)[0])
        let find = data.produitPackTarifAnnonceurV2[0].tarifs.find(x => x.designation === "Assurance Santé Individuelle");
        if (find) {
          find.designation = "Assurance Santé Individuelle senior"
        }
        // add salarie
        let find2 = data.produitPackTarifAnnonceurV2[0].tarifs.find(x => x.designation === "Assurance Santé Individuelle salarié");
        if (!find2) {
          let salarie = {
            designation: "Assurance Santé Individuelle salarié",
            exclusif: 8,
            duo: 6,
            trio: 3
          }
          data.produitPackTarifAnnonceurV2[0].tarifs.push(salarie)
        }
      },
      fetchPolicy: 'network-only',
      update: data => data.produitPackTarifAnnonceurV2[0].tarifs
    }
  },
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  }
}
</script>