<template>
    <div class="content">
      <div class="row mt-5">
        <div class="col-md-12">
          <h3>MON SUIVI QUALITÉ</h3>
          <div class="card">
            <div class="card-body">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-xl-10 col-lg-12 col-12 order-xl-1 order-1">
                    <div class="row">
                      <base-input label="Produit" class="col-md-4 mb-2">
                        <el-select filterable class="select-primary"
                          clearable
                          placeholder="CHOISISSEZ"
                          v-model="ProductSelects.Product">
                          <el-option 
                            v-for="option in ProductSelects.ListProduct"
                            class="select-primary"
                            :value="option.pk_categorie"
                            :label="option.designation.replace(/Ã©/g,'é').toUpperCase()"
                            :key="option.id">
                          </el-option>
                        </el-select>
                      </base-input>
                      <base-input label="Pack" class="col-md-4 mb-2">
                        <el-select filterable class="select-primary"
                          clearable
                          placeholder="CHOISISSEZ"
                          v-model="PackSelects.Pack">
                          <el-option 
                            v-for="option in PackSelects.ListPack"
                            class="select-primary"
                            :value="option.value"
                            :label="option.label"
                            :key="option.id">
                          </el-option>
                        </el-select>
                      </base-input>
                      <base-input label="Facturation" class="col-md-4 mb-2">
                        <el-select filterable class="select-primary"
                          clearable
                          placeholder="CHOISISSEZ"
                          v-model="FabricationSelects.Fabrication">
                          <el-option 
                            v-for="option in FabricationSelects.ListFabrication"
                            class="select-primary"
                            :value="option.value"
                            :label="option.label"
                            :key="option.id">
                          </el-option>
                        </el-select>
                      </base-input>
                      
                    </div>
                  </div>
                  <div class="col-xl-2 col-lg-12 col-12 order-xl-2 order-3">
                    <div class="row">
                      <base-button 
                        v-if="$apollo.queries.MyLeads.loading" 
                        loading 
                        class="btn btn-just-icon btn-default mt-4 d-block" 
                      >{{ $t("buttons.BtnLoad").toUpperCase() }}...</base-button>
                      <button 
                        v-else
                        class="btn btn-just-icon btn-default mt-4 d-block" 
                        @click="search"
                      >{{ $t("buttons.BtnSearch").toUpperCase() }}</button>
                    </div>
                  </div>
                  <div class="col-xl-10 col-lg-12 col-12 order-xl-1 order-2">
                    <div class="row">
                      <base-input label="Statut" class="col-md-4 mb-2">
                      <el-select filterable class="select-primary"
                        clearable
                        placeholder="CHOISISSEZ"
                        v-model="StatutSelects.Statut">
                        <el-option 
                          v-for="option in StatutSelects.ListStatut"
                          class="select-primary"
                          :value="option.value"
                          :label="option.label"
                          :key="option.id">
                        </el-option>
                      </el-select>
                    </base-input>
                    <base-input class="col-md-4 mb-2" label="Date début">
                      <el-date-picker v-model="StartDate" value-format="yyyy-MM-dd" type="date" placeholder="YYYY-MM-DD"></el-date-picker>
                    </base-input>
                    <base-input class="col-md-4 mb-2" label="Date fin">
                      <el-date-picker v-model="EndnDate" value-format="yyyy-MM-dd" type="date" placeholder="YYYY-MM-DD"></el-date-picker>
                    </base-input>
                    </div>
                  </div>
                  <div class=" col-lg-12 col-12 order-3">
                    <div class="row">
                      <button class="btn btn-market" @click="DownloadCSV">
                        <i class="tim-icons icon-cloud-download-93"></i> EXPORT CSV
                      </button>
                      &nbsp;
                      <base-checkbox inline class="mb-2 col-sm-4" v-model="With_fields">
                        avec les champs du produit
                    </base-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> 
          <div class="card">
            <div class="card-body">
              <div>
                <div class="row">
                  <base-alert class="col-12" type="market">
                    <center>
                      <p style="color: white;">
                        PENSEZ À VÉRIFIER EN 1 CLIC LES ANNUAIRES EN 
                        LIGNE POUR NE PAS PASSER À CÔTÉ D'UN POTENTIEL CLIENT
                      </p>
                    </center>
                  </base-alert>
                </div>
                <div class="row">
                  <div class="col-xl-1 col-sm-6">
                    <base-input>
                      <el-select
                        class="select-primary pagination-select"
                        v-model="pagination.perPage"
                        placeholder="Per page"
                      >
                        <el-option
                          class="select-primary"
                          v-for="item in pagination.perPageOptions"
                          :key="item"
                          :label="item"
                          :value="item"
                        >
                        </el-option>
                      </el-select>
                    </base-input>
                  </div>
                  <div class="col-xl-8 col-sm-6">
                    <label style="margin-top: 10px; font-size: 0.75rem">{{ $t("labels.Datatable.NbrLinePerPage").toUpperCase() }}</label>
                  </div>
                  <base-input class="col-xl-3 col-sm-6">
                    <el-input
                      type="search"
                      class="mb-3 search-input"
                      clearable
                      prefix-icon="el-icon-search"
                      placeholder="Rechercher"
                      aria-controls="datatables"
                      v-model="searchQuery"
                    >
                    </el-input>
                  </base-input>
                </div>
                <!-- hide/show colonne -->
                <base-dropdown menu-classes="dropdown-black">
                  <template slot="title-container">
                    <button class="btn btn-icon btn-round btn-market">
                      <i class="tim-icons el-icon-view"></i>
                    </button>
                  </template>
                    <template v-for="row in rows">
                      <div class="dropdown-header">
                        <base-checkbox v-model="row.show">
                          {{ row.label }}
                        </base-checkbox>
                      </div>
                    </template>
                </base-dropdown>
                <!-- hide/show colonne -->
                <el-table :data="filterlistMylead" v-loading="$apollo.queries.MyLeads.loading" class="customtable_leads">
                  <el-table-column
                    prop='date_livraison'
                    label='Date'
                    minWidth='120'
                    show-overflow-tooltip
                    sortable
                    v-if="rows[0].show"
                  >
                  </el-table-column>
                  <el-table-column
                    prop='pk_bon_commande'
                    label="N° commande"
                    minWidth='150'
                    show-overflow-tooltip
                    sortable
                    v-if="rows[1].show"
                  >
                  </el-table-column>
                  <el-table-column
                    label='Ref'
                    prop='pk_lead'
                    minWidth='80'
                    show-overflow-tooltip
                    sortable
                    v-if="rows[2].show"
                  >
                  </el-table-column>
                  <el-table-column
                    prop='categorie'
                    label='Produit'
                    minWidth='115'
                    show-overflow-tooltip
                    sortable
                    v-if="rows[3].show"
                  >
                    <template scope="scope">
                      {{ scope.row.categorie.replace(/Ã©/g,'é').toUpperCase() }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="Gamme"
                    prop='pack'
                    min-width="105"
                    show-overflow-tooltip
                    sortable
                    v-if="rows[4].show"
                  >
                    <template scope='scope'>
                      {{ scope.row.pack.toUpperCase() }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="Nom/Prénom"
                    prop='nom'
                    min-width="145"
                    show-overflow-tooltip
                    sortable
                    v-if="rows[5].show"
                  >
                    <template scope='scope'>
                      {{ scope.row.nom.toUpperCase() + ' ' + scope.row.prenom.toUpperCase() }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="Téléphone"
                    prop="telephone"
                    min-width="127"
                    show-overflow-tooltip
                    sortable
                    v-if="rows[6].show"
                  >
                  </el-table-column>
                  <el-table-column
                    label="Code postal"
                    prop="cp"
                    min-width="118"
                    show-overflow-tooltip
                    v-if="rows[7].show"
                  >
                  </el-table-column>
                  <el-table-column
                    label="Email"
                    prop="email"
                    min-width="120"
                    show-overflow-tooltip
                    sortable
                    v-if="rows[8].show"
                  >
                  </el-table-column>
                  <el-table-column
                    label="Facturation"
                    prop="facturation"
                    min-width="120"
                    show-overflow-tooltip
                    v-if="rows[9].show"
                  >
                    <template scope='scope'>
                      {{ scope.row.facturation.toUpperCase() }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="Consulter l'annuaire"
                    min-width="180"
                    v-if="rows[10].show"
                  >
                    <template scope="scope">
                      <a target="_blank" :href="'http://www.118218.fr/recherche?geo_id=&distance=&category=&who=' + encode_utf8(scope.row.nom) +'&where=' + encode_utf8(scope.row.ville)">
                        <input type="button" class="btn btn-theme btn-sm" value="118 218 ">
                      </a>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="Statut du lead"
                    prop="statut"
                    min-width="135"
                    show-overflow-tooltip
                    v-if="rows[11].show"
                  >
                    <template scope="scope">
                      <template v-if="scope.row.statut == 'ok' || scope.row.statut == 'Ok'">
                        VALIDE
                      </template>
                      <template v-else-if="scope.row.statut == 'devalide'">
                        DÉVALIDATION ACCEPTÉE
                      </template>
                      <template v-else-if="scope.row.statut == 'en cours de verification'">
                        EN COURS DE TRAITEMENT
                      </template>
                      <template v-else-if="scope.row.statut == 'demande refusee'">
                        DÉVALIDATION REFUSÉE
                      </template>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="Demande de dévalidation"
                    :render-header="renderHeaderMethod"
                    min-width="250"
                    v-if="rows[12].show"
                  >
                    <template scope="scope">
                      <base-input>
                        <el-select class="select-primary"
                          :disabled='checkDevalidation(scope.row)'
                          filterable
                          @change="StatChanged(scope.row)"
                          v-model="scope.row.motif">
                          <el-option 
                            class="select-primary"
                            value=""
                            label="-- CHOISIR --">
                          </el-option>
                          <el-option 
                            class="select-primary"
                            value="aucune demande"
                            label="N'A FAIT AUCUNE DEMANDE">
                          </el-option>
                          <el-option 
                            class="select-primary"
                            value="ne reponds pas"
                            label="NE RÉPOND PAS">
                          </el-option>
                          <el-option 
                            class="select-primary"
                            value="faux numero de telephone"
                            label="FAUX NUMÉRO DE TÉLÉPHONE">
                          </el-option>
                          <el-option 
                            class="select-primary"
                            value="numero non attribue"
                            label="NUMÉRO NON ATTRIBUÉ">
                          </el-option>
                        </el-select>
                      </base-input>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <div
                slot="footer"
                class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <div class="">
                  <p class="card-category">
                    {{ $t('labels.Datatable.Showing') }} {{ from + 1 }}  {{ $t('labels.Datatable.to') }} {{ to }} {{ $t('labels.Datatable.of') }} {{ total }} {{ $t('labels.Datatable.entries') }}
                  </p>
                </div>
                <base-pagination
                  type="market"
                  class="pagination-no-border"
                  v-model="pagination.currentPage"
                  :per-page="pagination.perPage"
                  :total="total"
                >
                </base-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import {Select, Option ,DatePicker} from 'element-ui'
  import { Table, TableColumn } from 'element-ui';
  import { BasePagination, BaseCheckbox, BaseDropdown, BaseAlert } from 'src/components';
  import gql from 'graphql-tag'
  
  let today = new Date()

  export default {
    apollo: {
      MyLeads: {
        client: 'MARKET',
        query: require("src/graphql/leadmarket/Annonceur/MyLeads.gql"),
        variables () {
          return {
            id_utilisateur: JSON.parse(localStorage.getItem('utilisateur')).id,
            date_debut: this.StartDate,
            date_fin: this.EndnDate,
            produit: this.ProductSelects.Product, 
            statut: this.StatutSelects.Statut, 
            pack: this.PackSelects.Pack, 
            facturation: this.FabricationSelects.Fabrication
          }
        },
        skip() {
          return this.SkipQuery
        },
        result({ data, loader, networkStatus }) {
          this.SkipQuery = true
        },
        fetchPolicy: 'network-only',
        update: data => data.leadsAnnonceur[0].leads,
      },
      produits :
      { 
        client: 'MARKET',
        query: gql` query {
          produits {
            pk_categorie
            designation
          }
        }`,
        result({ data, loader, networkStatus }) {
          this.ProductSelects.ListProduct = data.produits
        }
      },
    },
    components: {
      BasePagination,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Select.name]: Select,
      [Option.name]: Option,
      [DatePicker.name]: DatePicker,
      BaseCheckbox,
      BaseDropdown,
      BaseAlert
    },
    data() {
      return {
        With_fields: false,
        SkipQuery: true,
        searchQuery: '',
        total: 0,
        StartDate: today.getFullYear()+"-"+(today.getMonth() + 1)+"-"+today.getDate(),
        EndnDate: today.getFullYear()+"-"+(today.getMonth() + 1)+"-"+today.getDate(),
        ProductSelects: {
          Product: '',
          ListProduct: []
        },
        PackSelects: {
          Pack: '',
          ListPack: [
            {value: '', label:'TOUS LES PACKS'},
            {value: 'exclusif', label:'EXCLUSIF'},
            {value: 'duo', label:'DUO'},
            {value: 'trio', label:'TRIO'},
            {value: 'destockage', label:'DESTOCKAGE'}
          ]
        },
        FabricationSelects: {
          Fabrication: '',
          ListFabrication: [
            {value: 'facturable', label: 'FACTURABLE'},
            {value: 'non facturable', label: 'NON FACTURABLE'}
          ]
        },
        StatutSelects: {
          Statut: '',
          ListStatut: [
            {value: '', label:'TOUS LES STATUTS'},
            {value: 'ok', label:'VALIDÉ'},
            {value: 'en cours de verification', label:'EN COURS DE TRAITEMENT'},
            {value: 'devalide', label:'DÉVALIDÉ'},
            {value: 'demande refusee', label:'DEMANDE REFUSÉE'}
          ]
        },
        pagination: {
          perPage: 50,
          currentPage: 1,
          perPageOptions: [10, 25, 50, 100],
          total: 0
        },
        tableColumns: [],
        MyLeads: [],
        rows: [
          { label: 'Date', show: true },
          { label: 'N° commande', show: false },
          { label: 'Ref', show: false },
          { label: 'Produit', show: true },
          { label: 'Gamme', show: false },
          { label: 'Nom/Prénom', show: true },
          { label: 'Téléphone', show: true },
          { label: 'Code postal', show: true },
          { label: 'Email', show: true },
          { label: 'Facturation', show: true },
          { label: 'Consulter l\'annuaire', show: true },
          { label: 'Statut du lead', show: true },
          { label: 'Demande de dévalidation', show: true },
        ],
        isAdmin: localStorage.getItem('user_origine_id') != null
      }
    },
    computed: {
      filterlistMylead() {
        var list = []
        try {
          list = this.MyLeads.filter((lead) => {            
            return lead.date_livraison.match(this.searchQuery) ||
                   (lead.pk_bon_commande + '').match(this.searchQuery)  ||
                   (lead.pk_lead + '').match(this.searchQuery) ||
                   (lead.categorie+"").toLocaleLowerCase().match((this.searchQuery+"").toLocaleLowerCase()) ||
                   (lead.pack+"").toLocaleLowerCase().match((this.searchQuery+"").toLocaleLowerCase()) ||
                   ((lead.nom+"")+" "+(lead.prenom+"")).toLocaleLowerCase().match((this.searchQuery+"").toLocaleLowerCase()) ||
                   (lead.telephone + '').match(this.searchQuery) ||
                   lead.cp.match(this.searchQuery) ||
                   (lead.email+"").toLocaleLowerCase().match((this.searchQuery+"").toLocaleLowerCase())
          })
          this.total = list.length
          return list.slice(this.from, this.to);
        } catch (error) {
          console.log(error)
          return null
        }
      },
      to() {
        let highBound = this.from + this.pagination.perPage;
        if (this.total < highBound) {
          highBound = this.total;
        }
        return highBound;
      },
      from() {
        return this.pagination.perPage * (this.pagination.currentPage - 1);
      }
    },
    methods: {
      diffDate(date2,date1) {
        var Difference_In_Time = date2.getTime() - date1.getTime();
        return Difference_In_Time / (1000 * 3600 * 24); 
      },
      renderHeaderMethod(h, { column, $index }) {

        return h("div", [
          h("span", column.label + "  ", {
            align: "left"
          }),
          h(
            "el-popover",
            {
              props: {
                placement: "top-start",
                width: "300",
                trigger: "hover"
              }
            },
            [
              h("label", "Vous avez 5 jours à partir de la date de livraison pour notifier un motif de dévalidation qui sera contrôlé pour nos services, en cas de motif légitime, le montant du lead sera crédité sur votre solde", {
              }),
              h("i", {
                class: "far fa-question-circle",
                style: "color:#588bbd;font-size: 18px !important",
                slot: "reference"
              })
            ]
          )
        ]);
      },
      search() {
        this.SkipQuery = false
        this.MyLeads = []
      },
      encode_utf8(s){
        return unescape( encodeURIComponent( s ) );
      },
      async StatChanged(row) {
        await this.$apollo.mutate({
          client: "MARKET",
          mutation: require("src/graphql/leadmarket/Annonceur/mutation/devaliderLead.gql"),
          variables: {
            id_utilisateur: JSON.parse(localStorage.getItem('utilisateur')).id,
            ref: row.pk_bon_commande,
            motif: row.motif
          }
        }).then(response => {
          if (response.data.devaliderLead.state === 'OK') {
            this.$notify({type: 'success', message: this.$t("apiresponse.Stateupdated") })
          } else if (response.data.devaliderLead.state === 'depasse10') {
            this.$notify({type: 'success', message: "Le nombre de dévalidations maximum a été atteint sur votre commande N° " + row.pk_bon_commande })
          } else if (response.data.devaliderLead.state === '') {
            this.$notify({type: 'warning', message: this.$t("apiresponse.errorupdate") })
          }

        }).catch(error => {
          this.$notify({type: 'warning', message: this.$t("apiresponse.errorupdate") })
        })
      },
      DownloadCSV() {
        if (this.With_fields) {
          if (!this.ProductSelects.Product)  {
            this.$notify({type: 'warning', message: "Veuillez selectionner un produit pour l' extract" })
            return
          }
        }
        const url_api = "https://apileadmarket.wee-do-it.net/api/export-Leads-csv";
        let accessToken = localStorage.getItem("token_jwt");
        let formData = new FormData()
        formData.append('id_utilisateur', JSON.parse(localStorage.getItem('utilisateur')).id)
        formData.append('produit', this.ProductSelects.Product)
        formData.append('statut', this.StatutSelects.Statut)
        formData.append('pack', this.PackSelects.Pack)
        formData.append('facturation', this.FabricationSelects.Fabrication)
        formData.append('date_debut', this.StartDate)
        formData.append('date_fin', this.EndnDate)
        formData.append('with_tag', false)
        formData.append('with_fields', this.With_fields) // herre
        formData.append('export_validate', true)
        formData.append('with_date_collecte', false)

        this.axios
          .post(url_api,formData , {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded',authorization: `Bearer ${accessToken}` },
            responseType: "blob"
          }).then(function(response) {
            console.log(response.data)
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'extract.csv');
            document.body.appendChild(link);
            link.click();
          }.bind(this))
          .catch(function (error) {
            console.log(error);
          })
      },
      checkDevalidation(row) {
        if (this.isAdmin) {
          return false
        }
        if ((this.diffDate(new Date(),new Date(row.date_livraison)) > 5 || row.destockage === 1)) {
          return true
        }
        return false
      }
    },
    mounted() {
      this.SkipQuery = false
    },
  }
  </script>

  <style>
    .UpperCase {
      text-transform: uppercase;
    }
    .customtable_leads .cell{
      word-break: break-word;
    }
  </style>