<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-4 col-md-6">
        <stats-card
          :title="(getLeadOfDayBefore!=null) ? (getLeadOfDayBefore.leads).toLocaleString() : ''"
          :loading='getLeadOfDayBefore==null'
          sub-title="LEADS DE LA VEILLE"
          :subTitle_color='true'
          type="market"
          icon="tim-icons icon-coins"
        >
        </stats-card>
      </div>
      <div class="col-lg-4 col-md-6">
        <stats-card
          :title="(getLeadOfDay!=null) ? (getLeadOfDay.leads).toLocaleString() : ''"
          :loading='getLeadOfDay==null'
          sub-title="LEADS DU JOUR"
          :subTitle_color='true'
          type="market"
          icon="tim-icons icon-coins"
        >
        </stats-card>
      </div>
      <div class="col-lg-4 col-md-6">
        <stats-card
          :title="(getLeadOfMonth!=null) ? (getLeadOfMonth.leads).toLocaleString() : ''"
          :loading='getLeadOfMonth==null'
          sub-title="LEADS DU MOIS"
          :subTitle_color='true'
          type="market"
          icon="tim-icons icon-coins"
        >
        </stats-card>
      </div>
    </div>
    <card>
    <div class="row">
      <base-input label="PERIODE" class="col-md-4">
        <el-select filterable
          class="select-primary"
          placeholder="Choisissez"
          v-model="PeriodeSelect.Periode">

          <el-option
            v-for="option in PeriodeSelect.ListPeriode"
            class="select-primary"
            :value="option.mois+ '-' + option.annee"
            :label="Mounth[option.mois-1]+ ' ' + option.annee"
            :key="option.id">
          </el-option>

        </el-select>
      </base-input>
      <transition name="fade" appear>
        <WhatsappPoPup/>
      </transition>
    </div>
    <div class="row">
      <template v-for="campagne in tableauDeBordAnnonceur">
      <div class="col-md-4" v-if="campagne.categorie != 'Protection juridique' && campagne.categorie != 'DÃ©fiscalisation'">
        <card class="card-chart card-chart-pie">
          <div class="UpperCase whitcontent">{{ campagne.categorie.replace(/Ã©/g,'é') }}</div>
          <center v-if="$apollo.queries.tableauDeBord.loading">
            <base-button
              loading 
              class="btn btn-just-icon btn-default mt-4 d-block" 
            ></base-button>
          </center>
          <template v-else>
            <div class="row">
              <div class="col-md-6 row" style="text-align:center; color:#fff; background:#588bbd; border-radius:10px">
                <div class="col-xl-2 col-md-3">&nbsp;
                  <i :class="icons[campagne.categorie.replace(/Ã©/g,'é')]" style=" font-size: 24px;"></i>
                </div>
                <div class="col-md-9"> A partir de<br> 
                  <span style="font-size:18px">{{ campagne.cpl_base }}€ HT</span>
                </div>
              </div>
                <div class="col-md-6">
                  <el-tooltip v-if="campagne.reste == 0" content="CRÉER UNE COMMANDE" effect="light" :open-delay="300" placement="top">
                    <button :disabled="!userCanCmdWeedoMarket" class="btn btn-warning pull-right" @click="$router.push({name: 'cree Commandes Leadmarket Annonceur'})"><i class="fas fa-shopping-basket"></i></button>
                  </el-tooltip>
                  <el-tooltip v-else content="AUGMENTER LE CAPPING" effect="light" :open-delay="300" placement="top">
                    <button class="btn btn-warning pull-right" @click="$router.push({name: 'mes Commandes Leadmarket Annonceur'})"><i class="fas fa-plus"></i></button>
                  </el-tooltip>
              </div>
            </div><br>
            <div style="height: 235px" class="chart-area" v-if="campagne.leads_commandes == 0 && campagne.leads_livres== 0 && campagne.leads_cours_a_verifier== 0 && campagne.reste == 0">
              <pie-chart
                :chart-data="Defaultchartdata"
                :extra-options="pieChart2.extraOptions"
                :height="140"
              >
              </pie-chart>
            </div>

            <div class="chart-area" v-else>
              <pie-chart
                :chart-data="setdata(campagne)"
                :extra-options="pieChart.extraOptions"
                :height="200"
              >
              </pie-chart>
            </div>
          </template>
        </card>
      </div>
      </template>
    </div>
    </card>
    <div class="row">
      <card>
        <h4 slot="header" class="card-title">TARIFS ACTUELS DE NOS PRODUITS</h4>
        <el-table :data="produitPackTarifAnnonceur" row-class-name="UpperCase" v-loading="$apollo.queries.produitPackTarifAnnonceur.loading">
          <el-table-column
            prop='designation'
            label='Produit / Pack'
            minWidth='300'
            sortable
            show-overflow-tooltip
          >
            <template scope="scope">
              {{ scope.row.designation.replace(/Ã©/g,'é') }}
              <template v-if="scope.row.designation.replace(/Ã©/g,'é') === 'Assurance Emprunteur'">
                <span slot="header" class="badge-pill badge-dangerDark" style="text-transform: uppercase;font-weight: bold;">
                  NOUVEAU
                </span>
              </template>
            </template>
          </el-table-column>
          <el-table-column
            prop='exclusif'
            label=''
            minWidth='130'
            label-class-name="gold"
          >
            <template scope="scope">
              {{ scope.row.exclusif + " € HT" }}
            </template>
          </el-table-column>
          <el-table-column
            prop='duo'
            label=''
            minWidth='130'
            label-class-name="silver"
          >
            <template scope="scope">
              {{ scope.row.duo + " € HT" }}
            </template>
          </el-table-column>
          <el-table-column
            prop='trio'
            label=''
            minWidth='130'
            label-class-name="bronze"
          >
            <template scope="scope">
              {{ scope.row.trio + " € HT" }}
            </template>
          </el-table-column>
        </el-table>
      </card>
    </div>
  </div>
</template>

<script>
import StatsCard from 'src/components/Cards/StatsCard';
import PieChart from 'src/components/Charts/PieChart';
import * as chartConfigs from '@/components/Charts/config';
import { Table, TableColumn, Select, Option } from 'element-ui';
import gql from "graphql-tag";
import { Icons } from "src/pages/Pages/MyMarketAdmin/Product_Icons";
import WhatsappPoPup from './pop-up_Whatsapp.vue'

export default {
  apollo: {
    userCanCmdWeedoMarket: {
      client: 'AUTH',
      query: require("src/graphql/leadmarket/Annonceur/userCanCmdWeedoMarket.gql"),
      fetchPolicy: 'network-only',
      update: data => data.userCanCmdWeedoMarket
    },
    getLeadOfDay: {
      client: 'MARKET',
      query: require("src/graphql/leadmarket/Annonceur/GlobalLeadsAnnonceur.gql"),
      variables () {
        return {
          dateType: 'd',
          id_utilisateur: JSON.parse(localStorage.getItem('utilisateur')).id
        }
      },
      update: data => data.getCollectLeadsNetsAnnonceurByPeriode,
    },
    getLeadOfDayBefore: {
      client: 'MARKET',
      query: require("src/graphql/leadmarket/Annonceur/GlobalLeadsAnnonceur.gql"),
      variables () {
        return {
          dateType: 'd-1',
          id_utilisateur: JSON.parse(localStorage.getItem('utilisateur')).id
        }
      },
      update: data => data.getCollectLeadsNetsAnnonceurByPeriode,
    },
    getLeadOfMonth: {
      client: 'MARKET',
      query: require("src/graphql/leadmarket/Annonceur/GlobalLeadsAnnonceur.gql"),
      variables () {
        return {
          dateType: 'm',
          id_utilisateur: JSON.parse(localStorage.getItem('utilisateur')).id
        }
      },
      update: data => data.getCollectLeadsNetsAnnonceurByPeriode,
    },
    produitPackTarifAnnonceur: {
      client: 'MARKET',
      query: require("src/graphql/leadmarket/Annonceur/produitPackTarifAnnonceur.gql"),
      result({ data, loader, networkStatus }) {
        let pos = data.produitPackTarifAnnonceurV2[0].tarifs.findIndex(x => x.designation === "Assurance Emprunteur");
        data.produitPackTarifAnnonceurV2[0].tarifs.splice(0,0,data.produitPackTarifAnnonceurV2[0].tarifs.splice(pos,1)[0])
        let find = data.produitPackTarifAnnonceurV2[0].tarifs.find(x => x.designation === "Assurance Santé Individuelle");
        if (find) {
          find.designation = "Assurance Santé Individuelle senior"
        }
        // add salarie
        let find2 = data.produitPackTarifAnnonceurV2[0].tarifs.find(x => x.designation === "Assurance Santé Individuelle salarié");
        if (!find2) {
          let salarie = {
            designation: "Assurance Santé Individuelle salarié",
            exclusif: 8,
            duo: 6,
            trio: 3
          }
          data.produitPackTarifAnnonceurV2[0].tarifs.push(salarie)
        }
      },
      fetchPolicy: 'network-only',
      update: data => data.produitPackTarifAnnonceurV2[0].tarifs
    },
    tableauDeBord: {
      client: 'MARKET',
      query: require("src/graphql/leadmarket/Annonceur/tableauDeBordAnnonceur.gql"),
      variables () {
        return {
          periode: this.PeriodeSelect.Periode,
          id_utilisateur: JSON.parse(localStorage.getItem('utilisateur')).id
        }
      },
      result({ data, loader, networkStatus }) {
        this.tableauDeBordAnnonceur = data.dashboardAnnonceur[0].resumes.sort(this.CustomSort)
        
        let pos = this.tableauDeBordAnnonceur.findIndex(x => x.categorie === "Assurance Emprunteur");
        this.tableauDeBordAnnonceur.splice(5,0,this.tableauDeBordAnnonceur.splice(pos,1)[0])

        this.PeriodeSelect.ListPeriode = data.dashboardAnnonceur[0].periodes
        
      },
      update: data => data.dashboardAnnonceur
    }
  },
  components: {
    StatsCard,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    PieChart,
    [Select.name]: Select,
    [Option.name]: Option,
    WhatsappPoPup
  },
  data(){
    return {
      ShowWhatsappPopup: false,
      userCanCmdWeedoMarket: false,
      icons: Icons,
      Mounth: [
        "JANVIER",
        "FÉVRIER",
        "MARS",
        "AVRIL",
        "MAI",
        "JUIN",
        "JUILLET",
        "AOUT",
        "SEPTEMBRE",
        "OCTOBRE",
        "NOVEMBRE",
        "DÉCEMBRE"
      ],
      Defaultchartdata: {
        labels: [""],
        datasets: [
          {
            label: '',
            pointRadius: 0,
            pointHoverRadius: 0,
            backgroundColor: ['#ABABAB'],
            borderWidth: 0,
            data: [0.1]
          }
        ]
      },
      PeriodeSelect: {
        Periode: '',
        ListPeriode: []
      },
      pieChart2: {
        chartData: {},
        extraOptions: chartConfigs.pieChartOptions
      },
      pieChart: {
        chartData: {},
        extraOptions: {
          ...chartConfigs.pieChartOptions,
          legend: {
            display: true,
            position: 'bottom',
            align: 'start',
            labels: {
              boxWidth: 15,
              fontSize: 12
            }
          },
        }
      },
      tableauDeBordAnnonceur: []
    }
  },
  computed: {
    bigLineChartCategories() {
      return [{ name: 'exclusif', icon: 'tim-icons icon-single-02' }, { name: 'Shoots', icon: 'tim-icons icon-gift-2' }, { name: 'Bronze', icon: 'tim-icons icon-tap-02' }];
    }
  },
  methods: {
    closeWahtsappPopup() {
      this.ShowWhatsappPopup = false
    },
    CustomSort( a, b ) {
      if ( (a.reste + a.leads_commandes + a.leads_livres) > (b.reste + b.leads_commandes + b.leads_livres) ){
        return -1;
      }
      if ( (a.reste + a.leads_commandes + a.leads_livres) < (b.reste + b.leads_commandes + b.leads_livres) ){
        return 1;
      }
      return 0;
    },
    setdata(campagne) {
      let data = []
      var chartdata = {
        labels: ["Leads commandés", "Leads livrés", "Reste à livrer"],
        // labels: ["Leads commandés", "Leads livrés", "Reste à livrer", "Demandes de dévalidation en cours de traitement"],
        datasets: [
          {
            label: '',
            pointRadius: 0,
            pointHoverRadius: 0,
            backgroundColor: ['#04376a', '#16a0dd', '#f41d51'],
            borderWidth: 0,
            data: [campagne.leads_commandes,campagne.leads_livres,campagne.reste]
          }
        ]
      }
      return chartdata
    },
  }
};
</script>
<style>
  .gold {
    background-size: 67.5px;
    background-repeat: no-repeat;
    background-image: url(/img/lead_exclusif.png);
  }
  .silver {
    background-size: 67.5px;
    background-repeat: no-repeat;
    background-image: url(/img/lead_duo.png);
  }
  .bronze {
    background-size: 67.5px;
    background-repeat: no-repeat;
    background-image: url(/img/lead_trio.png);
  }
  .UpperCase {
    text-transform: uppercase;
  }
</style>
