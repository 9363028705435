<template>
  <div class="content">
    <div class="row mt-5">
      <div class="col-md-12">
        <h3>MES PRÉPAIEMENTS</h3>
        <h5>SOLDE ACTUEL (HT) : <template v-if="getSoldeactuel"> {{ getSoldeactuel.soldeHt + ' €'}}</template></h5>
        <h5>SOLDE ACTUEL (TTC) : <template v-if="getSoldeactuel"> {{ getSoldeactuel.soldeTtc + ' €' }}</template></h5>
        <div class="btn-group btn-group-toggle float-right mb-3" data-toggle="buttons">
          <button
            @click="showAddAmount = true"
            class="btn btn-round btn-just-icon btn-market"
          >CRÉDITER MON COMPTE</button>
        </div>
        <div class="card">
          <div class="card-body">
            <base-alert type="info" dismissible>
              Pour commander vos leads, nous vous invitons à créditer /pré-payer votre compte. Le minimum est de 1000€.<br>
              La consommation de ce crédit et l’édition des factures se fera à la commande de vos leads.<br>
              Si vous bénéficiez d’un code promo, vous devez au préalable créditer votre compte d’un montant minimum de 1000€ pour pouvoir l’utiliser.<br>
              Pour toute question, nous sommes joignables au 04 72 61 26 31 ou par e-mail à contact@leadmarket.fr<br>
            </base-alert>
            <div class="row">
              <div class="col-xl-1 col-sm-6">
                <base-input>
                  <el-select
                    class="select-primary pagination-select"
                    v-model="pagination.perPage"
                    placeholder="Per page"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-xl-8 col-sm-6">
                <label style="margin-top: 10px; font-size: 0.75rem">{{ $t("labels.Datatable.NbrLinePerPage").toUpperCase() }}</label>
              </div>
              <base-input class="col-xl-3 col-sm-6">
                <el-input
                  type="search"
                  class="mb-3 search-input"
                  clearable
                  prefix-icon="el-icon-search"
                  placeholder="Rechercher"
                  aria-controls="datatables"
                  v-model="searchQuery"
                >
                </el-input>
              </base-input>
            </div>
            <el-table :data="filterlistPrepayments" v-loading="$apollo.queries.MyPrepayments.loading">
              <el-table-column
                prop='pk_prepaiment'
                label='Ref prépaiement'
                minWidth='165'
                show-overflow-tooltip
                sortable
              >
              </el-table-column>
              <el-table-column
                prop='paymentDate'
                label='Date de prépaiement'
                minWidth='195'
                show-overflow-tooltip
                sortable
              >
              </el-table-column>
              <el-table-column
                prop='paymentAmount'
                label='Montant (HT)'
                minWidth='150'
                show-overflow-tooltip
                sortable
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.paymentAmount,true,2) + ' €'}}
                </template>
              </el-table-column>
              <el-table-column
                prop='paymentapi'
                label='Source'
                minWidth='120'
                show-overflow-tooltip
                sortable
              >
                <template scope="scope">
                  <template v-if="scope.row.paymentapi === 'code_promo'">
                    DÉVALIDATION 10 % MENSUELLE
                  </template>
                  <template v-else-if="scope.row.paymentapi === 'devalidation'">
                    DÉVALIDATION
                  </template>
                  <template v-else-if="scope.row.paymentapi === 'sogenactif'">
                    CARTE BANCAIRE
                  </template>
                </template>
              </el-table-column>
              <el-table-column
                prop='etat'
                label='Etat'
                minWidth='120'
                show-overflow-tooltip
                sortable
              >
                <template scope="scope">
                  <template v-if="scope.row.etat === 'refusee'">
                    PAIEMENT REFUSÉ
                  </template>
                  <template v-else-if="scope.row.etat === 'acceptee'">
                    PAIEMENT CONFIRMÉ
                  </template>
                  <template v-else>
                    -
                  </template>
                </template>
              </el-table-column>
              <el-table-column
                prop=''
                label='Actions'
                minWidth='90'
              >
                <template scope="scope">
                  <el-tooltip
                    content="Télécharger le reçu"
                    effect="light"
                    :open-delay="300"
                    placement="top"
                    v-if="scope.row.paymentapi === 'sogenactif' && scope.row.etat === 'acceptee'"
                  >
                    <base-button
                      class="btn-link"
                      type="success"
                      size="sm"
                      @click="DownloadPDF(scope.row.pk_prepaiment)"
                      icon
                    >
                      <i class="far fa-file-alt"></i>
                    </base-button>
                  </el-tooltip>
                </template>
              </el-table-column>
            </el-table>
            <div
              slot="footer"
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class="">
                <p class="card-category">
                  {{ $t('labels.Datatable.Showing') }} {{ from + 1 }}  {{ $t('labels.Datatable.to') }} {{ to }} {{ $t('labels.Datatable.of') }} {{ total }} {{ $t('labels.Datatable.entries') }}
                </p>
              </div>
              <base-pagination
                type="market"
                class="pagination-no-border"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="total"
              >
              </base-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- mondal credité mon compte -->
    <modal
      :show.sync="showAddAmount"
      body-classes="p-0"
      class="modal-listbase">
      <card type="secondary"
        header-classes="pb-6"
        body-classes="px-lg-6 py-lg-6"
        class="border-0 mb-0">

        <template slot="header">
          <el-tooltip
            content="Quitter"
            effect="light"
            :open-delay="300"
            placement="top"
          >
            <base-button
              @click.native="showAddAmount = false"
              class="btn-link float-right"
              type="danger"
              size="sm"
              icon
            >
              <i class="tim-icons icon-simple-remove icon-large"></i>
            </base-button>
          </el-tooltip>
        </template>

        <h4>CRÉDITER AVEC UNE CARTE BANCAIRE</h4>
        <base-table :data="[{titre:'1'}]" thead-classes="text-primary">
          <template slot-scope="{ row }">
            <td>
              <label>MONTANT </label>
            </td>
            <td>
              <form data-vv-scope="ScopeCard">
                <base-input
                  type="number"
                  v-model="Montant"
                  :error="getError('ScopeCard.montant')"
                  name="montant"
                  v-validate="'required'"
                ></base-input>
              </form>
            </td>
            <td class="td-actions text-right">
              <base-button type="market" @click="AddCreditByCard()">
                VALIDER
              </base-button>
            </td>
          </template>
        </base-table>
        <div v-html="message">
        </div>

        <hr class="my-4">

        <h4>CRÉDITER AVEC UN CODE PROMO</h4>
        <base-table :data="[{titre:'1'}]" thead-classes="text-primary">
          <template slot-scope="{ row }">
            <td>
              <label>CODE PROMO</label>
            </td>
            <td>
              <form data-vv-scope="ScopeCoupon">
                <base-input
                  v-model="Coupon"
                  :error="getError('ScopeCoupon.code promo')"
                  name="code promo"
                  v-validate="'required'"
                ></base-input>
              </form>
            </td>
            <td class="td-actions text-right">
              <base-button type="market" @click="AddCreditByCoupon()">
                VALIDER
              </base-button>
            </td>
          </template>
        </base-table>
      </card>
    </modal>
    <!-- mondal credité mon compte -->
  </div>
</template>

<script>
  import { BaseAlert, BasePagination, BaseTable } from 'src/components'
  import { Select, Option, Table, TableColumn } from 'element-ui'
  import { Modal } from 'src/components'

  export default {
    apollo: {
      MyPrepayments: {
        client: 'MARKET',
        query: require("src/graphql/leadmarket/Annonceur/MyPrepayments.gql"),
        variables () {
          return {
            id_utilisateur: JSON.parse(localStorage.getItem('utilisateur')).id
          }
        },
        result({ data, loader, networkStatus }) {
        },
        fetchPolicy: 'network-only',
        update: data => data.mesPrepaiements.prepaiements
      },
      getSoldeactuel: {
        client: 'MARKET',
        query: require("src/graphql/leadmarket/Annonceur/getSoldeactuel.gql"),
        variables () {
          return {
            id_utilisateur: JSON.parse(localStorage.getItem('utilisateur')).id
          }
        },
        fetchPolicy: 'network-only',
        update: data => data.getSoldeactuel
      }
    },
    components: {
      BaseAlert,
      [Select.name]: Select,
      [Option.name]: Option,
      BasePagination,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      Modal,
      BaseTable
    },
    computed: {
      filterlistPrepayments() {
        var list = []
        try {
          list = this.MyPrepayments.filter((pay) => {
            return (pay.pk_prepaiment+"").match(this.searchQuery) ||
                    pay.paymentDate.match(this.searchQuery) ||
                    pay.etat.match(this.searchQuery) ||
                    pay.paymentapi.match(this.searchQuery) ||
                    (pay.paymentAmount+"").match(this.searchQuery)
          })
          this.total = list.length
          return list.slice(this.from, this.to);
        } catch (error) {
          console.log(error)
          return null
        }
      },
      to() {
        let highBound = this.from + this.pagination.perPage;
        if (this.total < highBound) {
          highBound = this.total;
        }
        return highBound;
      },
      from() {
        return this.pagination.perPage * (this.pagination.currentPage - 1);
      }
    },
    data() {
      return {
        searchQuery: '',
        total: 0,
        pagination: {
          perPage: 50,
          currentPage: 1,
          perPageOptions: [10, 25, 50, 100],
          total: 0
        },
        tableColumns: [],
        MyPrepayments: [],
        showAddAmount: false,
        Montant: null,
        Coupon: null,
        message: ''
      }
    },
    created() {
      if(this.$route.query.response === 'paiement_canceled') {
        this.$notify({type: 'warning', message: "Paiement annulé" })
      }
      else if (this.$route.query.response === 'paiement_completed') {
        this.$notify({type: 'success', message: "Paiement terminé" })
      }
    },
    methods: {
      DownloadPDF(ref) {
        const url_api = "https://apileadmarket.wee-do-it.net/api/generate-prepaiement-recu";
          let accessToken = localStorage.getItem("token_jwt");
          let formData = new FormData()
          formData.append('id_prepaiement', ref)
          formData.append('id_utilisateur', JSON.parse(localStorage.getItem('utilisateur')).id)
          this.axios.post(url_api,formData , {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                authorization: `Bearer ${accessToken}`
              },
              responseType: "blob"
            }).then(function(response) {

              const filename = "reçu-" + ref +".pdf";
              var fileURL = window.URL.createObjectURL(new Blob([response.data]));
              var fileLink = document.createElement("a");
              fileLink.href = fileURL;
              fileLink.setAttribute("download", filename);
              document.body.appendChild(fileLink);
              fileLink.click();

            }.bind(this))
            .catch(function (error) {
              console.log(error.message);
            })
      },
      formatThounsends: function(value, fixe = false, number = 0) {
        if (fixe) {
          value = value.toFixed(number);
        }
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      },
      async AddCreditByCard() {
        let validate
        await this.$validator.validateAll("ScopeCard").then(isValid => {
          validate = isValid
        })
        if (!validate)
          return

        let formData = new FormData()

        formData.append("action", "leadmarket_prepaiement")
        formData.append("amount", this.Montant)
        formData.append("utilisateur_id", JSON.parse(localStorage.getItem('utilisateur')).id)

        const url_api = 'https://api-auth-plf.wee-do-it.net/api/v1/paymentgetway'
        let accessToken = localStorage.getItem("token_jwt")
        this.axios.post(url_api, formData,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'authorization': `Bearer ${accessToken}`
          }
        })
        .then(response => {
          if(response.data){
            this.message = response.data.message
          }
        })
        .catch(() => {
          console.log('error occured')
        })

      },
      async AddCreditByCoupon() {
        let validate
        await this.$validator.validateAll("ScopeCoupon").then(isValid => {
          validate = isValid
        })
        if (!validate)
          return

        if (localStorage.getItem("compteur") === null) {
          localStorage.setItem("compteur", 0)
        }

        if (parseInt(localStorage.getItem("compteur")) > 5) {
          this.$notify({type: 'warning', message: "Vous avez depassé le nombre de tentatives d'utilisation de codes promo" })
          this.showAddAmount = false
          return
        }
        await this.$apollo.mutate({
          client: 'MARKET',
          mutation: require("src/graphql/leadmarket/Annonceur/mutation/prepaiementByCodepromo.gql"),
          variables: {
            id_utilisateur: JSON.parse(localStorage.getItem('utilisateur')).id,
            code: this.Coupon,
            compteur: parseInt(localStorage.getItem("compteur"))
          },
        }).then(response => {
          if (response.data.prepaiementByCodepromo.state == false) {
            this.$notify({type: 'warning', message: "Code promo saisi est incorrect" })
          }
          else {
            this.$notify({type: 'success', message: "Code promo correct" })
            this.showAddAmount = false
            this.$apollo.queries.MyPrepayments.refetch({
              utilisateur_id: JSON.parse(localStorage.getItem('utilisateur')).id
            })
          }
        }).catch(error => {
          this.$notify({type: 'warning', message: "erreur" })
        })
        localStorage.setItem("compteur", parseInt(localStorage.getItem("compteur"))+1)
      },
      getError(fieldName) {
        return this.errors.first(fieldName);
      }
    },
  }
</script>
