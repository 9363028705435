let Icons

Icons = {
    'Assurance Auto' : 'fas fa-car', 'Assurance Santé Individuelle' : 'fas fa-heartbeat', 
    'Assurance Habitation' : 'fas fa-home', 'Assurance Animaux' : 'fas fa-paw', 
    'TNS Santé' : 'fas fa-people-arrows', 'Défiscalisation' : 'fas fa-chart-bar', 
    'Protection juridique' : 'fas fa-balance-scale-right',
    'Assurance Emprunteur' : 'tim-icons icon-bank',
    'CRÉDIT CONSO' : 'fas fa-credit-card',
    'Assurance Vie' : 'fa-solid fa-coins'
}

export {
    Icons
}
