<template>
  <div>
    <base-alert class="col-12" type="market">
      <b>Confirmation de votre commande - Paiement</b> <br>
      Vous avez commandé : {{ exclusif_total + duo_total + trio_total }} leads
    </base-alert>

    <div class="whitcontent label" v-if="exclusif_total">
      <b><img src="/img/lead_exclusif.png" style="height: 6%; width: 6%;" alt="" /></b>
      &nbsp;{{ exclusif_total }}   Lead EXCLUSIF à {{ (Commande) ? Commande.prix_unitaire_lead_exclusif : ''}} €
    </div><br/>

    <div class="whitcontent label" v-if="duo_total">
      <b><img src="/img/lead_duo.png" style="height: 6%; width: 6%;" alt="" /></b>
      &nbsp;{{ duo_total }}   Lead DUO à {{ (Commande) ? Commande.prix_unitaire_lead_duo : ''}} €
    </div><br/>

    <div class="whitcontent label" v-if="trio_total">
      <b><img src="/img/lead_trio.png" style="height: 6%; width: 6%;" alt="" /></b>
      &nbsp;{{ trio_total }}   Lead TRIO à {{ (Commande) ? Commande.prix_unitaire_lead_trio : ''}} €
    </div><br/>
    <br/>
    <base-table :data="tableData" thead-classes="text-primary" class="col-lg-6 col-sm-12">
      <template slot-scope="{ row }">
        <td>
          <div class="whitcontent label"> {{ row.title }} </div>
        </td>
        <td>
          <div class="whitcontent label">: {{ row.value }} </div>
        </td>
      </template>
    </base-table>

    <base-checkbox inline class="mb-2" v-model="condition">
      En validant ma commande, je déclare avoir pris connaissance et accepté sans réserve : <label style="cursor: pointer; text-decoration: underline;" @click="goConditions()">les CGV WEEDO MARKET </label>
    </base-checkbox>

  </div>
</template>
<script>
import { BaseAlert, BaseTable } from 'src/components/index';

export default {
  components: {
    BaseAlert,
    BaseTable
  },
  data() {
    return {
      disable_addcard_Exclusif: false,
      disable_addcard_Duo: false,
      disable_addcard_Trio: false,
      Commande: null,
      condition: false,
      Lead_Availability: null,
      id_exclusif_selected: "",
      id_duo_selected: "",
      id_trio_selected: "",
      exclusif_now: 0,
      exclusif_later: 0,
      exclusif_total: 0,
      duo_now: 0,
      duo_later: 0,
      duo_total: 0,
      trio_now: 0,
      trio_later: 0,
      trio_total: 0,
      tableData: [
        {
          title: 'Montant HT',
          value: ""
        },
        {
          title: 'TVA',
          value: ""
        },
        {
          title: 'Montant TTC',
          value: ""
        },
        {
          title: 'Votre solde après votre commande',
          value: ""
        }
      ]
    };
  },
  mounted() {

    this.$root.$on('Commande_Object2', (Commande_Object) => {
      this.Commande =  Commande_Object
      this.tableData[1].value = this.Commande.tva + " %"
    })

    this.$root.$on('disable_addcard_Exclusif', (disable_addcard_Exclusif) => {
      this.disable_addcard_Exclusif =  disable_addcard_Exclusif
    })

    this.$root.$on('disable_addcard_Duo', (disable_addcard_Duo) => {
      this.disable_addcard_Duo =  disable_addcard_Duo
    })

    this.$root.$on('disable_addcard_Trio', (disable_addcard_Trio) => {
      this.disable_addcard_Trio =  disable_addcard_Trio
    })

    this.$root.$on('Lead_Availability', async (Lead_Availability) => {
      this.Lead_Availability =  Lead_Availability
      let exclusif
      let duo
      let trio
      if (this.disable_addcard_Exclusif) {
        this.exclusif_now = (exclusif = this.Lead_Availability.find(x => x.type === 'EXCLUSIF')) ? exclusif.Immediate_delivery : 0
        this.exclusif_later = (exclusif =this.Lead_Availability.find(x => x.type === 'EXCLUSIF')) ? exclusif.subsequent_delivery : 0
      } else {
        this.exclusif_now = 0
        this.exclusif_later = 0
      }
      this.exclusif_total = parseInt(this.exclusif_now) + parseInt(this.exclusif_later)
      if (this.disable_addcard_Duo) {
        this.duo_now = (duo =this.Lead_Availability.find(x => x.type === 'DUO')) ? duo.Immediate_delivery : 0
        this.duo_later = (duo =this.Lead_Availability.find(x => x.type === 'DUO')) ? duo.subsequent_delivery : 0
      } else {
        this.duo_now = 0
        this.duo_later = 0
      }
      this.duo_total = parseInt(this.duo_now) + parseInt(this.duo_later)
      if (this.disable_addcard_Trio) {
        this.trio_now = (trio =this.Lead_Availability.find(x => x.type === 'TRIO')) ? trio.Immediate_delivery : 0
        this.trio_later = (trio =this.Lead_Availability.find(x => x.type === 'TRIO')) ? trio.subsequent_delivery : 0
      } else {
        this.trio_now = 0
        this.trio_later = 0
      }
      this.trio_total = parseInt(this.trio_now) + parseInt(this.trio_later)
      if (this.Commande) {
        let total_ht = (this.exclusif_total * this.Commande.prix_unitaire_lead_exclusif) +
                      (this.duo_total * this.Commande.prix_unitaire_lead_duo) +
                      (this.trio_total * this.Commande.prix_unitaire_lead_trio)

        this.tableData[0].value = this.formatThounsends(total_ht,true,1) + " €"
        this.tableData[2].value = this.formatThounsends((total_ht * ( parseFloat(this.Commande.tva) / 100 )) + total_ht,true,1)  + " €"

        await this.$apollo.query({
          client: 'MARKET',
          query: require("src/graphql/leadmarket/Annonceur/getSoldeactuel.gql"),
          variables: {
            id_utilisateur: JSON.parse(localStorage.getItem('utilisateur')).id
          },
          fetchPolicy: 'network-only'
        }).then(data => {
          this.tableData[3].value = this.formatThounsends(parseFloat(data.data.getSoldeactuel.soldeHt) - total_ht,true,2) + " €"
        }).catch((error) => {
          console.error(error)
        })
      }
    })

    this.$root.$on('id_exclusif_selected', (id_exclusif_selected) => {
      this.id_exclusif_selected =  id_exclusif_selected
    })

    this.$root.$on('id_duo_selected', (id_duo_selected) => {
      this.id_duo_selected =  id_duo_selected
    })

    this.$root.$on('id_trio_selected', (id_trio_selected) => {
      this.id_trio_selected =  id_trio_selected
    })
  },
  methods: {
    formatThounsends: function(value, fixe = false, number = 0) {
      if (fixe) {
        value = value.toFixed(number);
      }
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    goConditions() {
      window.open("https://weedoit-media.s3.eu-central-1.amazonaws.com/WEEDOMARKET/CGV_WeedoMarket.pdf", '_blank'); 
    },
    async validate() {

      if (!this.condition) {
        this.$notify({type: 'warning', message: "Vous devez accepter nos conditions générales de vente" })
        return false
      }
      this.$store.commit('setendregistration', true)
      let exclusif
      let duo
      let trio
      let jours_livraison = '' 
      // joure de livraison
      if (this.Commande.delai_livraison === 'etale') {
        if (this.Commande.jours_livraison.lundi)
          jours_livraison += "Lundi,"
        if (this.Commande.jours_livraison.mardi)
          jours_livraison += "Mardi,"
        if (this.Commande.jours_livraison.mercredi)
          jours_livraison += "Mercredi,"
        if (this.Commande.jours_livraison.jeudi)
          jours_livraison += "Jeudi,"
        if (this.Commande.jours_livraison.vendredi)
          jours_livraison += "Vendredi,"
        if (this.Commande.jours_livraison.samedi)
          jours_livraison += "Samedi,"
        if (this.Commande.jours_livraison.dimanche)
          jours_livraison += "Dimanche,"

        jours_livraison = jours_livraison.slice(0, -1)
      }

      await this.$apollo.mutate({
        client: 'MARKET',
        mutation: require("src/graphql/leadmarket/Annonceur/mutation/validerCommande.gql"),
        variables: {
          id_utilisateur: JSON.parse(localStorage.getItem('utilisateur')).id,
          categorie_id: this.Commande.Product,
          id_exclusif_selected: this.id_exclusif_selected,
          id_duo_selected: this.id_duo_selected,
          id_trio_selected: this.id_trio_selected,
          montant: this.Commande.montant,
          filtre_id: this.Commande.Filtre,
          exclusif_now: ((exclusif = this.Lead_Availability.find(x => x.type === 'EXCLUSIF')) && this.disable_addcard_Exclusif) ? exclusif.Immediate_delivery : 0,
          exclusif_later: ((exclusif = this.Lead_Availability.find(x => x.type === 'EXCLUSIF')) && this.disable_addcard_Exclusif) ? exclusif.subsequent_delivery : 0,
          duo_now: ((duo = this.Lead_Availability.find(x => x.type === 'DUO')) && this.disable_addcard_Duo) ? duo.Immediate_delivery : 0,
          duo_later: ((duo = this.Lead_Availability.find(x => x.type === 'DUO')) && this.disable_addcard_Duo) ? duo.subsequent_delivery : 0,
          trio_now: ((trio = this.Lead_Availability.find(x => x.type === 'TRIO')) && this.disable_addcard_Trio) ? trio.Immediate_delivery : 0,
          trio_later: ((trio = this.Lead_Availability.find(x => x.type === 'TRIO')) && this.disable_addcard_Trio) ? trio.subsequent_delivery : 0,
          prix_exclusif: this.Commande.prix_unitaire_lead_exclusif,
          prix_duo: this.Commande.prix_unitaire_lead_duo,
          prix_trio: this.Commande.prix_unitaire_lead_trio,
          tva: this.Commande.tva,
          nom: this.Commande.name_cmd,
          description: "",
          delai_livraison: this.Commande.delai_livraison,
          mode_livraison: this.Commande.mode_livraison,
          options_commande: this.Commande.options_sante,
          departement: this.Commande.Departement_parse,
          jours_livraison: jours_livraison,
          h_de_livriason: this.Commande.heure_debut_livraison,
          h_fin_livriason: this.Commande.heure_fin_livraison,
          email_notification: this.Commande.email_notification,
          fin_commande_notification: null,
          web_service: null,
          lead_par_jour: this.Commande.lead_par_jour,
          date_debut_livraison_client: this.Commande.date_debut_livraison_client,
          exclure: this.Commande.exclure
        }
      }).then(response => {
        this.$notify({type: 'success', message: this.$t("apiresponse.orderedcreated") })
        this.$router.push({ name: 'mes Commandes Leadmarket Annonceur' })

        this.$store.commit("setLeadsPanier_trio", 0);
        this.$store.commit("setLeadsPanier_duo", 0);
        this.$store.commit("setLeadsPanier_exclusif", 0);
        this.$store.commit('setendregistration', false)
      }).catch(error => {
        this.$notify({type: 'warning', message: this.$t("apiresponse.errorcreation") })
        this.$store.commit('setendregistration', false)
      })
    }
  }
};
</script>
