<template>
    <div class="content">
      <div class="row mt-5">
        <div class="col-md-12">
          <h3>MES FILTRES ENREGISTRÉS</h3>
          <div class="btn-group btn-group-toggle float-right mb-3" data-toggle="buttons">
            <router-link
              :to="{ name: 'cree filtres Leadmarket Annonceur'}"
              class="btn btn-round btn-just-icon btn-market"
            >CRÉER UN NOUVEAU FILTRE</router-link>
          </div>
          <div class="card">
            <div class="card-body">
              <div>
                <div class="row">
                  <div class="col-xl-1 col-sm-6">
                    <base-input>
                      <el-select
                        class="select-primary pagination-select"
                        v-model="pagination.perPage"
                        placeholder="Per page"
                      >
                        <el-option
                          class="select-primary"
                          v-for="item in pagination.perPageOptions"
                          :key="item"
                          :label="item"
                          :value="item"
                        >
                        </el-option>
                      </el-select>
                    </base-input>
                  </div>
                  <div class="col-xl-8 col-sm-6">
                    <label style="margin-top: 10px; font-size: 0.75rem">{{ $t("labels.Datatable.NbrLinePerPage").toUpperCase() }}</label>
                  </div>
                  <base-input class="col-xl-3 col-sm-6">
                    <el-input
                      type="search"
                      class="mb-3 search-input"
                      clearable
                      prefix-icon="el-icon-search"
                      placeholder="Rechercher"
                      aria-controls="datatables"
                      v-model="searchQuery"
                    >
                    </el-input>
                  </base-input>
                </div>
                <el-table :data="filterlistFilters" v-loading="$apollo.queries.MyFilters.loading">
                  <el-table-column
                    prop='id'
                    label='ID'
                    minWidth='90'
                    show-overflow-tooltip
                    sortable
                  >
                  </el-table-column>
                  <el-table-column
                    prop='nom'
                    label='nom'
                    minWidth='150'
                    show-overflow-tooltip
                    sortable
                  >
                    <template scope="scope">
                      {{ scope.row.nom.replace(/Ã©/g,'é').toUpperCase() }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop='date_creation'
                    label='date création'
                    minWidth='160'
                    show-overflow-tooltip
                    sortable
                  >
                  </el-table-column>
                  <el-table-column
                    prop='designation'
                    label='Produit'
                    minWidth='250'
                    show-overflow-tooltip
                    sortable
                  >
                    <template scope="scope">
                      {{ scope.row.designation.replace(/Ã©/g,'é').toUpperCase() }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop=''
                    label='Action'
                    minWidth='90'
                  >
                    <!-- <el-tooltip
                      content="Editer"
                      effect="light"
                      :open-delay="300"
                      placement="top"
                    >
                      <base-button
                        class="btn-link"
                        type="warning"
                        size="sm"
                        icon
                      >
                        <i class="tim-icons icon-pencil"></i>
                      </base-button>
                    </el-tooltip> -->
                    <template scope="scope">
                      <el-tooltip
                        content="Editer"
                        effect="light"
                        :open-delay="300"
                        placement="top"
                      >
                        <base-button
                          class="btn-link"
                          type="warning"
                          size="sm"
                          icon
                          @click="gotoEdit(scope.row.id)"
                        >
                          <i class="tim-icons icon-pencil"></i>
                        </base-button>
                      </el-tooltip>
                      <el-tooltip
                        content="Supprimer"
                        effect="light"
                        :open-delay="300"
                        placement="top"
                      >
                        <base-button
                          class="btn-link"
                          type="danger"
                          size="sm"
                          icon
                          @click="DeleteFiltre(scope.row.id)"
                        >
                          <i class="tim-icons icon-trash-simple"></i>
                        </base-button>
                      </el-tooltip>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <div
                slot="footer"
                class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <div class="">
                  <p class="card-category">
                    {{ $t('labels.Datatable.Showing') }} {{ from + 1 }}  {{ $t('labels.Datatable.to') }} {{ to }} {{ $t('labels.Datatable.of') }} {{ total }} {{ $t('labels.Datatable.entries') }}
                  </p>
                </div>
                <base-pagination
                  type="market"
                  class="pagination-no-border"
                  v-model="pagination.currentPage"
                  :per-page="pagination.perPage"
                  :total="total"
                >
                </base-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { Table, TableColumn, Select, Option } from 'element-ui';
  import { BasePagination } from 'src/components';
  import swal from "sweetalert2";

  let today = new Date()

  export default {
    apollo: {
      MyFilters: {
        client: 'MARKET',
        query: require("src/graphql/leadmarket/Annonceur/MyFilters.gql"),
        variables () {
          return {
            id_utilisateur: JSON.parse(localStorage.getItem('utilisateur')).id
          }
        },
        result({ data, loader, networkStatus }) {
        },
        fetchPolicy: 'network-only',
        update: data => data.getFilters
      }
    },
    components: {
      BasePagination,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Select.name]: Select,
      [Option.name]: Option
    },
    data() {
      return {
        searchQuery: '',
        total: 0,
        pagination: {
          perPage: 50,
          currentPage: 1,
          perPageOptions: [10, 25, 50, 100],
          total: 0
        },
        MyFilters: []
      }
    },
    computed: {
      filterlistFilters() {
        var list = []
        try {
          list = this.MyFilters.filter((filer) => {            
            return (filer.id+"").match(this.searchQuery) ||
                  filer.nom.match(this.searchQuery) ||
                  filer.date_creation.match(this.searchQuery)
          })

          this.total = list.length
          return list.slice(this.from, this.to);
        } catch (error) {
          console.log(error)
          return null
        }
      },
      to() {
        let highBound = this.from + this.pagination.perPage;
        if (this.total < highBound) {
          highBound = this.total;
        }
        return highBound;
      },
      from() {
        return this.pagination.perPage * (this.pagination.currentPage - 1);
      }
    },
    methods: {
      async DeleteFiltre(filtre_id) {
        swal.fire({
          text: "Vous êtes en train de supprimer ce filtre!",
          width: 400,
          showCancelButton: true,
          confirmButtonColor: '#988fc3',
          cancelButtonColor: '#27293D',
          confirmButtonText: 'Supprimer',
          cancelButtonText: 'Annuler'
        }).then((result) => {
          if (result.value) {
            this.$apollo.mutate({
              client: 'MARKET',
              mutation: require("src/graphql/leadmarket/Annonceur/mutation/deleteFilter.gql"),
              variables: {
                id_utilisateur: JSON.parse(localStorage.getItem('utilisateur')).id,
                filtre_id: filtre_id
              },
            }).then(response => {
              this.$notify({type: 'success', message: "filtre supprimé avec succès" })
              this.$apollo.queries.MyFilters.refetch({
                utilisateur_id: JSON.parse(localStorage.getItem('utilisateur')).id
              })
            }).catch(error => {
              this.$notify({type: 'warning', message: this.$t("apiresponse.errorupdate") })
            })
          }
        })
      },
      gotoEdit(filtre_id) {
        this.$router.push({ name:'editer filtres Leadmarket Annonceur', params: { id_filter: filtre_id } });
      }
    },
  }
</script>